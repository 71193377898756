import { IUsers, IAction, Query } from 'utils/types';

type UsersReducer = (state: Query<IUsers>, action: IAction) => Query<IUsers>;
export enum UsersReducers {
  CLEAR_USERS = 'CLEAR_USERS',
  READ_USERS = 'READ_USERS',
  LOADING_USERS = 'LOADING_USERS',
  ERROR_USERS = "ERROR_USERS"
}

const initialState: Query<IUsers> = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: undefined,
}

export const users: UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case UsersReducers.CLEAR_USERS:
      return initialState;
    case UsersReducers.READ_USERS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        data: action.payload
      };
    case UsersReducers.LOADING_USERS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: undefined
      }
    case UsersReducers.ERROR_USERS:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        data: undefined
      }
    default:
      return state;
  }
};
