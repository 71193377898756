import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from 'actions';
import { useValidationResolver } from 'utils/useValidationResolver';
import { getBase64File } from 'utils/files';
import { Input } from 'components/atoms';
import './styles.scss';
import {
  IPersonalDataForm,
  IUser,
  IPersonalData,
  ISignUpData,
  ISignUpDataForm,
} from 'utils/types';
import { IRootState } from 'reducers/rootReducer';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { CurriculumField } from 'components/molecules/CurriculumField';

interface Props {
  id?: number;
  submitCallback: (userData: ISignUpData | IPersonalData) => void;
  user?: IUser;
  validationSchema: any;
}

export const SignUpData: React.FC<Props> = ({ id, submitCallback, user, validationSchema }) => {
  const dispatch = useDispatch()
  const resolver = useValidationResolver(validationSchema);
  const { isLoading } = useSelector((state: IRootState) => state.utils);
  const { register, handleSubmit, setValue, watch, errors } = useForm({
    mode: 'onTouched',
    resolver,
    defaultValues: {
      ...user,
      curriculum: ''
    },
  });

  useEffect(() => {
    if (id && user && !watch('firstName')) {
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
      setValue('country', user.country);
      setValue('city', user.city);
      setValue('email', user.email);
      setValue('introduction', user.introduction);
    }
  }, [id, user]);

  useEffect(() => {
    if (id) dispatch(AuthActions.refreshToken(id));
  }, []);

  const onSubmit = async (data: ISignUpDataForm | IPersonalDataForm): Promise<void> => {
    const userData = {
      ...data,
      id,
      curriculum: (data?.curriculum) && await getBase64File(data?.curriculum[0]),
    };
    submitCallback(userData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="personal-data-form pb-5">
      <div className="form-body row col-lg-10 col-md-10 col-sm-12 col-xs-12 mx-auto">
        <div className="col-md-1 col-xs-10 col-sm-10 p-0 iconBack">
          <Link to="/login">
            <FontAwesomeIcon className="icon" icon={faChevronLeft} />
          </Link>
        </div>
        <div className="text-form col-md-7 col-sm-8 col-xs-12 mx-auto" id="formRight">
          <div className="row mt-3 ml-0 mr-0">
            <div className="first-name col-12 col-lg-6 mx-auto">
              <Input name="firstName" label="First Name *" register={register} error={errors?.firstName} />
            </div>
            <div className="last-name col-12 col-lg-6 mx-auto mt-3 mt-lg-0">
              <Input name="lastName" label="Last Name *" register={register} error={errors?.lastName} />
            </div>
          </div>
          <div className="row mt-3 ml-0 mr-0">
            <div className="country col-12 col-lg-6 mx-auto">
              <Input name="country" label="Country *" register={register} error={errors?.country} />
            </div>
            <div className="city col-12 col-lg-6 mx-auto mt-3 mt-lg-0">
              <Input name="city" label="City *" register={register} error={errors?.city} />
            </div>
          </div>
          <div className="row mt-3 ml-0 mr-0">
            <div className="email col-12 col-lg-12 mx-auto">
              <Input
                type="email"
                name="email"
                label="Email *"
                register={register}
                error={errors?.email}
                disabled={id ? true : false}
              />
            </div>
          </div>
          <CurriculumField register={register} />
          <div className="row mt-3 ml-0 mr-0">
            <div className="password col-12 col-lg-6 mx-auto">
              <Input type="password" name="password" label="Password *" register={register} error={errors?.password} />
            </div>
            <div className="repeat-password col-12 col-lg-6 mx-auto mt-3 mt-lg-0">
              <Input
                type="password"
                name="repeatPassword"
                label="Repeat password *"
                register={register}
                error={errors?.repeatPassword}
              />
            </div>
          </div>
          <div className="row mt-4 submit">
            <button className="btn button-orange mx-auto" type="submit">
              {
                isLoading 
                ? ( <div className="spinner-border text-light" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div> ) 
                : ('SIGN UP')
              }
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};