import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions, ReferenceActions } from 'actions';
import './styles.scss';
import { IReference } from 'utils/types';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { IRootState } from 'reducers/rootReducer';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';

const useStyles = makeStyles({
	pos: {
		marginBottom: 12,
	},
});

interface Props {
  id: number;
  idUserSelected: number;
  allReferences: any;
}

export const CardsRef: React.FC<Props> = ({ id, idUserSelected, allReferences }: Props) => {
  const dispatch = useDispatch();
  const [reloadReference, setReloadReference] = useState(false);
  const classes = useStyles();
	const references: IReference = useSelector((state: IRootState) => state.reference);
  const referenceData = Object.values(references.data);
	
  useEffect(() => {
    if (id) dispatch(AuthActions.refreshToken(id));
  }, []);
	
  useEffect(() => {
		dispatch(ReferenceActions.readUserReference(idUserSelected));
		setReloadReference(false);
	}, [reloadReference]);

  return (
		<div className="container-references row col-12 m-0">
			{allReferences.map((oneReference, index) => (
				<div key={index} className="card-user-references">
					<CardContent>
						<div className="col-md-12 row m-0 p-0">
							<div className="col-md-11 p-0 cardReferenceLeft">
								<label className="cardTitle">
								Reference created by {oneReference.reference.firstName.toUpperCase()} {oneReference.reference.lastName.toUpperCase()} at {oneReference.updatedAt[8]}{oneReference.updatedAt[9]}/{oneReference.updatedAt[5]}{oneReference.updatedAt[6]}/{oneReference.updatedAt[0]}{oneReference.updatedAt[1]}{oneReference.updatedAt[2]}{oneReference.updatedAt[3]}
								</label>
							</div>
							{oneReference.type == true ? 
								(<div className="col-md-1 p-0 cardReferenceRight">
									<span className="reference-icon">
										<ThumbUp style={{color: "black"}}/>
									</span>
								</div>) :
								(<div className="col-md-1 p-0 cardReferenceRight">
									<span className="reference-icon">
										<ThumbDown style={{color: "black"}}/>
									</span>
								</div>)
							}
						</div>
						{(oneReference.description != null) ? (
							<>
								<hr />
								<Typography className={'m-0' + classes.pos}>
									{oneReference.description} 
								</Typography> 
							</>
						) : null}
					</CardContent>
				</div>
			))}
		</div>
	);
}