import { IUser, IAction } from 'utils/types';
import DefaultPicture from 'assets/images/default_picture.jpg';

type UserReducer = (state: IUser, action: IAction) => IUser;
export enum UserReducers {
  CLEAR_USER = 'CLEAR_USER',
  ADD_SKILL = 'ADD_SKILL',
  ADD_LANGUAGE = 'ADD_LANGUAGE',
  ADD_SENIORITIE = 'ADD_SENIORITIE',
  CREATE_USER = 'CREATE_USER',
  DELETE_PROFILE_PICTURE = 'DELETE_PROFILE_PICTURE',
  DELETE_PROFILE_CV = 'DELETE_PROFILE_CV',
  DELETE_SKILL = 'DELETE_SKILL',
  DELETE_SENIORITIE = 'DELETE_SENIORITIE',
  DELETE_LANGUAGE = 'DELETE_LANGUAGE',
  UPDATE_CURRICULUM = 'UPDATE_CURRICULUM',
  UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_SENIORITIE = 'UPDATE_SENIORITIE',
  READ_USERS = 'READ_USERS', 
  READ_USER = 'READ_USER', 
  READ_USER_LANGUAGE = 'READ_USER_LANGUAGE',
}

const initialState: IUser = {
  id: 0,
  city: '',
  country: '',
  curriculumUrl: '',
  firstName: '',
  introduction: '',
  lastName: '',
  email: '',
  phone: '',
  pictureUrl: DefaultPicture,
  picture: null,
  skills: [],
  languages: [],
  seniorityId: 0,
  reference: [],
  status: [],
};

export const user: UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserReducers.CLEAR_USER:
      return initialState;
    case UserReducers.ADD_SKILL:
      if (!state.skills.some(({ title }) => title === action.payload.title)) {
        return {
          ...state,
          skills: [...state.skills, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case UserReducers.ADD_LANGUAGE:
    if (!state.languages.some(({ title }) => title === action.payload.title)) {
      return {
        ...state,
        languages: [...state.languages, action.payload],
      };
    } else {
      return {
        ...state,
      };
    }
    case UserReducers.DELETE_PROFILE_CV:
      return {
        ...state,
        curriculumUrl: initialState.curriculumUrl,
      };  
    case UserReducers.DELETE_PROFILE_PICTURE:
      return {
        ...state,
        picture: null,
        pictureUrl: null,
      };
    case UserReducers.DELETE_SKILL:
      return {
        ...state,
        skills: [...state.skills.slice(0, action.payload), ...state.skills.slice(action.payload + 1)],
      };
    case UserReducers.DELETE_LANGUAGE:
      return {
        ...state,
        languages: [...state.languages.slice(0, action.payload), ...state.languages.slice(action.payload + 1)],
      };  
    case UserReducers.UPDATE_CURRICULUM:
      return {
        ...state,
        curriculumUrl: action.payload,
      };
    case UserReducers.UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case UserReducers.UPDATE_PROFILE_PICTURE:
      return {
        ...state,
        picture: action.payload,
      };
    case UserReducers.READ_USER:
      return {
      ...state,
      ...action.payload,
    };
    case UserReducers.READ_USER_LANGUAGE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};