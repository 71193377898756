import { LinkReducers } from 'reducers/linkReducer';
import { UtilsReducers } from 'reducers/utilsReducer';
import { updateLink, saveLink, getUserLink, getLink, deleteLink } from 'utils/API';
import { IDispatch, ISignUpData, ILink, IAxiosResponse, ToastStatus } from 'utils/types';

type Filter = (obj: object) => object;
type CreateLink = (signUpData: ISignUpData, history: any) => (dispatch: IDispatch, getState: () => any) => void;
type UpdateLinkHandler = (link: ILink) => (dispatch: IDispatch, getState: () => any) => void;
type DeleteLink = (linkIndex: number) => (dispatch: IDispatch) => void;
type ReadUserLink = ({id}) => void;
type ReadLink = (linkIndex: number) => (dispatch: IDispatch) => void;

const filter: Filter = (obj) => {
  const asArray = Object.entries(obj);
  const filtered = asArray.filter(([_, value]) => value && value !== '' && value !== null);
  return Object.fromEntries(filtered);
};

const createLink: CreateLink = (userLinkData) => async (dispatch) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });
    const data = filter(userLinkData) as ISignUpData;
    const signUpResponse = (await saveLink(data)) as IAxiosResponse<ILink>;
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: 'Link data created successfully', status: ToastStatus.SUCCESS },
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: err.message, status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

const updateLinkHandler: UpdateLinkHandler = (link) => async (dispatch) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });
    const { id, ...data } = filter(link) as ILink;
    const requestBody = {
      id,
      data,
    };
    const updateLinkResponse = (await updateLink(requestBody)) as IAxiosResponse<ILink>;
    dispatch({
      type: LinkReducers.UPDATE_LINK,
      payload: updateLinkResponse.data,
    });
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: 'User data updated successfully', status: ToastStatus.SUCCESS },
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: err.message, status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

const readUserLink: ReadUserLink = ({id}) => async (dispatch) => {
  const links = await getUserLink({id});
  dispatch({
    type: LinkReducers.READ_USER_LINK,
    payload: links,
  })
};

const readLink: ReadLink = (linkIndex) => async (dispatch) => {
  const link = await getLink(linkIndex);
  dispatch({
    type: LinkReducers.READ_LINK,
    payload: link,
  })
};

const deleteLinkHandler: DeleteLink = (linkIndex) => async (dispatch) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });
    const deleteExp = await deleteLink(linkIndex);
    dispatch({
      type: LinkReducers.DELETE_LINK,
      payload: { message: 'Link data deleted successfully', status: ToastStatus.SUCCESS },
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: err.message, status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

export const LinkActions = {
  createLink,
  updateLink: updateLinkHandler,
  readUserLink,
  readLink,
  deleteLinkHandler
};