import { Education } from 'screens';
import { IEducation, IAction } from 'utils/types';

type EducationReducer = (state: IEducation, action: IAction) => IEducation;
export enum EducationReducers {
	CLEAR_EDUCATION = 'CLEAR_EDUCATION',
	CREATE_EDUCATION = 'CREATE_EDUCATION',
	UPDATE_EDUCATION = 'UPDATE_EDUCATION',
	READ_USER_EDUCATION = 'READ_USER_EDUCATION',
	READ_EDUCATION = 'READ_EDUCATION',
	DELETE_EDUCATION = 'DELETE_EDUCATION'
}

const initialState: IEducation = {
	id: 0,
	userId: 0,
	title: '',
	institution: '',
	level: '',
	country: '',
	startYear: '',
	finishYear: '',
	data: [],
	isEdit: false, 
};

export const education: EducationReducer = (state = initialState, action) => {
	switch (action.type) {
		case EducationReducers.CLEAR_EDUCATION:
			return initialState;
		case EducationReducers.UPDATE_EDUCATION:
			return {
				...state,
				...action.payload,
			};
		case EducationReducers.READ_USER_EDUCATION:
			return {
				...state,
				...action.payload,
			};
		case EducationReducers.DELETE_EDUCATION:
			return {
				...state,
				...action.payload,
			}
		default:
			return state;
	}
};