import { IAction, INewPassword } from 'utils/types';

type NewPasswordReducer = (state: INewPassword, action: IAction) => INewPassword;

export enum NewPasswordReducers {
  CLEAR_PASSWORD = 'CLEAR_PASSWORD',
  NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS',
}

const initialState: INewPassword = {
  password: '',
  resetToken: '',
  email: '',
};

export const newpassword: NewPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case NewPasswordReducers.CLEAR_PASSWORD:
      return initialState;
    case NewPasswordReducers.NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};