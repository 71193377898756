import { UsersReducers } from 'reducers/usersReducer';
import { getUsers } from 'utils/API';
import { IDispatch } from 'utils/types';

type ReadUsers = ({limitNumber, pageNumber, name, skill, language, city, status}) => (dispatch: IDispatch) => void;

const readUsers: ReadUsers = (objectParams) => async (dispatch) => {
  dispatch({
    type: UsersReducers.LOADING_USERS})
  try {
    const users = await getUsers(objectParams);
    dispatch({
      type: UsersReducers.READ_USERS,
      payload: users,
    })
  } catch (e) {
      dispatch({
        type: UsersReducers.ERROR_USERS
      })
  }
  
};

export const UsersActions = {
  readUsers
};
