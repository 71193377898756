import React, { useEffect } from 'react';
import logo from '../../../assets/images/logo.png';
import crown from '../../../assets/images/crownAdmin.png';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions, UserActions } from 'actions';
import { IRootState } from 'reducers/rootReducer';
import { Link } from 'react-router-dom';
import { IUser } from 'utils/types';
import { logOutOAuthUser } from "react-google-oauth2";
import { useLocation } from 'react-router-dom'

export const Header = () => {
  const dispatch = useDispatch();
  const { id, isAuthenticated } = useSelector((state: IRootState) => state.auth);
  const user: IUser = useSelector((state: IRootState) => state.user);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname != '/login' && location.pathname != '/' && location.pathname != '/signup' && location.pathname != '/forgot-password') {
      dispatch(UserActions.readUser(id))
    }
  },[]); 
  
  return (
    <div className="container-fluid ">
      <div className="header row px-4 align-items-center justify-content-between">
        <div className="ms-0 ms-lg-5 col-xs-3 col-auto left">
          <Link to="/home">
            <img className="logo" src={logo} alt="42mate" />
          </Link>
        </div>
        {isAuthenticated ? (
          <div className="me-0 me-lg-5 col-xs-4 col-auto right">
            {(user.role == 'ADMIN') ? (
              <Link to="/personal-data"  className="user-log">
                <img className="crown" src={crown} alt="" />
                <span className="user-admin">
                  {user.firstName} {user.lastName}
                </span>
              </Link>
            ) : (
              <Link to="/personal-data"  className="user-log">
                {user.firstName} {user.lastName}
              </Link>
            )}
            <div className="btn me-0 me-lg-5 col-auto" onClick={() => dispatch(AuthActions.logout())}>
              <FontAwesomeIcon className="icon" icon={faSignOutAlt} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};