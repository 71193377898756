import { Links } from 'screens';
import { ILink, IAction } from 'utils/types';

type LinkReducer = (state: ILink, action: IAction) => ILink;
export enum LinkReducers {
  ADD_SKILL = 'ADD_SKILL',
  CLEAR_LINK = 'CLEAR_LINK',
  CREATE_LINK = 'CREATE_LINK',
  DELETE_SKILL = 'DELETE_SKILL',
  UPDATE_LINK = 'UPDATE_LINK',
  READ_USER_LINK = 'READ_USER_LINK',
  READ_LINK = 'READ_LINK',
  DELETE_LINK = 'DELETE_LINK'
}

const initialState: ILink = {
  id: 0,
  userId: 0,
  title: '',
  url: '',
  isEdit: false,
  data: [],
};

export const link: LinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case LinkReducers.CLEAR_LINK:
      return initialState;
    case LinkReducers.UPDATE_LINK:
      return {
        ...state,
        ...action.payload,
      };
    case LinkReducers.READ_USER_LINK:
      return {
        ...state,
        ...action.payload,
      };
    case LinkReducers.DELETE_LINK:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state;
  }
};