import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Cards } from './';
import { IEducation, IUser } from 'utils/types';
import { Input } from 'components/atoms';

interface Props {
	id: number;
	educations: Array<IEducation>;
	totalEducations: number;
	updateCallback: (userEducationData: IEducation) => void;
	education?: IEducation;
	validationSchema: any;
}

export const Data: React.FC<Props> = ({ id, educations, totalEducations, updateCallback, education, validationSchema }: Props) => {

	const [search, setSearch] = useState('');
	const filteredEducations = educations.filter(
		(education) => {
			return education.title.toUpperCase().includes(search.toUpperCase()) ||
				education.institution.toUpperCase().includes(search.toUpperCase()) ||
				education.level.toUpperCase().includes(search.toUpperCase()) ||
				education.country.toUpperCase().includes(search.toUpperCase())
		}
	);

	if (totalEducations != 0) {
		return (
			<div className="card-education-form">
				<h5 className="text-no-education">MY EDUCATION</h5>
				<div className="filter col-md-12 col-sm-12 col-xs-12">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <Input value={search} type="text" onChange={e => setSearch(e.target.value)}
              name="filter" placeholder="Search" register={null} />
          </div>
        </div>
				<Cards educations={filteredEducations} updateCallback={updateCallback} validationSchema={validationSchema} educationDOM={education} />
			</div>
		);
	} else {
		return (
			<div className="card-education-form">
				<h5 className="text-no-education">MY EDUCATION</h5>
				<h5 className="mt-4">You have not added any education yet..</h5>
			</div>
		)
	}
};