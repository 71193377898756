import React, { useEffect, useState } from 'react';
import { IEducation, ISignUpData } from 'utils/types';
import { EducationActions } from 'actions';
import { UserEducationData, Data } from 'components/organisms/UserEducationData';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { IRootState } from 'reducers/rootReducer';
import { validationSchema } from './validations';
import { useHistory } from 'react-router-dom';

interface Props { }

export const Education: React.FC<Props> = ({ }: Props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [reloadEducation, setReloadEducation] = useState(false);
	const { id, isAuthenticated } = useSelector((state: IRootState) => state.auth);
	const education: IEducation = useSelector((state: IRootState) => state.education);
	const educationData = Object.values(education.data);
	const totalEducations = education?.totalEducations;

	useEffect(() => {
		dispatch(EducationActions.readUserEducation({id}));
		setReloadEducation(false);
    education.isEdit = false;
	}, [reloadEducation]);

	const submitCallback = (userEducationData: ISignUpData) => {
		dispatch(EducationActions.createEducation(userEducationData, history));
		dispatch(EducationActions.readUserEducation({id}));
		setReloadEducation(true);
	};
	const updateCallback = (educationUpdateData: ISignUpData) => {
		dispatch(EducationActions.updateEducation(educationUpdateData));
		dispatch(EducationActions.readUserEducation({id}))
		setReloadEducation(true);
	};

	if (!isAuthenticated) return <Redirect to="/login" />;
	return (
		<div className="row mt-3 m-0">
			<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
				<UserEducationData userId={id} submitCallback={submitCallback} education={education} validationSchema={validationSchema} />
			</div>
			<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
				<Data id={id} educations={educationData} totalEducations={totalEducations} updateCallback={updateCallback} education={education} validationSchema={validationSchema} />
			</div>
		</div>
	);
};