import React, {useEffect, useState} from 'react';
import { ILink, ISignUpData, IUser } from 'utils/types';
import { LinkActions } from 'actions';
import { UserLinkData, Data } from 'components/organisms/UserLinkData';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { IRootState } from 'reducers/rootReducer';
import { validationSchema } from './validations';
import { useHistory } from 'react-router-dom';

interface Props {}

export const Links: React.FC<Props> = ({}: Props) => {
  const dispatch = useDispatch();
	const history = useHistory();
	const [reloadLink, setReloadLink] = useState(false);
	const { id, isAuthenticated } = useSelector((state: IRootState) => state.auth);
	const user: IUser = useSelector((state: IRootState) => state.user);
	const link: ILink = useSelector((state: IRootState) => state.link);
	const linkData = Object.values(link.data);
	const totalLinks = link?.totalLinks;

	useEffect(() => {
		dispatch(LinkActions.readUserLink({id}));
		setReloadLink(false);
    link.isEdit = false;
	}, [reloadLink]);

	const submitCallback = (userLinkData: ISignUpData) => {
		dispatch(LinkActions.createLink(userLinkData, history));
		dispatch(LinkActions.readUserLink({id}));
		setReloadLink(true);
	};
	const updateCallback = (linkUpdateData: ISignUpData) => {
		dispatch(LinkActions.updateLink(linkUpdateData));
		dispatch(LinkActions.readUserLink({id}))
		setReloadLink(true);
	};    

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <div className="row mt-3 m-0">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
        <UserLinkData userId={id} user={user} submitCallback={submitCallback} link={link} validationSchema={validationSchema}  />
      </div>
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
        <Data id={id} links={linkData} totalLinks={totalLinks} updateCallback={updateCallback} link={link} validationSchema={validationSchema} />
      </div>
    </div>
  );
};