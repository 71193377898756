import React, { useEffect, useState } from 'react';
import { IUser } from 'utils/types';
import { ActionGroupDispatcher, AuthActions, UserActions } from 'actions';
import { UserLanguageData, Data } from 'components/organisms/UserLanguageData';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { IRootState } from 'reducers/rootReducer';
import { validationSchema } from './validations';

interface Props { }

export const Language: React.FC<Props> = ({ }: Props) => {
  const dispatch = useDispatch();
  const [reloadLanguage, setReloadLanguage] = useState(false);
  const { id, isAuthenticated } = useSelector((state: IRootState) => state.auth);
  const user: IUser = useSelector((state: IRootState) => state.user);
  const language = user.languages;
  const languageData = Object.values(language);

  useEffect(() => {
		dispatch(UserActions.readUserLanguage({id}));
		setReloadLanguage(false);
	}, [reloadLanguage, language]);

  const submitCallback = (languageData: any) => {
    language.push(languageData);
    const actionGroupDispatcher = ActionGroupDispatcher([AuthActions.refreshToken(id), UserActions.updatePersonalData(user)]);
    actionGroupDispatcher(dispatch);
    setReloadLanguage(true);
  };
  const updateCallback = (index: any) => {
    const i = language.indexOf(language.find(e => e.id == index))
		language.splice(i,1);
    const actionGroupDispatcher = ActionGroupDispatcher([AuthActions.refreshToken(id), UserActions.updatePersonalData(user)]);
    actionGroupDispatcher(dispatch);
    setReloadLanguage(true);
  };

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <div className="row mt-3 m-0">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
        <UserLanguageData userId={id} submitCallback={submitCallback} language={language} validationSchema={validationSchema} />
      </div>
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
        <Data id={id} languages={languageData} updateCallback={updateCallback} validationSchema={validationSchema} />
      </div>
    </div>
  );
};