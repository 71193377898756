import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { IRootState } from 'reducers/rootReducer';
import { UserCurriculum } from 'components/organisms/UserCurriculum';
import { IUser } from 'utils/types';

interface Props {}

export const Curriculum: React.FC<Props> = ({}: Props) => {
  const BASE_API = process.env.REACT_APP_BASE_API_URL;
  const { id, isAuthenticated } = useSelector((state: IRootState) => state.auth);
  const [curriculum64, setCurriculum64] = useState();

  const getCurriculum = () => {
		fetch(`${BASE_API}/users/curriculum/${id}`)
    .then(response => {
      return response.json();
    })
    .then(result => {
      setCurriculum64(result.data)
    }).catch(error => {
      console.log(error)
    })
	};

  useEffect(() => {
     getCurriculum();
  }, []);

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <UserCurriculum curriculum64={curriculum64} />
  )
};