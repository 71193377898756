export const levels = [
  {
    value: 'BEGINNER',
    name: 'Beginner'
  },
  {
    value: 'ELEMENTARY',
    name: 'Elementary'
  },
  {
    value: 'PRE INTERMEDIATE',
    name: 'Pre Intermediate'
  },
  {
    value: 'INTERMEDIATE',
    name: 'Intermediate'
  },
  {
    value: 'UPPER INTERMEDIATE',
    name: 'Upper Intermediate'
  },
  {
    value: 'ADVANCED',
    name: 'Advanced'
  },
  {
    value: 'NATIVE',
    name: 'Native'
  },
];

export const languages = [
  {
    value: 'English',
    name: 'English'
  },
  {
    value: 'French',
    name: 'French'
  },
  {
    value: 'German',
    name: 'German'
  },
  {
    value: 'Italian',
    name: 'Italian'
  },
  {
    value: 'Portuguese',
    name: 'Portuguese'
  },
  {
    value: 'Spanish',
    name: 'Spanish'
  },
];

export const levelsEducation = [
  {
    value: 'Secondary',
    name: 'Secondary'
  },
  {
    value: 'Tertiary',
    name: 'Tertiary'
  },
  {
    value: 'College',
    name: 'College'
  },
  {
    value: 'Master',
    name: 'Master'
  },
  {
    value: 'Doctoral',
    name: 'Doctoral'
  },
  {
    value: 'Postgraduate',
    name: 'Postgraduate'
  },
  {
    value: 'Courses',
    name: 'Courses'
  },
  {
    value: 'Certifications',
    name: 'Certifications'
  },
  {
    value: 'Specializations',
    name: 'Specializations'
  }
];

export const allSeniorities = [
  {
    value: 1,
    title: "Trainee"
  },
  {
    value: 2,
    title: "Junior"
  },
  {
    value: 3,
    title: "Semi Senior"
  },
  {
    value: 4,
    title: "Senior"
  },
  {
    value: 5,
    title: "Tech Manager"
  },
  {
    value: 6,
    title: "Director"
  }
];