import { AuthReducers } from 'reducers/authReducer';
import { UserReducers } from 'reducers/userReducer';
import { UtilsReducers } from 'reducers/utilsReducer';
import { login, loginGoogle, refreshToken } from 'utils/API';
import { ILoginForm, IDispatch, IAxiosResponse, IUserToken, ToastStatus } from 'utils/types';

type LoginHandler = (user: ILoginForm) => (dispatch: IDispatch, getState) => void;
type LoginGoogleHandler = (googleData) => (dispatch: IDispatch, getState) => void;
type LogoutHandler = () => (dispatch: IDispatch) => void;
type RefreshTokenHandler = (userID: number) => (dispatch: IDispatch, getState: () => any) => void;

const loginHandler: LoginHandler = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });
    const loginResponse = (await login(user)) as IAxiosResponse<IUserToken>;
    const loginUser = {
      user: {
        ...loginResponse.data.user,
      },
      token: loginResponse.data.token,
    };
    dispatch({
      type: AuthReducers.LOGIN_SUCCESS,
      payload: loginUser,
    });
    dispatch({
      type: UserReducers.UPDATE_USER,
      payload: loginUser.user,
    });
  } catch (err) {
    dispatch({
      type: AuthReducers.LOGIN_FAILED,
    });
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: 'The entered email or password is incorrect', status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

const loginHandlerGoogle: LoginGoogleHandler = (googleData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });
    const loginResponse = (await loginGoogle(googleData)) as IAxiosResponse<IUserToken>;
    const loginUser = {
      user: {
        ...loginResponse.data.user,
      },
      token: loginResponse.data.token,
    };
    dispatch({
      type: AuthReducers.LOGIN_SUCCESS,
      payload: loginUser,
    });
    dispatch({
      type: UserReducers.UPDATE_USER,
      payload: loginUser.user,
    });
  } catch (err) {
    dispatch({
      type: AuthReducers.LOGIN_FAILED,
    });
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: 'The entered google acount is incorrect', status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

const logoutHandler: LogoutHandler = () => async (dispatch) => {
  try {
    dispatch({
      type: AuthReducers.LOGOUT_SUCCESS,
    });
    dispatch({
      type: UserReducers.CLEAR_USER,
    });
  } catch (err) {
    console.log(err);
  }
};

const refreshTokenHandler: RefreshTokenHandler = (userID) => async (dispatch) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });
    const apiConfig = localStorage.getItem('token');
    const refreshTokenResponse = (await refreshToken(userID, apiConfig)) as IAxiosResponse<IUserToken>;
    dispatch({
      type: AuthReducers.REFRESH_TOKEN,
      payload: refreshTokenResponse.data.token,
    });
    dispatch({
      type: UserReducers.UPDATE_USER,
      payload: refreshTokenResponse.data.user,
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: err.message, status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

export const AuthActions = {
  login: loginHandler,
  loginGoogle: loginHandlerGoogle,
  logout: logoutHandler,
  refreshToken: refreshTokenHandler,
};