import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ExperienceActions, AuthActions } from 'actions';
import { listSkills } from 'utils/API';
import { useValidationResolver } from 'utils/useValidationResolver';
import { Autocomplete, Input } from 'components/atoms';
import './styles.scss';
import {
  ISkill,
  IAxiosResponse,
  INewSkill,
  IExperience,
} from 'utils/types';
import { IRootState } from 'reducers/rootReducer';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import Moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useHistory } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { MAX_CHARACTERS } from 'utils/variables';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const querySkils = async (search: string, setSkillOptions) => {
  const response = (await listSkills(search)) as IAxiosResponse<ISkill[]>;
  if (response.data) setSkillOptions(response.data);
};

interface Props {
  userId?: number;
  submitCallback: (userExperienceData: IExperience) => void;
  experience?: IExperience;
  validationSchema: any;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 5, 3),
  },
}));

export const UserExperienceData: React.FC<Props> = ({ userId, submitCallback, experience, validationSchema }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
	const classes = useStyles();
  const { isLoading } = useSelector((state: IRootState) => state.utils);
  const [open, setOpen] = React.useState(false);
  const resolver = useValidationResolver(validationSchema);
  const [countCharacters, setCountCharacters] = useState(0);
  const [submit, setSubmit] = useState(true);
  const { register, handleSubmit, errors } = useForm({
    mode: 'onTouched',
    resolver,
    defaultValues: { experience },
  });

  useEffect(() => {
    if (userId) dispatch(AuthActions.refreshToken(userId));
  }, []);

  useEffect(() => {
    setSubmit(true)
  }, [!submit]);

  const onSubmit = async (data: IExperience, e): Promise<void> => {
    data.userId = userId;
    const userExperienceData = {
      ...data,
      skills: experience.skills,
    };
    if (startYear <= finishYear || data.finishYear == 'Present') {
      e.target.reset();
			submitCallback(userExperienceData);
		} else {
			alert('Your finishYear cannot be less than the startYear')
    }
    setChecked(false)
    setStartYear('')
    setFinishYear('')
    setSubmit(false)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddSkill = (selectedSkill: ISkill | INewSkill) => {
    dispatch(ExperienceActions.addSkill(selectedSkill));
  };
  const handleDeleteSkill = (index: number) => {
    dispatch(ExperienceActions.deleteSkill(index));
  };

  const yesterday = Moment().subtract(1, 'day');
  const valid = function (current) {
    return current <= yesterday;
  };
  const inputPropsStart = {
    placeholder: 'Pick a year',
    name: 'startYear',
    autoComplete: 'off',
    disabled: experience.isEdit,
    readOnly: true
  }
  const inputPropsFinish = {
    placeholder: 'Pick a year',
    name: 'finishYear',
    autoComplete: 'off',
    disabled: experience.isEdit,
    readOnly: true
  }

  const handleAddStart = (date) => {setStartYear(date.year())}
  const [startYear, setStartYear] = useState('');
  const handleAddFinish = (date) => {setFinishYear(date.year())}
  const [finishYear, setFinishYear] = useState('');
  const [checked, setChecked] = useState(false);

  const handleChecbox = () => {
    if (checked == true) {
      setChecked(false)
    } else {
      setChecked(true)
    }
  }

  const handleContinue = () => {
		const title = ((document.getElementById('title')) as HTMLInputElement).value
		const company = ((document.getElementById('company')) as HTMLInputElement).value
    if ((title != '') || (company != '') || (startYear != '') || (finishYear != '')) {
			setOpen(true);
		} else {
			history.push('/languages');	
		}
  }

  const handleStayModal = () => {
    setOpen(false)
  };

	const handleContinueModal = () => {
    history.push('/languages');	
  };

  return (
    <form id="experience-form" onSubmit={handleSubmit(onSubmit)} className="experience-form pb-5" onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}}>
      <div className="form-body">
        <div className="text-form">
          <div className="row mt-4">
            <div className="title col-12 ">
              <Input name="title" id="title" label="Position *" register={register} error={errors?.title}
                disabled={experience.isEdit} />
            </div>
          </div>
          <div className="row mt-4">
            <div className="company col-12  ">
              <Input name="company" id="company" label="Company" register={register} error={errors?.company}
                disabled={experience.isEdit} />
            </div>
          </div>
          <div className="row mt-3">
            <div className="startYear col-12 col-lg-6 mx-auto">
              <label className="form-label">Start Year *</label>
              {submit ? 
                <>
                  <Datetime dateFormat="YYYY" isValidDate={valid} timeFormat={false} className={experience.isEdit ? "" : "notEdit"}
                    inputProps={inputPropsStart} onChange={e => handleAddStart(e)} />
                  <Input type="hidden" value={startYear} name="startYear" id="startYear" register={register} 
                    disabled={experience.isEdit} className={experience.isEdit ? "" : "notEdit"}/>
                </>
               : null}
            </div>
            <div className="finishYear col-12 col-lg-6 mx-auto">
              <label className="form-label">Finish Year *</label>
              {checked ?
                <Input name="finishYear" register={register} value="Present" readOnly /> 
              : null }
              {(submit && !checked) ? 
                <>
                  <Datetime dateFormat="YYYY" className={experience.isEdit ? "" : "notEdit"} isValidDate={valid} timeFormat={false}
                    inputProps={inputPropsFinish} onChange={e => handleAddFinish(e)} />
                  <Input type="hidden" value={finishYear} name="finishYear" id="finishYear" register={register}
                  disabled={experience.isEdit} />
                </>
              : null}                
            </div>
          </div>
          <div className="row mt-4">
            <div className="checkbox col-12">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChecbox}
                    name="checkedB"
                    color="default"
                  />
                }
                label="Ongoing"
                className="form-label form-checkbox"
                disabled={experience.isEdit}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="description">
              <label htmlFor="description-input" className="form-label">
                Description
              </label>
              <textarea
                name="description"
                ref={register}
                disabled={experience.isEdit}
                className="form-control"
                id="description-input"
                placeholder="Tell us about your experience in that position (preferably in English)"
                onChange={e => setCountCharacters(e.target.value.length)}
                maxLength={MAX_CHARACTERS} />
              <label className="label-introduction">{countCharacters}/{MAX_CHARACTERS}</label>
            </div>
          </div>
          <div className="row mt-3">
            <label className="form-label">Tech Skills</label>
            <Autocomplete getOptions={querySkils} onSelect={handleAddSkill} disabled={experience.isEdit} />
          </div>
          <div className="skills-row row mt-2">
            {experience.isEdit == false && experience.skills?.length
              ? experience.skills.map((skill, index) => (
                <span
                  key={skill.id ? 'skill' + skill.id : 'newSkill' + index}
                  className='col-auto mt-2 mx-2 btn badge rounded-pill skill-badge'
                  onClick={() => handleDeleteSkill(index)}>
                  {skill.title}
                  <span>
                  <Tooltip title="delete skill">
                    <IconButton aria-label="delete skill">
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                  </span>
                </span>
              ))
              : null}
          </div>
        </div>
        <Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className={classes.modal}
					open={open}
					onClose={handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={open}>
						<div className={classes.paper}>
							<p id="transition-modal-description">There are unsaved changes. If you continue, all unsaved data will be lost.</p>
							<div className="save-submit row">
								<button type="button" className="continue m-1 btn button-orange" onClick={handleStayModal}>
									Stay
								</button>
								<button type="button" className="continue m-1 btn button-orange" onClick={handleContinueModal}>
									Continue
								</button>
							</div>
						</div>
					</Fade>
				</Modal>
        {userId ? (
          <div>
            <div className="save-submit row mt-4">
              <button className="save btn button-orange" type="submit">
                {isLoading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  'Add'
                )}
              </button>
              <button className="continue btn button-orange " type="button" onClick={() => handleContinue()}>
                <NavigateNextIcon fontSize="large"/>
              </button>
            </div>
          </div>
        ) : (
          <div className="row mt-4">
            <button className="btn button-orange mx-auto" type="submit">
              {isLoading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                'SIGN UP'
              )}
            </button>
          </div>
        )}
      </div>
    </form>
  );
};