import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import deletePictureButton from 'assets/images/trash-alt.svg';
import { makeStyles } from '@material-ui/core/styles';
import { IRootState } from 'reducers/rootReducer';
import {
	ILanguage,
} from 'utils/types';
import { UserActions } from 'actions';

const useStyles = makeStyles({
	root: {
		minWidth: 275,
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

interface Props {
	languages:  Array<ILanguage>;
	updateCallback: any;
	validationSchema: any;
	languageDOM?: ILanguage;
}

export const Cards: React.FC<Props> = ({ languages, updateCallback, validationSchema }: Props) => {
	const classes = useStyles();
	const { id, isAuthenticated } = useSelector((state: IRootState) => state.auth);
	const handleDeleteLanguage = (index: number) => {
    if (window.confirm("Are you sure you want to delete this language?")) {
      updateCallback(index);
		}
	};

	return (
		<div>
			{languages.map((language, index) => (
				<div key={index} className="card-language">
					<Card className={classes.root}>
						<CardContent>
							<span className="profile-picture-delete"
								onClick={() => handleDeleteLanguage(language.id)}>
								<input type="button" />
								<img alt="delete" src={deletePictureButton} />
							</span>
							<label >
								{language.title}
							</label>
							<Typography className={classes.pos} color="textSecondary">
								{language.level.charAt(0) + language.level.slice(1).toLowerCase()}
							</Typography>
						</CardContent>
					</Card>
				</div>
			))}
		</div>
	);
}