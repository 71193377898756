import React from "react";
import './styles.scss';
import OpenButton from 'assets/images/open-icon.svg';

interface Props {
	curriculum64: any;
}

export const UserCurriculum: React.FC<Props> = ({ curriculum64 }: Props) => {
	
	const base64toBlob = (curriculum64: string) =>{
		const sliceSize = 1024;
		const byteCharacters = atob(curriculum64);
		const bytesLength = byteCharacters.length;
		const slicesCount = Math.ceil(bytesLength / sliceSize);
		const byteArrays = new Array(slicesCount);
	
		for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
			const begin = sliceIndex * sliceSize;
			const end = Math.min(begin + sliceSize, bytesLength);
			const bytes = new Array(end - begin);
			for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
				bytes[i] = byteCharacters[offset].charCodeAt(0);
			}
			byteArrays[sliceIndex] = new Uint8Array(bytes);
		}
		return new Blob(byteArrays, { type: "application/pdf" });
	}

	const openBase64NewTab = (curriculum64: string): void => {
		var blob = base64toBlob(curriculum64);
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
		} else {
			const blobUrl = URL.createObjectURL(blob);
			window.open(blobUrl);
		}
	}

	return (
		<div>
			<div className="col-12 openUrl">
				<a onClick={(): void => openBase64NewTab(curriculum64)} target="_blank" className="row p-0">
					<div className="col-10 textOpen">
						Open Curriculum
					</div>
					<div className="col-1">
						<img src={OpenButton} alt="" className="iconOpen"/>
					</div>
				</a>
			</div>
			<div className="preview">
				{curriculum64 ? (
					<object
						data={"data:application/pdf;base64,"+curriculum64}
						type="application/pdf" width="100%" height="600px">
					</object>
				) : null}
			</div>
		</div>
	);
}