import { AuthReducers } from 'reducers/authReducer';
import { UserReducers } from 'reducers/userReducer';
import { UtilsReducers } from 'reducers/utilsReducer';
import { signUp, updatePersonalData, getUser, getUserLanguages, login, getCurriculum } from 'utils/API';
import { IDispatch, ISignUpData, IPersonalData, IAxiosResponse, IUser, ISkill, INewSkill, ILanguage, INewLanguage, ToastStatus, IFile, ILoginForm, IUserToken } from 'utils/types';

type Filter = (obj: object) => object;
type CreateUser = (signUpData: ISignUpData, history: any) => (dispatch: IDispatch, getState: () => any) => void;
type UpdatePersonalDataHandler = (personalData: IPersonalData) => (dispatch: IDispatch, getState: () => any) => void;
type UpdateProfilePicture = (profilePictureUrl: object) => (dispatch: IDispatch) => void;
type DeleteProfilePicture = () => (dispatch: IDispatch) => void;
type DeleteProfileCV = () => (dispatch: IDispatch) => void;
type AddSkill = (selectedSkill: ISkill | INewSkill) => (dispatch: IDispatch) => void;
type DeleteSkill = (skillIndex: number) => (dispatch: IDispatch) => void;
type AddLanguages = (selectedLanguage: ILanguage | INewLanguage) => (dispatch: IDispatch) => void;
type DeleteLanguages = (languageIndex: number) => (dispatch: IDispatch) => void;
type UpdateCurriculum = (curriculumUrl: object) => (dispatch: IDispatch) => void;
type ReadUser = (id: number) => (dispatch: IDispatch) => void;
type ReadUserLanguage = ({id}) => void;

const filter: Filter = (obj) => {
  const asArray = Object.entries(obj);
  const filtered = asArray.filter(([_, value]) => value && value !== '' && value !== null);
  return Object.fromEntries(filtered);
};

const createUser: CreateUser = (signUpData, history) => async (dispatch) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });

    const data = filter(signUpData) as ISignUpData;
    const signUpResponse = (await signUp(data)) as IAxiosResponse<IUser>;
    const log = {email: data.email, password: data.password};
    if (signUpResponse.data) {
      const loginResponse = (await login(log)) as IAxiosResponse<IUserToken>
      const loginUser = {
        user: {
          ...loginResponse.data.user,
        },
        token: loginResponse.data.token,
      };
      dispatch({
        type: AuthReducers.LOGIN_SUCCESS,
        payload: loginUser,
      });
    };
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: 'User account created successfully', status: ToastStatus.SUCCESS },
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: 'The email entered already has an associated account', status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

const updatePersonalDataHandler: UpdatePersonalDataHandler = (personalData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });

    const { id, ...data } = filter(personalData) as IPersonalData;
    const requestBody = {
      id,
      data,
    };
    const updatePersonalDataResponse = (await updatePersonalData(requestBody)) as IAxiosResponse<IUser>;
    dispatch({
      type: UserReducers.UPDATE_USER,
      payload: updatePersonalDataResponse.data,
    });
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: 'User data updated successfully', status: ToastStatus.SUCCESS },
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: err.message, status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

const updateProfilePicture: UpdateProfilePicture = (profilePictureUrl: IFile) => (dispatch) => {
  dispatch({
    type: UserReducers.UPDATE_PROFILE_PICTURE,
    payload: profilePictureUrl,
  });
};
const deleteProfilePicture: DeleteProfilePicture = () => (dispatch) => {
  dispatch({
    type: UserReducers.DELETE_PROFILE_PICTURE,
  });
};
const deleteProfileCV: DeleteProfileCV = () => (dispatch) => {
  dispatch({
    type: UserReducers.DELETE_PROFILE_CV,
  });
};

const addSkill: AddSkill = (selectedSkill) => async (dispatch) => {
  dispatch({
    type: UserReducers.ADD_SKILL,
    payload: selectedSkill,
  });
};
const deleteSkill: DeleteSkill = (skillIndex) => async (dispatch) => {
  dispatch({
    type: UserReducers.DELETE_SKILL,
    payload: skillIndex,
  });
};

const addLanguage: AddLanguages = (selectedLanguage) => async (dispatch) => {
  dispatch({
    type: UserReducers.ADD_LANGUAGE,
    payload: selectedLanguage,
  });
};
const deleteLanguage: DeleteLanguages = (languageIndex) => async (dispatch) => {
  dispatch({
    type: UserReducers.DELETE_LANGUAGE,
    payload: languageIndex,
  });
};

const updateCurriculum: UpdateCurriculum = (curriculumUrl) => (dispatch) => {
  dispatch({
    type: UserReducers.UPDATE_CURRICULUM,
    payload: curriculumUrl,
  });
};

const readUser: ReadUser = (id) => async (dispatch) => {
  const user = await getUser(id);
  dispatch({
    type: UserReducers.READ_USER,
    payload: user,
  })
};

const readUserLanguage: ReadUserLanguage = ({id}) => async (dispatch) => {
  const languages = await getUserLanguages({id});
  dispatch({
    type: UserReducers.READ_USER_LANGUAGE,
    payload: languages,
  })
};

export const UserActions = {
  createUser,
  updatePersonalData: updatePersonalDataHandler,
  updateProfilePicture,
  deleteProfilePicture,
  deleteProfileCV,
  addSkill,
  deleteSkill,
  addLanguage,
  deleteLanguage,
  updateCurriculum,
  readUser, 
  readUserLanguage
};