import { useEffect, useState, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions, AuthActions } from 'actions';
import { useHistory } from 'react-router-dom';
import updatePictureButton from 'assets/images/edit.svg';
import deletePictureButton from 'assets/images/trash-alt.svg';
import DefaultPicture from 'assets/images/default_picture.jpg';
import OpenButton from 'assets/images/open-icon.svg'
import { useValidationResolver } from 'utils/useValidationResolver';
import { Autocomplete, Input } from 'components/atoms';
import './styles.scss';
import { listSkills } from 'utils/API';
import { getBase64File } from 'utils/files';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {
  IPersonalDataForm,
  IUser,
  IPersonalData,
  ISignUpData,
  ISkill,
  IAxiosResponse,
  ISignUpDataForm,
  INewSkill,
} from 'utils/types';
import { IRootState } from 'reducers/rootReducer';
import ReactCrop from 'react-image-crop';
import "react-image-crop/dist/ReactCrop.css";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {MAX_CHARACTERS} from 'utils/variables';
import { CurriculumField } from 'components/molecules/CurriculumField';

const querySkils = async (search: string, setSkillOptions) => {
  const response = (await listSkills(search)) as IAxiosResponse<ISkill[]>;
  if (response.data) setSkillOptions(response.data);
};

interface Props {
  id?: number;
  submitCallback: (userData: ISignUpData | IPersonalData) => void;
  user?: IUser;
  userPicture: any;
  validationSchema: any;
  personalCurriculum64: string
}

export const UserData: React.FC<Props> = ({ id, submitCallback, user, userPicture, validationSchema, personalCurriculum64 }) => {
  const history = useHistory();
  const [file, setFile] = useState('');
  const [showCanvas, setShowCanvas] = useState(false);
  const [showEditPhoto, setShowEditPhoto] = useState(false);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ width: 50, unit: '%', aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const dispatch = useDispatch()
  const [picture, setPicture] = useState(user.picture);
  const resolver = useValidationResolver(validationSchema);
  const [deletePicture, setDeletePicture] = useState(false)
  const { isLoading } = useSelector((state: IRootState) => state.utils);
  const [countCharacters,setCountCharacters] = useState(0);
  const { register, handleSubmit, setValue, watch, errors } = useForm({
    mode: 'onTouched',
    resolver,
    defaultValues: {
      ...user,
      curriculum: '',
      picture: '',
    },
  })

  const openBase64NewTab = (curriculum64: string): void => {
		var blob = base64toBlob(curriculum64);
		if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
			(window.navigator as any).msSaveOrOpenBlob(blob, "pdfBase64.pdf");
		} else {
			const blobUrl = URL.createObjectURL(blob);
			window.open(blobUrl);
		}
	}
  
  const base64toBlob = (curriculum64: string) =>{
		const sliceSize = 1024;
		const byteCharacters = atob(curriculum64);
		const bytesLength = byteCharacters.length;
		const slicesCount = Math.ceil(bytesLength / sliceSize);
		const byteArrays = new Array(slicesCount);
	
		for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
			const begin = sliceIndex * sliceSize;
			const end = Math.min(begin + sliceSize, bytesLength);
	
			const bytes = new Array(end - begin);
			for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
				bytes[i] = byteCharacters[offset].charCodeAt(0);
			}
			byteArrays[sliceIndex] = new Uint8Array(bytes);
		}
		return new Blob(byteArrays, { type: "application/pdf" });
	}

  useEffect(() => {
    if (id && user && !watch('firstName')) {
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
      setValue('country', user.country);
      setValue('city', user.city);
      setValue('email', user.email);
      setValue('phone', user.phone);
      setValue('introduction', user.introduction);
      setValue('skills', user.skills);
    }
  }, [id, user])

  useEffect(() => {
    if (id) dispatch(AuthActions.refreshToken(id));
  }, []);

  useEffect(() => {
    if (user?.picture?.data) {
      setPicture({
        data: user.picture?.data.substr(22), 
        extension: user.picture?.extension,
      })
    } else if (deletePicture) {
      setPicture({
        data: 'null',
        extension: null
      })
    }
  }, [deletePicture, user?.picture]);

  const onSubmit = async (data: ISignUpDataForm | IPersonalDataForm): Promise<void> => {
    let userData
    if (user.curriculumUrl) {
      userData = {
        ...data,
        id,
        curriculumUrl: user.curriculumUrl,
        picture: picture,
        skills: user.skills,
      };
    } else {
      userData = {
        ...data,
        id,
        curriculum: (data?.curriculum) && await getBase64File(data?.curriculum[0]),
        picture: picture,
        skills: user.skills,
      };
    }
    submitCallback(userData);
    setShowCanvas(false);
    setShowEditPhoto(false);
    user.skills = [];
  };

  const getBase64 = (file) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const baseURL = reader.result.toString();
        resolve(baseURL);
      }        
    })
  } 
  
  const handleUploadImage = (e) => {
    setShowEditPhoto(true);
    const file = (e.target.files[0]);
    if (!file) return;
    getBase64(file)
      .then (setFile)
      .catch( err => {
        console.log(err);
      })
  } 
  const onLoad = useCallback((img) => {
    imgRef.current = img;
    setShowCanvas(true);
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const context = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio; 
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    context.imageSmoothingQuality = 'high';
    context.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    let dataURL = canvas.toDataURL()
    if (dataURL) {
      let picture = {
        data: dataURL, 
        extension: dataURL.split(';')[0].split('/')[1],
      }
      dispatch(UserActions.updateProfilePicture(picture))
    }
  }, [completedCrop, previewCanvasRef ])

  const handleDeleteImage = () => {
    setDeletePicture(true);
    setShowEditPhoto(false);
    setShowCanvas(false)
    user.pictureUrl = DefaultPicture;
    dispatch(UserActions.deleteProfilePicture());
  }

  const handleAddSkill = (selectedSkill: ISkill | INewSkill) => {
    dispatch(UserActions.addSkill(selectedSkill));
  }

  const handleDeleteSkill = (index: number) => {
    dispatch(UserActions.deleteSkill(index));
  }

  const handleDeleteCurriculum = () => {
    dispatch(UserActions.deleteProfileCV());
  }

  const handleContinue = () => {
    history.push('/education');
  }

  const cancelPhoto = () => {
    setShowEditPhoto(false)
    const picture2 = {
      data: userPicture, 
      extension: userPicture?.split(';')[0].split('/')[1],
    }
    dispatch(UserActions.updateProfilePicture(picture2));
  }

  useEffect(() => {
    if (user.introduction) {
      setCountCharacters(user.introduction.length)
    }
  },[user.introduction])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="personal-data-form pb-5" >
      <div className="form-body row col-lg-10 col-md-10 col-sm-12 col-xs-12 mx-auto">
        <div className="col-lg-3 col-md-5 col-sm-4 col-xs-12 mx-auto">
          <div className="profile-picture mt-3 mx-auto">
            <label className="form-label" id="form-label-profile"> Profile picture </label>
            <label className="profile-picture-delete">
              <input type="button" onClick={handleDeleteImage} />
              <img alt="delete" src={deletePictureButton} />
            </label>
            <label className="profile-picture-update-icon">
              <input type="file" accept="image/*" name="picture" onChange={handleUploadImage} ref={register} />
              <img alt="update" src={updatePictureButton} />
            </label>
            {
              showEditPhoto 
              ? (<div>  
                  <div className="react-crop">
                    <ReactCrop
                      src={file}
                      onImageLoaded={onLoad}
                      crop={crop}
                      onChange={(c) => setCrop(c)}
                      onComplete={(c) => setCompletedCrop(c)}
                    />
                  </div>
                  <div className="buttons-photo">
                    <button className="savePhoto btn button-orange" onClick={cancelPhoto} >
                      <CloseIcon />
                    </button>
                    <button className="savePhoto btn button-orange" type="submit" onSubmit={handleSubmit(onSubmit)} >
                      <CheckIcon />
                    </button>
                  </div>
                </div>) 
              : <label className="profile-picture-update img">
                  { user && ((typeof user.picture == 'object') && (user.picture?.data) ) 
                  ? ( <img alt="update" className="profile-img" src={user.picture?.data} /> ) 
                  : ( <img alt="update" className="profile-img" src={(typeof user.pictureUrl == 'string') ? user.pictureUrl : DefaultPicture} /> ) 
                  }
                </label>
            }
            <br />
            {
              showCanvas && 
                <div className="mx-auto mt-2 canvas">
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      width: Math.round(completedCrop?.width ?? 0),
                      height: Math.round(completedCrop?.height ?? 0)
                    }}
                  />
                </div>
            }
          </div>
        </div>
        <div className="text-form col-md-7 col-sm-8 col-xs-12 mx-auto" id="formRight">
          <div className="row mt-3 ml-0 mr-0">
            <div className="first-name col-12 col-lg-6 mx-auto">
              <Input name="firstName" label="First Name *" register={register} error={errors?.firstName} 
              onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}}/>
            </div>
            <div className="last-name col-12 col-lg-6 mx-auto mt-3 mt-lg-0">
              <Input name="lastName" label="Last Name *" register={register} error={errors?.lastName} 
              onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}}/>
            </div>
          </div>
          <div className="row mt-3 ml-0 mr-0">
            <div className="country col-12 col-lg-6 mx-auto">
              <Input name="country" label="Country" register={register} error={errors?.country} 
              onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}}/>
            </div>
            <div className="city col-12 col-lg-6 mx-auto mt-3 mt-lg-0">
              <Input name="city" label="City" register={register} error={errors?.city} 
              onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}}/>
            </div>
          </div>
          <div className="row mt-3 ml-0 mr-0">
            <div className="email col-12 col-lg-6 mx-auto">
              <Input
                type="email"
                name="email"
                label="Email *"
                register={register}
                error={errors?.email}
                disabled={id ? true : false}
              />
            </div>
            <div className="phone col-12 col-lg-6 mx-auto mt-3 mt-lg-0">
              <Input type="tel" step={null} maxLength={15} min={0} name="phone" autoComplete={"off"} label="Phone" register={register} error={errors?.phone} 
              onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}}/>
            </div>
          </div>
          <div className="row mt-3">
            <div className="introduction">
              <label htmlFor="introduction-input" className="form-label">
                Introduction
              </label>
              <textarea
                name="introduction"
                ref={register}
                className="form-control introduction"
                id="introduction-input"
                placeholder="Write a short introduction about yourself (preferably in English)"
                onChange={e => setCountCharacters(e.target.value.length)}
                maxLength={MAX_CHARACTERS}
              />
              {errors.introduction && <label className="error">{errors.introduction.message}</label>}
              <label className="label-introduction">{countCharacters}/{MAX_CHARACTERS}</label>
            </div>
          </div>
          <label htmlFor="formFile" className="form-label">
          Curriculum (PDF)
          </label>
          {
            (user.curriculumUrl && user.curriculums.length)
            ? ( <div className="row p-0 m-0">
                  <div className="col-md-11 p-0 inputCV">
                    <a onClick={(): void => openBase64NewTab(personalCurriculum64)} target="_blank" className="row p-0">
                      <div className="col-3 textOpen">
                        Open Uploaded Curriculum
                      </div>
                      <div className="col-1">
                        <img className="iconOpen" src={OpenButton} alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="col-md-1 p-0 deleteCV">
                    <label className="profile-cv-delete">
                      <input type="button" onClick={handleDeleteCurriculum}/>
                      <img alt="delete" src={deletePictureButton} />
                    </label>
                  </div>
                </div>
              ) 
            : <CurriculumField register={register} />
          }
          <div className="row mt-3">
            <div className="skills">
              <label className="form-label">Skills</label>
              <Autocomplete getOptions={querySkils} onSelect={handleAddSkill}/>
            </div>
          </div>
          <div className="skills-row row mt-2 m-1">
            {
              user.skills?.length
              ? user.skills.map((skill, index) => (
                <span
                  key={skill.id ? 'skill' + skill.id : 'newSkill' + index}
                  className='col-auto mt-2 mx-2 btn badge rounded-pill skill-badge'
                  onClick={() => handleDeleteSkill(index)}
                >
                  {skill.title}
                  <span>
                    <Tooltip title="delete skill">
                      <IconButton aria-label="delete skill">
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                </span> )) 
              : ''}
          </div>
          <div className="save-submit mx-auto mt-4">
            <button className="save btn button-orange" type="submit">
              {
                isLoading 
                ? ( <div className="spinner-border text-light" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div> ) 
                : ('Save')
              }
            </button>
            <button className="continue btn button-orange" type="submit" onClick={() => handleContinue()}>
              <NavigateNextIcon fontSize="large"/>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};