import { IAction, IPassword } from 'utils/types';

type ForgotPasswordReducer = (state: IPassword, action: IAction) => IPassword;

export enum ForgotPasswordReducers {
  CLEAR_PASSWORD = 'CLEAR_PASSWORD',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
}

const initialState: IPassword = {
  email: '',
};

export const password: ForgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case ForgotPasswordReducers.CLEAR_PASSWORD:
      return initialState;
    case ForgotPasswordReducers.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};