import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions, ForgotPasswordActions } from 'actions';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { validationSchema } from './validations';
import { useValidationResolver } from 'utils/useValidationResolver';
import './style.scss';

import { ILoginForm, INewPassword } from 'utils/types';
import { IRootState } from 'reducers/rootReducer';
import { Input } from 'components/atoms';


export const NewPassword: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: IRootState) => state.utils);
  const resolver = useValidationResolver(validationSchema);
  const { register, handleSubmit, errors } = useForm({ mode: 'onTouched', resolver });
  const newpassword: INewPassword = useSelector((state: IRootState) => state.newpassword);
  let history = useHistory();

  const onSubmit = async (data: INewPassword) => {
    const querystring = window.location.search;
    const params = new URLSearchParams(querystring);
    data.resetToken = params.get('token');
    dispatch(ForgotPasswordActions.newPassword(data));
    history.push('/personal-data')
  };

  const changeStatus = () => {
    newpassword.password = '';
    newpassword.resetToken = '';
  }

  return (
    <div className="login">
      <div className="login-form">
        <h1 className="title"> 42People </h1>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="password mx-auto">
            <Input type="password" name="password" label="New Password" register={register} error={errors.password} />
          </div>
          <div className="rep-password col-12 col-lg-6 mx-auto mt-3 mt-lg-0">
            <Input
              type="password"
              name="repeatPassword"
              label="Repeat password *"
              register={register}
              error={errors?.repeatPassword}
            />
          </div>
          <div className="submit mx-auto">
            <button className="btn button-orange mx-auto" type="submit">
              {isLoading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                'RESET PASSWORD'
              )}
            </button>
          </div>
          <div className="signup mt-3">
            <label>Don't have an account?</label>
            <Link to="/signup" className="signup-button btn">
              Sign up
            </Link>
            <br/>
            <div onClick={() => changeStatus()}>
              <Link to="/login" className="back-button btn">
                Back
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};