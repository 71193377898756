import { UserActions } from './userActions';
import { UsersActions } from './usersActions';
import { AuthActions } from './authActions';
import { ExperienceActions } from './experienceActions';
import { EducationActions } from './educationActions';
import { LinkActions } from './linkActions';
import { StatusActions } from './statusActions';
import { ReferenceActions } from './referenceActions';
import { ForgotPasswordActions } from './forgotPasswordActions';

type IActionGroupDispatcher = (action) => (dispatch) => void;

const ActionGroupDispatcher: IActionGroupDispatcher = (actions) => (dispatch) => {
  const list = actions;
  list.forEach((action) => dispatch(action));
};

export { ActionGroupDispatcher, UserActions, UsersActions, AuthActions, ExperienceActions, 
  EducationActions, LinkActions, StatusActions, ReferenceActions, ForgotPasswordActions };