import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Cards } from './';
import { ILink } from 'utils/types';
import { Input } from 'components/atoms';

interface Props {
	id: number;
	links: Array<ILink>;
	totalLinks: number;
	updateCallback: (userLinkData: ILink) => void;
	link?: ILink;
	validationSchema: any;
}

export const Data: React.FC<Props> = ({ id, links, totalLinks, updateCallback, link, validationSchema }: Props) => {
	
  const [search, setSearch] = useState('');
  const filteredLinks = links.filter(
    (link) => { 
        return link.title.toUpperCase().includes(search.toUpperCase()) || 
        link.url.toUpperCase().includes(search.toUpperCase())
    }
  );

	if (totalLinks != 0) {
		return (
			<div className="card-link-form">
				<h5 className="text-no-link">MY LINKS</h5>
				<div className="filter col-md-12 col-sm-12 col-xs-12">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <Input value={search} type="text" onChange={e => setSearch(e.target.value)}
              name="filter" placeholder="Search" register={null} />
          </div>
        </div>
				<Cards links={filteredLinks} updateCallback={updateCallback} validationSchema={validationSchema} linkDOM={link} />
			</div>
		);
	} else {
		return (
			<div className="card-link-form">
				<h5 className="text-no-link">MY LINKS</h5>
				<h5 className="mt-4">You have not added any link yet..</h5>
			</div>
		)
	}
};