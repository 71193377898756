import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions, ForgotPasswordActions } from 'actions';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { validationSchema } from './validations';
import { useValidationResolver } from 'utils/useValidationResolver';
import './style.scss';
import CheckPicture from 'assets/images/check.svg';

import { ILoginForm, IPassword } from 'utils/types';
import { IRootState } from 'reducers/rootReducer';
import { Input } from 'components/atoms';
import { forgotPassword } from 'utils/API';


export const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: IRootState) => state.utils);
  const resolver = useValidationResolver(validationSchema);
  const { register, handleSubmit, errors } = useForm({ mode: 'onTouched', resolver });
  const password: IPassword = useSelector((state: IRootState) => state.password);
  const [sendEmail, setSendEmail] = useState(false);

  const onSubmit = async (data: IPassword) => {
    dispatch(ForgotPasswordActions.forgotPassword(data));
  };

  const changeStatus = () => {
    setSendEmail(false);
    password.email = '';
  }

  useEffect(() => {
    if (password.email != ''){
      setSendEmail(true);
    }
  }, [password])

  if (sendEmail) {
    return (
      <div className="login">
        <div className="login-form">
          <h1 className="title"> 42People </h1>
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <div className="message">
              <label>Check your email to change password</label>
            </div>
            <div className="span">
              <span>If you don't see the email in your inbox, check your spam folder.</span>
            </div>
            <br/>
            <div className="signup mt-3">
              <label>Don't have an account?</label>
              <Link to="/signup" className="signup-button btn">
                Sign up
              </Link>
              <br/>
              <div onClick={() => changeStatus()}>
                <Link to="/login" className="back-button btn">
                  Back
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return (
    <div className="login">
      <div className="login-form">
        <h1 className="title"> 42People </h1>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="email mx-auto">
            <Input type="email" name="email" label="Email" register={register} error={errors.email} />
          </div>
          <div className="submit mx-auto">
            <button className="btn button-orange mx-auto" type="submit">
              {isLoading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                'RESET PASSWORD'
              )}
            </button>
          </div>
          <div className="signup mt-3">
            <label>Don't have an account?</label>
            <Link to="/signup" className="signup-button btn">
              Sign up
            </Link>
            <br/>
            <div onClick={() => changeStatus()}>
              <Link to="/login" className="back-button btn">
                Back
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
};
