import { localstorage } from 'utils/localstorage';
import { IAction, IAuthState } from 'utils/types';

type AuthReducer = (state: IAuthState, action: IAction) => IAuthState;
export enum AuthReducers {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILED = 'LOGIN_FAILED',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  REGISTER_FAILED = 'REGISTER_FAILED',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
}

const initialState: IAuthState = {
  id: localstorage.getUserId(),
  token: localstorage.getToken(),
  isAuthenticated: localstorage.isAuthenticated(),
};

export const auth: AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthReducers.LOGIN_SUCCESS:
      localstorage.saveUserId(action.payload.user.id);
      localstorage.saveIsAuthenticated('true');
      localstorage.saveToken(action.payload.token);
      return {
        ...state,
        id: action.payload.user.id,
        isAuthenticated: true,
        token: action.payload.token,
      };
    case AuthReducers.REFRESH_TOKEN:
      localstorage.saveIsAuthenticated('true');
      localstorage.saveToken(action.payload);
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload,
      };
    case AuthReducers.REGISTER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };
    case AuthReducers.LOGIN_FAILED:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
      };
    case AuthReducers.LOGOUT_SUCCESS:
      localstorage.clearUser();
      return {
        ...state,
        token: null,
        isAuthenticated: false,
      };
    case AuthReducers.REGISTER_FAILED:
      localstorage.clearUser();
      return {
        ...state,
        token: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};