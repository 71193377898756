import { ExperienceReducers } from 'reducers/experienceReducer';
import { UtilsReducers } from 'reducers/utilsReducer';
import { updateExperience, saveExperience, getUserExperience, getExperience, deleteExperience } from 'utils/API';
import { IDispatch, ISignUpData, IExperience, IAxiosResponse, ISkill, INewSkill, ToastStatus } from 'utils/types';

type Filter = (obj: object) => object;
type CreateExperience = (signUpData: ISignUpData, history: any) => (dispatch: IDispatch, getState: () => any) => void;
type UpdateExperienceHandler = (experience: IExperience) => (dispatch: IDispatch, getState: () => any) => void;
type AddSkill = (selectedSkill: ISkill | INewSkill) => (dispatch: IDispatch) => void;
type DeleteSkill = (skillIndex: number) => (dispatch: IDispatch) => void;
type DeleteExperience = (experienceIndex: number) => (dispatch: IDispatch) => void;
type ReadUserExperience = ({id}) => void;
type ReadExperience = (experienceIndex: number) => (dispatch: IDispatch) => void;

const filter: Filter = (obj) => {
  const asArray = Object.entries(obj);
  const filtered = asArray.filter(([_, value]) => value && value !== '' && value !== null);
  return Object.fromEntries(filtered);
};

const createExperience: CreateExperience = (userExperienceData) => async (dispatch) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });
    const data = filter(userExperienceData) as ISignUpData;
    const signUpResponse = (await saveExperience(data)) as IAxiosResponse<IExperience>;
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: 'Experience data created successfully', status: ToastStatus.SUCCESS },
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: err.message, status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

const updateExperienceHandler: UpdateExperienceHandler = (experience) => async (dispatch) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });
    const { id, ...data } = filter(experience) as IExperience;
    const requestBody = {
      id,
      data,
    };
    const updateExperienceResponse = (await updateExperience(requestBody)) as IAxiosResponse<IExperience>;
    dispatch({
      type: ExperienceReducers.UPDATE_EXPERIENCE,
      payload: updateExperienceResponse.data,
    });
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: 'User data updated successfully', status: ToastStatus.SUCCESS },
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: err.message, status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

const readUserExperience: ReadUserExperience = ({id}) => async (dispatch) => {
  const experiences = await getUserExperience({id});
  dispatch({
    type: ExperienceReducers.READ_USER_EXPERIENCE,
    payload: experiences,
  })
};

const readExperience: ReadExperience = (experienceIndex) => async (dispatch) => {
  const experience = await getExperience(experienceIndex);
  dispatch({
    type: ExperienceReducers.READ_EXPERIENCE,
    payload: experience,
  })
};

const deleteExperienceHandler: DeleteExperience = (experienceIndex) => async (dispatch) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });
    const deleteExp = await deleteExperience(experienceIndex);
    dispatch({
      type: ExperienceReducers.DELETE_EXPERIENCE,
      payload: { message: 'Experience data deleted successfully', status: ToastStatus.SUCCESS },
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: err.message, status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

const addSkill: AddSkill = (selectedSkill) => async (dispatch) => {
  dispatch({
    type: ExperienceReducers.ADD_SKILL,
    payload: selectedSkill,
  });
};
const deleteSkill: DeleteSkill = (skillIndex) => async (dispatch) => {
  dispatch({
    type: ExperienceReducers.DELETE_SKILL,
    payload: skillIndex,
  });
};

export const ExperienceActions = {
  createExperience,
  updateExperience: updateExperienceHandler,
  addSkill,
  deleteSkill,
  readUserExperience,
  readExperience,
  deleteExperienceHandler
};