import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import deletePictureButton from 'assets/images/trash-alt.svg';
import editPictureButton from 'assets/images/edit.svg';
import { makeStyles } from '@material-ui/core/styles';
import { IRootState } from 'reducers/rootReducer';
import { EducationActions } from 'actions';
import { Input, Autocomplete } from 'components/atoms';
import {
	IEducation,
} from 'utils/types';
import { useForm } from 'react-hook-form';
import { useValidationResolver } from 'utils/useValidationResolver';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import Moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles({
	root: {
		minWidth: 275,
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});


interface Props {
	educations: Array<IEducation>;
	updateCallback: (userEducationData: IEducation) => void;
	validationSchema: any;
	educationDOM?: IEducation;
}

export const Cards: React.FC<Props> = ({ educations, updateCallback, validationSchema, educationDOM }: Props) => {
	const { isLoading } = useSelector((state: IRootState) => state.utils);
	const dispatch = useDispatch();
	const classes = useStyles();
	const [reloadEducation, setReloadEducation] = useState(false);
	const { id, isAuthenticated } = useSelector((state: IRootState) => state.auth)
	const [edit, setEdit] = useState(-1);
	const resolver = useValidationResolver(validationSchema);
	const { register, handleSubmit, setValue, reset, errors } = useForm({
		mode: 'onTouched',
		resolver,
		defaultValues: { educations },
	});
	useEffect(() => {
		dispatch(EducationActions.readUserEducation({id}));
		setReloadEducation(false);
	}, [reloadEducation]);
	const handleDeleteEducation = (educationId: number) => {
		if (window.confirm("Are you sure you want to delete this education?")) {
			dispatch(EducationActions.deleteEducationHandler(educationId));
			setReloadEducation(true);
		}
	};
	const handleEditEducation = (educationId: number, education) => {
		educations.map(oneEducation => {
			if (oneEducation.id == educationId) {
				if (oneEducation.finishYear == 'Present') {
					setChecked(true)
				} else {
					setChecked(false)
				}
			}
		})
		if (edit == -1) {
			educationDOM.id = educationId;
			educationDOM.title = education.title;
			educationDOM.institution = education.institution;
			educationDOM.level = education.level;
			educationDOM.country = education.country;
			educationDOM.startYear = education.startYear;
			educationDOM.finishYear = education.finishYear;
			educationDOM.isEdit = true;
			dispatch(EducationActions.readUserEducation({id}))
			setEdit(educationId);
		} else {
			setEdit(-1);
			dispatch(EducationActions.readUserEducation({id}))
			educationDOM.isEdit = false;
		}
	};

	const onSubmit = async (data: IEducation, e): Promise<void> => {
		const educationData = {
			id: educationDOM.id,
			...data
		};
		if ((startYear != '' && (startYear > educationDOM.finishYear)) || (finishYear != '' && (educationDOM.startYear > finishYear))) {
			alert('Your finishYear cannot be less than the startYear')
		} else {
			e.target.reset();
			setEdit(-1);
			dispatch(EducationActions.readUserEducation({id}))
			educationDOM.isEdit = false;
			updateCallback(educationData);
		}
	};
	useEffect(() => {
		if (id && educationDOM) {
			setValue('title', educationDOM.title);
			setValue('institution', educationDOM.institution);
			setValue('level', educationDOM.level);
			setValue('country', educationDOM.country);
			setValue('startYear', educationDOM.startYear);
			setValue('finishYear', educationDOM.finishYear);
		}
	}, [id, educationDOM, edit]);
	const yesterday = Moment().subtract(1, 'day');
	const valid = function (current) {
		return current <= yesterday;
	};
	const handleAddStart = (date) => { setStartYear(date.year()) }
	const [startYear, setStartYear] = useState('');
	const handleAddFinish = (date) => { setFinishYear(date.year()) }
	const [finishYear, setFinishYear] = useState('');
	const inputPropsStart = {
		placeholder: 'Start Year',
		name: 'startYear',
		autoComplete: 'off',
		readOnly: true
	}
	const inputPropsFinish = {
		placeholder: 'Finish Year',
		name: 'finishYear',
		autoComplete: 'off',
		readOnly: true
	}
	const [checked, setChecked] = useState(false);
	const handleCheckbox = () => {
		if (checked == true) {
			setChecked(false)
		} else {
			setChecked(true)
		}
	}
	return (
		<div>
			{educations.map((education, index) => (
				<div key={index} className="card-education">
					{education.id == edit ?
						<form id="education-form" onSubmit={handleSubmit(onSubmit)} className="" onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}}>
							<Card className={classes.root}>
								<CardContent>
									<span className="profile-picture-delete"
										onClick={() => handleDeleteEducation(education.id)}>
										<input type="button" />
										<img alt="delete" src={deletePictureButton} />
									</span>
									<span className="profile-picture-edit"
										onClick={() => handleEditEducation(education.id, education)}>
										<input type="button" />
										<img alt="edit" src={editPictureButton} />
									</span>
									<div className="title">
										<Input name="title" register={register} error={errors?.title} placeholder="Position *" />
									</div>
									<div className="institution">
										<Input name="institution" placeholder="Institution" register={register} error={errors?.institution} />
									</div>
									<hr />
									<div className="level">
										<Input name="level" placeholder="Level" register={register} error={errors?.level} />
									</div>
									<div className="row mt-3">
										<div className="startYear col-12 col-lg-6">
											<Datetime dateFormat="YYYY" isValidDate={valid} initialValue={education.startYear} timeFormat={false}
												inputProps={inputPropsStart} onChange={e => handleAddStart(e)} />
											<input type="hidden" value={startYear} ref={register} name="startYear" />
										</div>
										<div className="finishYear col-12 col-lg-6 ">
											{checked ?
												<Input name="finishYear" register={register} value="Present" readOnly /> :
												<>
													<Datetime dateFormat="YYYY" isValidDate={valid} initialValue={education.finishYear} timeFormat={false}
														inputProps={inputPropsFinish} onChange={e => handleAddFinish(e)} />
													<input type="hidden" value={finishYear} ref={register} name="finishYear" /></>
											}
										</div>
									</div>
								<div className="row mt-3 mb-3">
									<div className="checkbox col-12">
									<FormControlLabel
                    control={
                      <Checkbox
                      checked={checked}
                      onChange={handleCheckbox}
                      name="checkedB"
                      color="default"
                      />
                    }
                    label="Ongoing"
                    className="form-label form-checkbox"
                    />
									</div>
								</div>
								<div className="country">
										<Input name="country" placeholder="Country" register={register} error={errors?.country} />
									</div>
								</CardContent>
								<div className="save-submit row mt-4">
									<button className="btn button-orange" type="submit">
										{isLoading ? (
											<div className="spinner-border text-light" role="status">
												<span className="visually-hidden">Loading...</span>
											</div>
										) : (
											'Update'
										)}
									</button>
								</div>
                <div className="cancel-submit row mt-4">
									<button className="btn button-orange"
										onClick={() => handleEditEducation(education.id, education)} type="button">
										Cancel
									</button>
								</div>
							</Card>
						</form>
						:
						<Card className={classes.root}>
							<CardContent>
								<span className="profile-picture-delete"
									onClick={() => handleDeleteEducation(education.id)}>
									<input type="button" />
									<img alt="delete" src={deletePictureButton} />
								</span>
								<span className="profile-picture-edit"
									onClick={() => handleEditEducation(education.id, education)}>
									<input type="button" />
									<img alt="edit" src={editPictureButton} />
								</span>
								<label>
									{education.title}
								</label>
								<Typography className={classes.pos} color="textSecondary">
                  {education.institution} - {education.country}
								</Typography>
									{education.institution ? <hr /> : <div></div>}
								<Typography variant="body2" component="p">
                  {education.level} | {education.startYear ? education.startYear : ' ... '} - {education.finishYear ? education.finishYear : ' ... '}
								</Typography>
							</CardContent>
						</Card>
					}
				</div>
			))}
		</div>
	);
}