import { ReferenceReducers } from 'reducers/referenceReducer';
import { UtilsReducers } from 'reducers/utilsReducer';
import { saveReference, getUserReference, getReference } from 'utils/API';
import { IDispatch, ISignUpData, IReference, IAxiosResponse, ToastStatus } from 'utils/types';

type Filter = (obj: object) => object;
type CreateReference = (signUpData: ISignUpData, history: any) => (dispatch: IDispatch, getState: () => any) => void;
type ReadUserReference = (userIndex: number) => void;
type ReadReference = (referenceIndex: number) => (dispatch: IDispatch) => void;

const filter: Filter = (obj) => {
  const asArray = Object.entries(obj);
  const filtered = asArray.filter(([_, value]) => value && value !== '' && value !== null);
  return Object.fromEntries(filtered);
};

const createReference: CreateReference = (userReferenceData) => async (dispatch) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });
    const data = filter(userReferenceData) as ISignUpData;
    const signUpResponse = (await saveReference(data)) as IAxiosResponse<IReference>;
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: 'Reference data created successfully', status: ToastStatus.SUCCESS },
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: err.message, status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

const readUserReference: ReadUserReference = (userIndex) => async (dispatch) => {
  const reference = await getUserReference(userIndex);
  dispatch({
    type: ReferenceReducers.READ_USER_REFERENCE,
    payload: reference,
  })
};

const readReference: ReadReference = (referenceIndex) => async (dispatch) => {
  const reference = await getReference(referenceIndex);
  dispatch({
    type: ReferenceReducers.READ_REFERENCE,
    payload: reference,
  })
};

export const ReferenceActions = {
  createReference,
  readUserReference,
  readReference
};