import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions, AuthActions } from 'actions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './styles.scss';
import {
  IStatus,
  ISignUpData
} from 'utils/types';
import { IRootState } from 'reducers/rootReducer';
import { useValidationResolver } from 'utils/useValidationResolver';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useHistory } from "react-router-dom";
import { MAX_CHARACTERS } from 'utils/variables';
import Moment from 'moment';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

interface Props {
  id: number;
  idUserSelected: number;
  userDataSelected: any;
  submitCallback: (userStateData: IStatus) => void;
  allStatus: any;
  validationSchema: any;
}

export const AddStatusUser: React.FC<Props> = ({ id, idUserSelected, userDataSelected, submitCallback, allStatus, validationSchema }: Props) => {
  const dispatch = useDispatch();
  const [stateSelected, setstateSelected] = useState();
  const [stringDescrip, setStringDescrip] = useState("");
  const [fileArch, setFileArch] = useState();
  const [comment, setComment] = useState(false);
  const [validate, setValidate] = useState(true);
  const resolver = useValidationResolver(validationSchema);
  const { isLoading } = useSelector((state: IRootState) => state.utils);
  const history = useHistory();
  const [countCharacters, setCountCharacters] = useState(0);
  const [startDate, setStartDate] = useState('');
  const { register, handleSubmit, errors } = useForm({
    mode: 'onTouched',
    resolver,
    defaultValues: { allStatus },
  });

  const handleFileUpload = (e) => {
    /* Missing to add the model in the database */
  }

  const handleOnChangeDescription = (e, editor) => {
    const data = editor.getData();
    setStringDescrip(data);

    if (data.length < MAX_CHARACTERS) {
      setCountCharacters(data.length);

      if (stateSelected) {
        setValidate(false);
      }

    } else {
      setCountCharacters(data.length - 1);

      setValidate(true);
    }
  }

  const handleAddComment = () => {
    setComment(!comment)
  }

  useEffect(() => {
    if (id) dispatch(AuthActions.refreshToken(id));
  }, [id, dispatch]);

  const yesterday = Moment().subtract(1, 'day');

  const valid = function (current) {
    return current <= yesterday;
  };

  const handleAddStartDate = (event) => {
    setStartDate(event.format('DD-MM-YYYY'))
  }

  const inputPropsStartDate = {
    placeholder: 'Pick a date',
    name: 'startDate',
    autoComplete: 'off',
    readOnly: true
  }

  const onSubmit = async (data: IStatus, e): Promise<void> => {
    data.userId = Number(idUserSelected);
    data.editorId = id;

    const userStatusData = {
      ...data
    };

    e.target.reset();
    submitCallback(userStatusData);
  };

  const handleChangeState = (event) => {
    setstateSelected(event.target.value);
  }

  const handleEditAdmin = (user) => {
    if (window.confirm("Are you sure you want to change the role of this user?")) {
      const idUser = idUserSelected;
      const userData = user;

      if (userData.role === 'USER' || userData.role === 'EMPLOYEE') {
        userData.role = 'ADMIN'
      }
      else if ((userData.role === 'ADMIN') && (userData.status[0]?.title === 'Mate') && (userData.id !== 1)) {
        userData.role = 'EMPLOYEE'
      }
      else if ((userData.role === 'ADMIN') && (userData.status[0]?.title !== 'Mate') && (userData.id !== 1)) {
        userData.role = 'USER'
      }

      dispatch(UserActions.updatePersonalData(userData))
      history.push(`/user-detail/${idUser}`)
    }
  }

  const saveStatus = () => {
    const description = stringDescrip
    const dataStatus = {
      userId: idUserSelected,
      title: stateSelected,
      editorId: id,
      startDate: startDate,
      description: description,
    } as ISignUpData;

    if (description && stateSelected) {
      submitCallback(dataStatus)
      if (dataStatus.title === 'Mate' && userDataSelected.role !== 'ADMIN') {
        const userData = userDataSelected;
        userData.role = 'EMPLOYEE';
      }
      else if (dataStatus.title !== 'Mate') {
        const userData = userDataSelected;
        userData.role = 'USER';
      }
    }

    setComment(false);
  };

  return (
    <div className="container-comments row col-12">
      <div className="col-12 p-0">
        <div className="col-12 mb-3 userAdmin">
          <FormControlLabel
            control={
              (id === 1)
                ? <Checkbox
                  checked={userDataSelected.role === 'ADMIN'}
                  onClick={() => handleEditAdmin(userDataSelected)}
                  name="checkedB" color="default"
                  />

                : <Checkbox
                  checked={userDataSelected.role === 'ADMIN'}
                  onClick={() => handleEditAdmin(userDataSelected)}
                  name="checkedB" color="default" disabled
                  />
            }
            label="User ADMIN"
            className="form-label"
          />
        </div>

        <hr />

        <button className="btn btn-comment mb-3" onClick={handleAddComment}>Add comment</button>

        <label className="title col-12">COMMENTS</label>

        <div className={comment ? 'add-comment' : 'hidden-form'}>
          <form className="comment-user-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-body">
              <div className="text-form">
                <div className="statusChange col-12 ">
                  <label className="form-label">Current Status *</label>

                  <select name="title" id="title" value={stateSelected} onChange={e => handleChangeState(e)}
                    className={errors?.title ? 'form-control is-invalid' : 'form-control'} ref={register}
                  >
                    <option value="default" hidden>Select an option...</option>

                    <option value="Candidate" className="col-form-label">Candidate</option>

                    <option value="To Interview" className="col-form-label">To interview</option>

                    <option value="Interviewed, not ready" className="col-form-label">Interviewed, not ready</option>

                    <option value="Interviewed, possible candidate" className="col-form-label">Interviewed, possible candidate</option>

                    <option value="Accepted" className="col-form-label">Accepted</option>

                    <option value="Not a fit" className="col-form-label">Not a fit</option>

                    <option value="Proposal not accepted" className="col-form-label">Proposal not accepted</option>

                    <option value="Mate" className="col-form-label">Mate</option>

                    <option value="Ex Mate" className="col-form-label">Ex Mate</option>
                  </select>

                  {errors.title ? <label className="error">{errors.title}</label> : null}
                </div>

                {stateSelected === 'Mate' &&
                  <div className="date-status-mate col-12 mx-auto mt-2">
                    <label htmlFor="description" className="form-label">
                      Start Date
                    </label>

                    <Datetime dateFormat="DD-MM-YYYY" isValidDate={valid} timeFormat={false} className="input-date-status"
                      inputProps={inputPropsStartDate} onChange={e => handleAddStartDate(e)} />
                  </div>
                }

                <div className='mt-3'>
                  <label htmlFor="description" className="form-label">
                    Description * <br />
                    Tell us why you made the user status change
                  </label>

                  <CKEditor
                    name="description"
                    ref={register}
                    className={errors?.description ? 'form-control is-invalid' : 'form-control'}
                    id="description"
                    editor={ClassicEditor}
                    onChange={handleOnChangeDescription}
                  />

                  <label className="label-introduction">{countCharacters}/{MAX_CHARACTERS}</label>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="formFile" className="form-label">File upload is optional</label>

                <input className="form-control" name='Files' multiple type="file" id="formFile" onChange={(e) => handleFileUpload(e.target.files)} />
              </div>

              {id
                ? (<div className="save-submit row">
                    <button className="btn button-orange m-3" onClick={() => setComment(false)}>Cancel</button>

                    <button className="btn button-orange" disabled={validate} type="submit" onClick={saveStatus} >
                      {isLoading
                        ? (<div className="spinner-border text-light" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>)
                        : ('Save')
                      }
                    </button>
                  </div>)
                : (<div className="row mt-4">
                    <button className="btn button-orange mx-auto" type="submit">
                      {isLoading
                        ? (<div className="spinner-border text-light" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>)
                        : ('SIGN UP')
                      }
                    </button>
                  </div>)
              }
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
