import './styles.scss';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers/rootReducer';
import Paginator from '@material-ui/lab/Pagination';

export const Pagination = ({limitNumber, name, skill, language, city, status}) => {
	var url = `list-users?`;
	const usersQuery = useSelector((state: IRootState) => state.users);
  const history = useHistory();
	
	if (name) {url = url.concat(`name=${name}&`)}
	if (skill) {url = url.concat(`skill=${skill}&`)}
	if (language) {url = url.concat(`language=${language}&`)}
	if (city) {url = url.concat(`city=${city}&`)}
	if (status) {url = url.concat(`status=${status}&`)}

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    history.push(`${url}limit=${limitNumber}&page=${value}`);
  }

  if (usersQuery.isSuccess && usersQuery.data.totalPages > 1) {
    return (
      <div className="">
        <Paginator className="" onChange={handleChangePage} color="primary" count={usersQuery.data.totalPages} variant="outlined" shape="rounded" />
      </div>
    )
  } else {
    return null;
  }
};