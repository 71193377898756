/*
 *   This file contains interfaces used as just for documenting the expected
 *   structure of the diferent types used in the project.
 */

import { AuthReducers } from 'reducers/authReducer';
import { UserReducers } from 'reducers/userReducer';
import { UsersReducers } from 'reducers/usersReducer';
import { UtilsReducers } from 'reducers/utilsReducer';
import { ExperienceReducers } from 'reducers/experienceReducer';
import { EducationReducers } from 'reducers/educationReducer';
import { LinkReducers } from 'reducers/linkReducer';
import { StatusReducers } from 'reducers/statusReducer';
import { ReferenceReducers } from 'reducers/referenceReducer';
import { ForgotPasswordReducers } from 'reducers/forgotPasswordReducer';
import { NewPasswordReducers } from 'reducers/newPasswordReducer';

export interface IAbstractUser {
	city: string;
	country: string;
	email: string;
	firstName: string;
	introduction?: string;
	lastName: string;
	phone?: string;
	skills: ISkill[] | INewSkill[];
	languages: ILanguage[] | INewLanguage[];
	seniorityId?: number;
}

export interface IUsers {
	data: Array<IUser>;
	totalUsers?: number;
	totalPages?: number;
}

export interface IAction {
	type: AuthReducers | UserReducers | UsersReducers | UtilsReducers | ExperienceReducers | EducationReducers | LinkReducers | StatusReducers | ForgotPasswordReducers | NewPasswordReducers | ReferenceReducers;
	payload?: any;
}

export interface IAuthState {
	id: number;
	isAuthenticated: boolean;
	token: string;
}

export interface IAxiosResponse<T> {
	data: T;
	totalUsers?: number;
	totalPages?: number;
	errors?: { message: string }[];
}

export type IDispatch = (action: IAction) => void;

export interface IFile {
	data: string;
	extension: string;
}

export interface ILoginForm {
	email: string;
	password: string;
}

export interface INewSkill {
	title: string;
}

export interface INewLanguage {
	title: string;
	level: string;
}

export interface IPersonalData extends IAbstractUser {
	id: number;
	picture?: IFile;
}

export interface IPersonalDataForm extends IAbstractUser {
	curriculum?: File;
	picture?: IFile;
}

export interface ISignUpData extends IAbstractUser, IExperience, IEducation, ILink, IStatus, IReference {
	password: string;
	curriculum?: IFile;
	picture?: IFile;
}

export interface ISignUpDataForm extends IAbstractUser {
	password: string;
	curriculum?: IFile[];
	picture?: IFile;
}

export interface ISkill {
	id: number;
	title: string;
}

export interface ILanguage {
	id: number;
	title: string;
	level: string;
}

export enum ToastStatus {
	ERROR = 'bg-danger',
	INFO = 'bg-primary',
	SUCCESS = 'bg-success',
}

export interface IUser extends IAbstractUser {
	id: number;
	curriculumUrl?: IFile | string;
	pictureUrl?: IFile | string | null;
	picture?: IFile;
  role?: string;
	skills: ISkill[];
	languages: ILanguage[];
  reference: IReference[];
  status: IStatus[];
  curriculums?: [];
}

export interface IUserToken {
	user: IUser;
	token: string;
}

export interface IPassword {
	email?: string;
}

export interface INewPassword {
	password: string;
  resetToken: string;
  email: string;
}

export interface IUtilsState {
	isLoading: boolean;
	showToast: boolean;
	toastStatus: ToastStatus;
	toastMessage: string;
}

export interface IExperience extends IAbstractExperience {
	id: number;
	userId: number;
	data: [];
	totalExperiences?: number;
	isEdit: boolean;
}

export interface IEducation extends IAbstractEducation {
	id: number;
	userId: number;
	data: [];
	totalEducations?: number;
	isEdit: boolean;
}

export interface IStatus extends IAbstractStatus {
	id: number;
	userId: number;
	data: [];
	isEdit: boolean;
}

export interface IReference {
	id: number;
	userId: number;
	referenceId: number;
	description?: string;
	type: boolean;
	data: [];
}

export interface ILink {
	id: number;
	title: string;
	url: string;
	userId: number;
	isEdit: boolean;
	data: [];
	totalLinks?: number;
}

export interface IAbstractExperience {
	title: string;
	company: string;
	description?: string;
	startYear: string;
	finishYear: string;
	skills: ISkill[] | INewSkill[];
}

export interface IAbstractEducation {
	title: string;
	institution: string;
	level: any;
	country: string;
	startYear: string;
	finishYear: string;
}

export interface IAbstractStatus {
	title: string;
	editorId: number;
	startDate: string;
	description?: string;
}

export type Query<T> = {
	// The query can return several different results:

	/* When in loading state, there's no error, nor success 
	and for that, no data returned */
	isLoading: true
	isError: false
	isSuccess: false
	data?: undefined
} | {
	/* If the query returns an error is no longer on loading
	state, there's no success and no data will be returned */
	isLoading: false
	isError: true
	isSuccess: false
	data?: undefined
} | {
	/* The query returns success. It's no longer on loading 
	state, no error and it brings data with it */
	isLoading: false
	isError: false
	isSuccess: true
	data?: T
} | {
	/* There can be a few occassions where the query is not 
	loading, there is no error, not success and therefore, no data */
	isLoading: false
	isError: false
	isSuccess: false
	data?: undefined
}