import { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'react-router-dom';
import './styles.scss';
import DefaultPicture from 'assets/images/default_picture.jpg';
import FlagSpanishPicture from 'assets/images/flags/flag-spanish.png';
import FlagEnglishPicture from 'assets/images/flags/flag-english.png';
import FlagFrenchPicture from 'assets/images/flags/flag-french.png';
import FlagGermanPicture from 'assets/images/flags/flag-german.png';
import FlagPortuguesePicture from 'assets/images/flags/flag-portuguese.png';
import FlagItalianPicture from 'assets/images/flags/flag-italian.png';
import PdfPictureButton from 'assets/images/file-pdf.svg';
import Badge from '@material-ui/core/Badge';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

interface Props {
  id: number;
  users: any;
  role: string;
  params: any;
}

export const ListUsersData: React.FC<Props> = ({ users, role }) => {
  const BASE_API = process.env.REACT_APP_BASE_API_URL;
  const [curriculum64, setCurriculum64] = useState();
  
  
  const getCurriculum = (userId) => {
		fetch(`${BASE_API}/users/curriculum/${userId}`)
    .then(response => {
      return response.json();
    })
    .then(result => {
      setCurriculum64(result.data)
    })
    .catch(error => {
      console.log(error)
    })
	};

  const base64toBlob = (curriculum64: any) =>{
		const sliceSize = 1024;
		const byteCharacters = atob(curriculum64);
		const bytesLength = byteCharacters.length;
		const slicesCount = Math.ceil(bytesLength / sliceSize);
		const byteArrays = new Array(slicesCount);
	
		for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
			const begin = sliceIndex * sliceSize;
			const end = Math.min(begin + sliceSize, bytesLength);
			const bytes = new Array(end - begin);
			for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
				bytes[i] = byteCharacters[offset].charCodeAt(0);
			}
			byteArrays[sliceIndex] = new Uint8Array(bytes);
		}
		return new Blob(byteArrays, { type: "application/pdf" });
	}

	const openBase64NewTab = (userId: number): void => {
    getCurriculum(userId);
	}

  useEffect(() => {
    if (curriculum64) {
      var blob = base64toBlob(curriculum64);
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(blob, "pdfBase64.pdf");
      } else {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
      }
    }
  }, [curriculum64])
  
  return (
    <div className="row p-0 m-0 mt-3 containerGeneral">
       {users?.map((user, index) => (
          <div key={index} className="p-0 containerCards">
            <Card className="containerCard">
              <CardContent className="oneCard">
                <div className="col-md-12 col-sm-12 col-xs-12 row p-0 m-0 mt-2 justify-content-center align-items-center">
                  <div className="col-md-4 col-sm-4 col-xs-4 p-0 leftCard">
                    <Link to={`user-detail/${user.id}`}> 
                      <img alt="delete" src={user?.pictureUrl || DefaultPicture} className="pictureUser"/>
                    </Link>
                  </div>
                  <div className="col-md-7 col-sm-7 col-xs-7 p-0 centerCard">
                    <div>
                      <Link to={`user-detail/${user.id}`}> 
                        <label className="name">{user.firstName} {user.lastName}</label>
                      </Link>
                    </div>
                    {user?.seniorities?.title &&
                      <div>
                        <label className="seniority">{user.seniorities.title}</label>
                      </div>
                    }
                  </div>
                  <div className="col-md-1 col-sm-1 col-xs-1 mt-0 rightCard">
                    <a onClick={(): void => openBase64NewTab(user.id)} target="_blank" className="profile-picture-pdf">
                      <img  alt="delete" src={PdfPictureButton} />
                    </a>
                    {role === 'ADMIN' &&
                      <>
                        <br />
                        <br />
                        <Badge badgeContent={user.countReferenced} color="primary" className="content-up">
                          <ThumbUpIcon className="badgeUp"/>
                        </Badge>
                        <Badge badgeContent={user.countUnreferenced} color="primary" className="content-down">
                          <ThumbDownIcon className="badgeDown"/>
                        </Badge>
                      </>
                    }
                  </div>
                </div>
                <div className="col-12 mt-2 text-white text-center d-none">
                  {user?.skills.map((skill, index) => (
                    <span key={index}>
                      <span className={'col-auto mt-2 mx-2 btn badge rounded-pill skill-badge'}>
                        {skill.title}
                      </span>
                    </span>
                  ))}
                  <div className="mt-2 mb-2">
                    {user?.languages.map((language, index) => (
                      <span key={index}>
                        <span>
                          {language.title === 'Spanish' &&
                            <img alt="flag" src={FlagSpanishPicture} className="pictureFlag"/>
                          }
                          {language.title === 'English' &&
                            <img alt="flag" src={FlagEnglishPicture} className="pictureFlag"/>
                          }
                          {language.title === 'French' &&
                            <img alt="flag" src={FlagFrenchPicture} className="pictureFlag"/>
                          }
                          {language.title === 'Italian' &&
                            <img alt="flag" src={FlagItalianPicture} className="pictureFlag"/>
                          }
                          {language.title === 'Portuguese' &&
                            <img alt="flag" src={FlagPortuguesePicture} className="pictureFlag"/>
                          }
                          {language.title === 'German' &&
                            <img alt="flag" src={FlagGermanPicture} className="pictureFlag"/>
                          }
                          <span className="languageLevel">{language.shortlevel}</span>
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
              </CardContent>
              <Link to={`user-detail/${user.id}`}> 
                {(user?.status.length === 0) ?
                  <div className="statusUser mx-auto new-user">
                    <label className="statusName">NEW USER</label>
                  </div>
                : null}
                {user?.status[0]?.title === 'Candidate' &&
                  <div className="statusUser mx-auto candidate">
                    <label className="statusName">{user?.status[0]?.title}</label>
                  </div>
                }
                {user?.status[0]?.title === 'To Interview' &&
                  <div className="statusUser mx-auto to-interview">
                    <label className="statusName">{user?.status[0]?.title}</label>
                  </div>
                }
                {user?.status[0]?.title === 'Interviewed, not ready' &&
                  <div className="statusUser mx-auto to-interview">
                    <label className="statusName">{user?.status[0]?.title}</label>
                  </div>
                }
                {user?.status[0]?.title === 'Interviewed, possible candidate' &&
                  <div className="statusUser mx-auto possible-candidate">
                    <label className="statusName">{user?.status[0]?.title}</label>
                  </div>
                }
                {user?.status[0]?.title === 'Accepted' &&
                  <div className="statusUser mx-auto accepted">
                    <label className="statusName">{user?.status[0]?.title}</label>
                  </div>
                }
                {user?.status[0]?.title === 'Not a fit' &&
                  <div className="statusUser mx-auto rejected">
                    <label className="statusName">{user?.status[0]?.title}</label>
                  </div>
                }
                {user?.status[0]?.title === 'Proposal not accepted' &&
                  <div className="statusUser mx-auto dont-accept">
                    <label className="statusName">{user?.status[0]?.title}</label>
                  </div>
                }
                {(user?.status[0]?.title == 'Mate' && user?.status[0]?.title !== 'Ex Mate') &&
                  <div className="statusUser row justify-content-center align-items-center p-0 mx-auto mate">
                    <div className="col-md-2 statusLeft">
                      <label className="statusName">{user?.status[0]?.title}</label>
                    </div>
                  </div>
                }
                {user?.status[0]?.title === 'Ex Mate' &&
                  <div className="statusUser mx-auto ex-mate">
                    <label className="statusName">{user?.status[0]?.title}</label>
                  </div>
                }
              </Link>
            </Card>
          </div>
        ))}
    </div>
  );
};