import { combineReducers } from 'redux';
import { user } from './userReducer';
import { users } from './usersReducer';
import { auth } from './authReducer';
import { utils } from './utilsReducer';
import { experience } from './experienceReducer';
import { education } from './educationReducer';
import { link } from './linkReducer';
import { status } from './statusReducer';
import { reference } from './referenceReducer';
import { password } from './forgotPasswordReducer';
import { newpassword } from './newPasswordReducer';

export const rootReducer = combineReducers({
  auth,
  utils,
  user,
  users, 
  experience,
  education,
  link, 
  status,
  password,
  newpassword,
  reference
});

export type IRootState = ReturnType<typeof rootReducer>;