import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { IRootState } from 'reducers/rootReducer';
import { Header } from 'components/molecules';
import { Login, SignUp, PersonalData, Education, Experience, Language, Links, ListUsers, UserDetail, Curriculum, ForgotPassword, NewPassword } from 'screens';
import { ToastContainer } from 'components/molecules/ToastContainer';
import { TopNavbar } from 'components/molecules/TopNavbar';
import { IUser } from 'utils/types';

const Router = () => {
  const { isAuthenticated } = useSelector((state: IRootState) => state.auth);
  const user: IUser = useSelector((state: IRootState) => state.user);
  return (
    <BrowserRouter>
      <ToastContainer />
      <Header/>
      {isAuthenticated ? <TopNavbar/> : null}
      <Switch>
        <Route path="/login">{isAuthenticated ? <Redirect to="/personal-data" /> : <Login />}</Route>
        <Route path="/signup">{isAuthenticated ? <Redirect to="/personal-data" /> : <SignUp />}</Route>
        <Route path="/forgot-password">{<ForgotPassword />}</Route>
        <Route path="/new-password">{<NewPassword />}</Route>
        <Route path="/personal-data">{isAuthenticated ? <PersonalData /> : <Redirect to="/login" />}</Route>
        <Route path="/education">{isAuthenticated ? <Education /> : <Redirect to="/login" />}</Route>
        <Route path="/experience">{isAuthenticated ? <Experience /> : <Redirect to="/login" />}</Route>
        <Route path="/languages">{isAuthenticated ? <Language /> : <Redirect to="/login" />}</Route>
        <Route path="/links">{isAuthenticated ? <Links /> : <Redirect to="/login" />}</Route>
        <Route path="/curriculum">{isAuthenticated ? <Curriculum /> : <Redirect to="/login" />}</Route>
        <Route path="/list-users">{(isAuthenticated && user.role != "USER" )? <ListUsers role={user.role}/> : <Redirect to="/login" />}</Route>
        <Route path="/user-detail/:idUser">{(isAuthenticated && user.role != "USER") ? <UserDetail /> :<Redirect to="/login" />}</Route>
        <Route path="/">
          <Redirect to="/personal-data" />
        </Route>
      </Switch> 
    </BrowserRouter>
  );
};

export default Router;