import React, {useEffect, useState} from 'react';
import { IExperience, ISignUpData } from 'utils/types';
import { ExperienceActions } from 'actions';
import { UserExperienceData, Data } from 'components/organisms/UserExperienceData';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { IRootState } from 'reducers/rootReducer';
import { validationSchema } from './validations';
import { useHistory } from 'react-router-dom';

interface Props {}

export const Experience: React.FC<Props> = ({}: Props) => {
  const dispatch = useDispatch();
	const history = useHistory();
	const [reloadExperience, setReloadExperience] = useState(false);
	const { id, isAuthenticated } = useSelector((state: IRootState) => state.auth);
	const experience: IExperience = useSelector((state: IRootState) => state.experience);
	const experienceData = Object.values(experience.data);
	const totalExperiences = experience?.totalExperiences;

	useEffect(() => {
		dispatch(ExperienceActions.readUserExperience({id}));
		setReloadExperience(false);
    experience.isEdit = false;
    experience.skills = [];
	}, [reloadExperience]);

	const submitCallback = (userExperienceData: ISignUpData) => {
		dispatch(ExperienceActions.createExperience(userExperienceData, history));
		dispatch(ExperienceActions.readUserExperience({id}));
		setReloadExperience(true);
	};
	const updateCallback = (experienceUpdateData: ISignUpData) => {
		dispatch(ExperienceActions.updateExperience(experienceUpdateData));
		dispatch(ExperienceActions.readUserExperience({id}))
		setReloadExperience(true);
	};    

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <div className="row mt-3 m-0">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
        <UserExperienceData userId={id} submitCallback={submitCallback} experience={experience} validationSchema={validationSchema}  />
      </div>
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
        <Data id={id} experiences={experienceData} totalExperiences={totalExperiences} updateCallback={updateCallback} experience={experience} validationSchema={validationSchema} />
      </div>
    </div>
  );
};