import { AuthReducers } from 'reducers/authReducer';
import { ForgotPasswordReducers } from 'reducers/forgotPasswordReducer';
import { NewPasswordReducers } from 'reducers/newPasswordReducer';
import { UtilsReducers } from 'reducers/utilsReducer';
import {  forgotPassword, login, newPassword } from 'utils/API';
import { IDispatch, IAxiosResponse, ToastStatus, IPassword, INewPassword, IUserToken } from 'utils/types';

type Filter = (obj: object) => object;
type ForgotPasswordHandler = (forgotPasswordData: IPassword) => (dispatch: IDispatch, getState: () => any) => void;
type NewPasswordHandler = (newPasswordData: INewPassword) => (dispatch: IDispatch, getState: () => any) => void;

const filter: Filter = (obj) => {
  const asArray = Object.entries(obj);
  const filtered = asArray.filter(([_, value]) => value && value !== '' && value !== null);
  return Object.fromEntries(filtered);
};

const forgotPasswordHandler: ForgotPasswordHandler = (forgotPasswordData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });

    const { ...data } = filter(forgotPasswordData) as IPassword;
    const requestBody = {
      data,
    };
    const forgotPasswordResponse = (await forgotPassword(requestBody)) as IAxiosResponse<IPassword>;   
    dispatch({
      type: ForgotPasswordReducers.FORGOT_PASSWORD_SUCCESS,
      payload: forgotPasswordResponse.data,
    });
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: 'Check your email to update password ', status: ToastStatus.SUCCESS },
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: err.message, status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

const newPasswordHandler: NewPasswordHandler = (newPasswordData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });

    const { ...data } = filter(newPasswordData) as INewPassword;
    const requestBody = {
      data,
    };
    const newPasswordResponse = (await newPassword(requestBody)) as IAxiosResponse<INewPassword>;
    const log = {email: data.email, password: data.password};
    if (newPasswordResponse.data) {
      const loginResponse = (await login(log)) as IAxiosResponse<IUserToken>
      const loginUser = {
        user: {
          ...loginResponse.data.user,
        },
        token: loginResponse.data.token,
      };
      dispatch({
        type: AuthReducers.LOGIN_SUCCESS,
        payload: loginUser,
      });
    };
    dispatch({
      type: NewPasswordReducers.NEW_PASSWORD_SUCCESS,
      payload: newPasswordResponse.data,
    });
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: 'Check your password to update', status: ToastStatus.SUCCESS },
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: err.message, status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

export const ForgotPasswordActions = {
  forgotPassword: forgotPasswordHandler,
  newPassword: newPasswordHandler,
};