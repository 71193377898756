import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions, StatusActions } from 'actions';
import deletePictureButton from 'assets/images/trash-alt.svg';
import parse from 'html-react-parser';
import './styles.scss';
import {
  IStatus
} from 'utils/types';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { IRootState } from 'reducers/rootReducer';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

interface Props {
  id: number;
  idUserSelected: number;
  allStatus: any;
  updateCallback: (userStateData: IStatus) => void;
  userDataSelected?: any;
}

export const Cards: React.FC<Props> = ({ id, idUserSelected, allStatus, userDataSelected, updateCallback }: Props) => {
  const dispatch = useDispatch();
  const [reloadStatus, setReloadStatus] = useState(false);
  const [shortWord, setShortWord] = useState("");
  const [seeBtn, setSeeBtn] = useState(false);
  const classes = useStyles();
  const status: IStatus = useSelector((state: IRootState) => state.status);
  const statusData = Object.values(status.data);

  const handleMore = (dataCard) => {
    setShortWord(dataCard.description);
    setSeeBtn(true);
  }

  const handleBtn = () => {
    setShortWord('');
    setSeeBtn(false);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

  }, [shortWord]);

  useEffect(() => {
    if (id) dispatch(AuthActions.refreshToken(id));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(StatusActions.readUserStatus(idUserSelected));
    setReloadStatus(false);
  }, [dispatch, reloadStatus, idUserSelected]);

  const handleDeleteStatus = (statusId: number) => {
    if (window.confirm("Are you sure you want to delete this status?")) {
      dispatch(StatusActions.deleteStatusHandler(statusId));
      setReloadStatus(true);
    }
  };

  return (
    <div className="container-comments row col-12">
      {allStatus.map((oneStatus, index) => (
        <div key={index} className="card-user-status">
          <CardContent>
            <div className="col-md-12 row m-0 p-0">
              <div className="col-md-11 p-0 cardStatusLeft">
                <label>
                  {oneStatus.title.toUpperCase()} by {oneStatus.editor?.firstName.toUpperCase()} {oneStatus.editor?.lastName.toUpperCase()} at {oneStatus.createdAt.substr(0, 10)}
                </label>
              </div>

              <div className="col-md-1 p-0 cardStatusRight">
                <span className="status-picture-delete"
                  onClick={() => handleDeleteStatus(oneStatus.id)}>
                  <input type="button" />
                  <img alt="delete" src={deletePictureButton} />
                </span>
              </div>
            </div>

            <hr />

            <div>
              <Typography component={'div'} className={'m-0' + classes.pos}>
                {parse(oneStatus.description.substring(0, 1000))}
              </Typography>
            </div>

            {(oneStatus.description.length > 400)
              &&  <button className='btn-more' onClick={(e) => handleMore(oneStatus)}>More...</button>
            }
          </CardContent>
        </div>
      ))}

      <div className={seeBtn ? 'more-text' : 'deleteBox'}>
        <Typography component={'div'} className={'m-0' + classes.pos}>
          {parse(shortWord)}
        </Typography>

        <button className='btn-close' onClick={handleBtn} />
      </div>
    </div>
  );
}
