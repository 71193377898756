import { EducationReducers } from 'reducers/educationReducer';
import { UtilsReducers } from 'reducers/utilsReducer';
import { updateEducation, saveEducation, getUserEducation, getEducation, deleteEducation } from 'utils/API';
import { IDispatch, ISignUpData, IEducation, IAxiosResponse, ToastStatus } from 'utils/types';

type Filter = (obj: object) => object;
type CreateEducation = (signUpData: ISignUpData, history: any) => (dispatch: IDispatch, getState: () => any) => void;
type UpdateEducationHandler = (education: IEducation) => (dispatch: IDispatch, getState: () => any) => void;
type DeleteEducation = (educationIndex: number) => (dispatch: IDispatch) => void;
type ReadUserEducation = ({id}) => void;
type ReadEducation = (educationIndex: number) => (dispatch: IDispatch) => void;

const filter: Filter = (obj) => {
	const asArray = Object.entries(obj);
	const filtered = asArray.filter(([_, value]) => value && value !== '' && value !== null);
	return Object.fromEntries(filtered);
};

const createEducation: CreateEducation = (userEducationData) => async (dispatch) => {
	try {
		dispatch({
			type: UtilsReducers.SET_LOADING,
		});
		const data = filter(userEducationData) as ISignUpData;
		const signUpResponse = (await saveEducation(data)) as IAxiosResponse<IEducation>;
		dispatch({
			type: UtilsReducers.SHOW_TOAST,
			payload: { message: 'Education data created successfully', status: ToastStatus.SUCCESS },
		});
	} catch (err) {
		dispatch({
			type: UtilsReducers.SHOW_TOAST,
			payload: { message: err.message, status: ToastStatus.ERROR },
		});
	} finally {
		dispatch({
			type: UtilsReducers.STOP_LOADING,
		});
	}
};

const updateEducationHandler: UpdateEducationHandler = (education) => async (dispatch) => {
	try {
		dispatch({
			type: UtilsReducers.SET_LOADING,
		});
		const { id, ...data } = filter(education) as IEducation;
		const requestBody = {
			id,
			data,
		};
		const updateEducationResponse = (await updateEducation(requestBody)) as IAxiosResponse<IEducation>;
		dispatch({
			type: EducationReducers.UPDATE_EDUCATION,
			payload: updateEducationResponse.data,
		});
		dispatch({
			type: UtilsReducers.SHOW_TOAST,
			payload: { message: 'User data updated successfully', status: ToastStatus.SUCCESS },
		});
	} catch (err) {
		dispatch({
			type: UtilsReducers.SHOW_TOAST,
			payload: { message: err.message, status: ToastStatus.ERROR },
		});
	} finally {
		dispatch({
			type: UtilsReducers.STOP_LOADING,
		});
	}
};

const readUserEducation: ReadUserEducation = (objectParams) => async (dispatch) => {
	const educations = await getUserEducation(objectParams);
	dispatch({
		type: EducationReducers.READ_USER_EDUCATION,
		payload: educations,
	})
};

const readEducation: ReadEducation = (educationIndex) => async (dispatch) => {
	const education = await getEducation(educationIndex);
	dispatch({
		type: EducationReducers.READ_EDUCATION,
		payload: education,
	})
};

const deleteEducationHandler: DeleteEducation = (educationIndex) => async (dispatch) => {
	try {
		dispatch({
			type: UtilsReducers.SET_LOADING,
		});
		const deleteExp = await deleteEducation(educationIndex);
		dispatch({
			type: EducationReducers.DELETE_EDUCATION,
			payload: { message: 'Education data deleted successfully', status: ToastStatus.SUCCESS },
		});
	} catch (err) {
		dispatch({
			type: UtilsReducers.SHOW_TOAST,
			payload: { message: err.message, status: ToastStatus.ERROR },
		});
	} finally {
		dispatch({
			type: UtilsReducers.STOP_LOADING,
		});
	}
};

export const EducationActions = {
	createEducation,
	updateEducation: updateEducationHandler,
	readUserEducation,
	readEducation,
	deleteEducationHandler
};