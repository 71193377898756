import { StatusReducers } from 'reducers/statusReducer';
import { UtilsReducers } from 'reducers/utilsReducer';
import { updateStatus, saveStatus, getUserStatus, getStatus, deleteStatus } from 'utils/API';
import { IDispatch, ISignUpData, IStatus, IAxiosResponse, ToastStatus } from 'utils/types';

type Filter = (obj: object) => object;
type CreateStatus = (signUpData: ISignUpData, history: any) => (dispatch: IDispatch, getState: () => any) => void;
type UpdateStatusHandler = (status: IStatus) => (dispatch: IDispatch, getState: () => any) => void;
type DeleteStatus = (statusIndex: number) => (dispatch: IDispatch) => void;
type ReadUserStatus = (userIndex: number) => void;
type ReadStatus = (statusIndex: number) => (dispatch: IDispatch) => void;

const filter: Filter = (obj) => {
  const asArray = Object.entries(obj);
  const filtered = asArray.filter(([_, value]) => value && value !== '' && value !== null);
  return Object.fromEntries(filtered);
};

const createStatus: CreateStatus = (userStatusData) => async (dispatch) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });
    const data = filter(userStatusData) as ISignUpData;
    const signUpResponse = (await saveStatus(data)) as IAxiosResponse<IStatus>;
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: 'Status data created successfully', status: ToastStatus.SUCCESS },
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: err.message, status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

const updateStatusHandler: UpdateStatusHandler = (status) => async (dispatch) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });
    const { id, ...data } = filter(status) as IStatus;
    const requestBody = {
      id,
      data,
    };
    const updateStatusResponse = (await updateStatus(requestBody)) as IAxiosResponse<IStatus>;
    dispatch({
      type: StatusReducers.UPDATE_STATUS,
      payload: updateStatusResponse.data,
    });
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: 'Status data updated successfully', status: ToastStatus.SUCCESS },
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: err.message, status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

const readUserStatus: ReadUserStatus = (userIndex) => async (dispatch) => {
  const status = await getUserStatus(userIndex);
  dispatch({
    type: StatusReducers.READ_USER_STATUS,
    payload: status,
  })
};

const readStatus: ReadStatus = (statusIndex) => async (dispatch) => {
  const status = await getStatus(statusIndex);
  dispatch({
    type: StatusReducers.READ_STATUS,
    payload: status,
  })
};

const deleteStatusHandler: DeleteStatus = (statusIndex) => async (dispatch) => {
  try {
    dispatch({
      type: UtilsReducers.SET_LOADING,
    });
    const deleteStat = await deleteStatus(statusIndex);
    dispatch({
      type: StatusReducers.DELETE_STATUS,
      payload: { message: 'Status data deleted successfully', status: ToastStatus.SUCCESS },
    });
  } catch (err) {
    dispatch({
      type: UtilsReducers.SHOW_TOAST,
      payload: { message: err.message, status: ToastStatus.ERROR },
    });
  } finally {
    dispatch({
      type: UtilsReducers.STOP_LOADING,
    });
  }
};

export const StatusActions = {
  createStatus,
  updateStatus: updateStatusHandler,
  readUserStatus,
  readStatus,
  deleteStatusHandler
};