import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import deletePictureButton from 'assets/images/trash-alt.svg';
import editPictureButton from 'assets/images/edit.svg';
import { makeStyles } from '@material-ui/core/styles';
import { IRootState } from 'reducers/rootReducer';
import { LinkActions } from 'actions';
import { Input } from 'components/atoms';
import { ILink } from 'utils/types';
import { useForm } from 'react-hook-form';
import { useValidationResolver } from 'utils/useValidationResolver';
import Moment from 'moment';

const useStyles = makeStyles({
	root: {
		minWidth: 275,
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});
interface Props {
	links:  Array<ILink>;
	updateCallback: (userLinkData: ILink) => void;
	validationSchema: any;
	linkDOM?: ILink;
}

export const Cards: React.FC<Props> = ({ links, updateCallback, validationSchema, linkDOM }: Props) => {
	const { isLoading } = useSelector((state: IRootState) => state.utils);
	const dispatch = useDispatch();
	const classes = useStyles();
	const [reloadLink, setReloadLink] = useState(false);
	const { id, isAuthenticated } = useSelector((state: IRootState) => state.auth);
	const [edit, setEdit] = useState(-1);
	const resolver = useValidationResolver(validationSchema);
	const { register, handleSubmit, setValue, reset, errors } = useForm({
		mode: 'onTouched',
		resolver,
		defaultValues: { links },
	});
	useEffect(() => {
		dispatch(LinkActions.readUserLink({id}));
		setReloadLink(false);
	}, [reloadLink]);
	const handleDeleteLink = (linkId: number) => {
		if (window.confirm("Are you sure you want to delete this link?")) {
			dispatch(LinkActions.deleteLinkHandler(linkId));
			setReloadLink(true);
		}
	};
	const handleEditLink = (linkId: number, link) => {
		if (edit == -1) {
			linkDOM.id = linkId;
			linkDOM.title = link.title;
			linkDOM.url = link.url;
			linkDOM.isEdit = true;
			dispatch(LinkActions.readUserLink({id}))
			setEdit(linkId);
		} else {
			setEdit(-1);
			dispatch(LinkActions.readUserLink({id}))
			linkDOM.isEdit = false;
		}
	};
	const onSubmit = async (data: ILink, e): Promise<void> => {
		const linkData = {
			id: linkDOM.id,
			...data,
		};
		e.target.reset();
		setEdit(-1);
		dispatch(LinkActions.readUserLink({id}))
		linkDOM.isEdit = false;
		updateCallback(linkData);
	};
	useEffect(() => {
		if (id && linkDOM) {
			setValue('title', linkDOM.title);
			setValue('url', linkDOM.url);
		}
	}, [id, linkDOM, edit]);
	const yesterday = Moment().subtract(1, 'day');
	
	return (
		<div>
			{links.map((link, index) => (
				<div key={index} className="card-userlink">
					{link.id == edit ?
						<form id="link-form" onSubmit={handleSubmit(onSubmit)} className="" onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}}>
							<Card className={classes.root}>
								<CardContent>
									<span className="profile-picture-delete"
										onClick={() => handleDeleteLink(link.id)}>
										<input type="button" />
										<img alt="delete" src={deletePictureButton} />
									</span>
									<span className="profile-picture-edit"
										onClick={() => handleEditLink(link.id, link)}>
										<input type="button" />
										<img alt="edit" src={editPictureButton} />
									</span>
									<div className="title">
										<Input name="title" register={register} error={errors?.title} placeholder="Title *" />
									</div>
									<div className="url">
										<Input name="url" placeholder="Url" register={register} error={errors?.url} />
									</div>
								</CardContent>
								<div className="save-submit row mt-4">
									<button className="btn button-orange" type="submit">
										{isLoading ? (
											<div className="spinner-border text-light" role="status">
												<span className="visually-hidden">Loading...</span>
											</div>
										) : (
											'Update'
										)}
									</button>
								</div>
								<div className="cancel-submit row mt-4">
									<button className="btn button-orange"
										onClick={() => handleEditLink(link.id, link)} type="button">
										Cancel
									</button>
								</div>
							</Card>
						</form>
						:
						<Card className={classes.root}>
							<CardContent>
								<span className="profile-picture-delete"
									onClick={() => handleDeleteLink(link.id)}>
									<input type="button" />
									<img alt="delete" src={deletePictureButton} />
								</span>
								<span className="profile-picture-edit"
									onClick={() => handleEditLink(link.id, link)}>
									<input type="button" />
									<img alt="edit" src={editPictureButton} />
								</span>
								<label >
									{link.title}
								</label>
								<Typography className={classes.pos} color="textSecondary">
									{link.url}
								</Typography>
							</CardContent>
						</Card>
					}
				</div>
			))}
		</div>
	);
}