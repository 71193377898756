import { useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { AuthActions } from 'actions'
import { useValidationResolver } from 'utils/useValidationResolver'
import { Input } from 'components/atoms'
import './styles.scss'
import { IUser } from 'utils/types'
import { IRootState } from 'reducers/rootReducer'
import "react-image-crop/dist/ReactCrop.css"
import { AdminUpdatePersonalData } from 'utils/API'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

interface Props {
  id?: number
  user?: IUser
  validationSchema: any
  handleClose: any
}

export const EditUserData: React.FC<Props> = ({ id, user, validationSchema, handleClose }) => {
  const dispatch = useDispatch()
  const resolver = useValidationResolver(validationSchema)
  const { isLoading } = useSelector((state: IRootState) => state.utils)
  const [name, setName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)
  const [country, setCountry] = useState(user.country)
  const [city, setCity] = useState(user.city)
  const [email, setEmail] = useState(user.email)
  const [phone, setPhone] = useState(user.phone)
  const { register, handleSubmit, errors } = useForm({
    mode: 'onTouched',
    resolver,
    defaultValues: {
      ...user,
    },
  })

  useEffect(() => {
    if (id) dispatch(AuthActions.refreshToken(id))
  }, [])

  const onSubmit = async (user, id) => {
    const selectedUser = user
    const userId = id

    selectedUser.firstName = name
    selectedUser.lastName = lastName
    selectedUser.country = country
    selectedUser.city = city
    selectedUser.email = email
    selectedUser.phone = phone

    const queryData = {
      user: selectedUser,
      id: userId
    }

    dispatch(AuthActions.refreshToken(userId))
    await AdminUpdatePersonalData(queryData)
    handleClose()
  };

  const onError = (errors, e) => console.log(errors, e)

  return (
    <form className="editUser-data-form pb-5" onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="form-body row col-lg-10 col-md-10 col-sm-12 col-xs-12 mx-auto">
      <div className="col-lg-1 col-md-12 col-xs-12 p-0">
        <FontAwesomeIcon className="icon" icon={faChevronLeft} onClick={e => handleClose()} />
      </div>
        <div className="text-form col-md-7 col-sm-8 col-xs-12 mx-auto" id="formRight">
          <div className="row mt-3 ml-0 mr-0">
            <div className="user-first-name col-12 col-lg-6 mx-auto">
              <Input name="firstName" label="First Name *" register={register} error={errors?.firstName} 
              onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="last-name col-12 col-lg-6 mx-auto mt-3 mt-lg-0">
              <Input name="lastName" label="Last Name *" register={register} error={errors?.lastName} 
              onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}} onChange={(e) => setLastName(e.target.value)} />
            </div>
          </div>
          <div className="row mt-3 ml-0 mr-0">
            <div className="country col-12 col-lg-6 mx-auto">
              <Input name="country" label="Country" register={register} error={errors?.country} 
              onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}} onChange={(e) => setCountry(e.target.value)} />
            </div>
            <div className="city col-12 col-lg-6 mx-auto mt-3 mt-lg-0">
              <Input name="city" label="City" register={register} error={errors?.city} 
              onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}} onChange={(e) => setCity(e.target.value)} />
            </div>
          </div>
          <div className="row mt-3 ml-0 mr-0">
            <div className="email col-12 col-lg-6 mx-auto">
              <Input
                type="email"
                name="email" 
                label="Email *"
                register={register}
                error={errors?.email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="phone col-12 col-lg-6 mx-auto mt-3 mt-lg-0">
              <Input type="tel" step={null} maxLength={15} min={0} name="phone" autoComplete={"off"} label="Phone" register={register} error={errors?.phone} 
              onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}} onChange={(e) => setPhone(e.target.value)} />
            </div>
          </div>
          <div className="save-submit mx-auto mt-4">
            <button className="save btn button-orange" type="submit" onClick={() => onSubmit(user, id)} >
              {
                isLoading 
                ? ( <div className="spinner-border text-light" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div> ) 
                : ('Save')
              }
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}