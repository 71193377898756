import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions, AuthActions } from 'actions';
import editPictureButton from 'assets/images/edit.svg';
import DefaultPicture from 'assets/images/default_picture.jpg';
import { Link } from 'react-router-dom';
import { useValidationResolver } from 'utils/useValidationResolver';
import { IRootState } from 'reducers/rootReducer';
import {
  IPersonalData, 
  IAxiosResponse, 
  IUser,
} from 'utils/types';
import './styles.scss';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import { getUser } from 'utils/API';
import PdfButtonBlack from 'assets/images/pdf-file-black.svg';
import OpenButton from 'assets/images/open-icon.svg';
import { allSeniorities } from '../objects';
import { EditUserData } from '../EditUserData/EditUserData';

const useStyles = makeStyles({
	root: {
		minWidth: 275,
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

interface Props {
  id: number;
  idUserSelected: number;
  userDataSelected?: any;
  curriculum64: string;
  personalCurriculum64: string;
  validationSchema: any;
  user?: IUser;
}

export const UserDetailAdmin: React.FC<Props> = ({ id, idUserSelected, userDataSelected, curriculum64, personalCurriculum64, validationSchema, user }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  var Spinner = require('react-spinkit');
  const [seniority, setSeniority] = useState(0);
  const [senioritySelected, setSenioritySelected] = useState(userDataSelected.seniorityId);
  const [seniorityTitleSelected, setSeniorityTitleSelected] = useState(null);
  const { isLoading } = useSelector((state: IRootState) => state.utils);
  const resolver = useValidationResolver(validationSchema);
  const { register, handleSubmit, setValue, errors } = useForm({
    mode: 'onTouched',
    resolver,
    defaultValues: { 
      senioritySelected
    },
  });
  const selectedUser = userDataSelected;
   
  async function getUserss(idUserSelected: number) {
    const response = (await getUser(idUserSelected)) as IAxiosResponse<IUser[]>;
    if (response?.data) {
      (userDataSelected = response?.data);
    }
  }

  useEffect(() => {
    getUserss(idUserSelected);
  },[userDataSelected.seniorityId]);  
    
  useEffect(() => {
    const idUser = idUserSelected;
    if (id) dispatch(AuthActions.refreshToken(id));    
  }, []);

  useEffect(() => {
    dispatch(UserActions.readUser(idUserSelected))
  }, [userDataSelected.seniorityId]);

  const onSubmit = async (data: any) => {
    setIsEdit(false);
  };

  const onSubmitSeniority = (userDataSelected: IPersonalData) => {
    const userData = userDataSelected;
    userData.seniorityId = seniority;
    history.push(`/user-detail/${idUserSelected}`)
    dispatch(UserActions.updatePersonalData(userData));
    setIsEdit(false);
    allSeniorities.map(e => {
      if (e.value == Number(seniority)) setSeniorityTitleSelected(e.title)
    })
  };

  const handleAddSeniority = (e) => {
    setIsEdit(true);
  };

  const handleClick = (e) => {
    setSeniority(e.target.value);
  }

  const base64toBlob = (curriculum64: string) =>{
		const sliceSize = 1024;
		const byteCharacters = atob(curriculum64);
		const bytesLength = byteCharacters.length;
		const slicesCount = Math.ceil(bytesLength / sliceSize);
		const byteArrays = new Array(slicesCount);
	
		for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
			const begin = sliceIndex * sliceSize;
			const end = Math.min(begin + sliceSize, bytesLength);
	
			const bytes = new Array(end - begin);
			for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
				bytes[i] = byteCharacters[offset].charCodeAt(0);
			}
			byteArrays[sliceIndex] = new Uint8Array(bytes);
		}
		return new Blob(byteArrays, { type: "application/pdf" });
	}

	const openBase64NewTab = (curriculum64: string): void => {
		var blob = base64toBlob(curriculum64);
		if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
			(window.navigator as any).msSaveOrOpenBlob(blob, "pdfBase64.pdf");
		} else {
			const blobUrl = URL.createObjectURL(blob);
			window.open(blobUrl);
		}
	}

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="container-userDetail row col-12">
        { open 
          ? <EditUserData id={id} user={userDataSelected} validationSchema={validationSchema} handleClose={handleClose} />
          :
        <>
          <div className="col-lg-1 col-md-1 col-xs-12 p-0">
            <Link to="/list-users">
              <FontAwesomeIcon className="icon" icon={faChevronLeft} />
            </Link>
          </div>
          
          <div className="col-md-11 col-xs-12 p-0">
            {userDataSelected?.firstName ? 
            <div className="col-12 row m-0 p-0">
              <div className="col-md-12 row mt-3 mb-4 container-personal-data">
              <div className={open ? "hide-button" : "col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 editUser-icon"}>
                <FontAwesomeIcon className="icon" icon={faPencilAlt} onClick={() => {setOpen(true)}} />                    
              </div>
                <div className="col-md-4 col-sm-3 col-xs-12 p-0 m-0 containerPicture ml-2 mr-2">
                  <img alt="delete" src={userDataSelected?.pictureUrl || DefaultPicture} className="pictureUser"/>
                </div>
                <div className="col-md-8 col-sm-8 col-xs-12 p-0 m-0 containerInfo">
                  <div className="col-md-12 col-sm-12 col-xs-12 row m-0 p-0">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 p-0">
                      <label className="name2 col-12" >{userDataSelected.firstName} {userDataSelected.lastName}</label>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 p-0 container-pdf-icon">
                      <a onClick={(): void => openBase64NewTab(curriculum64)} target="_blank"  className="icon-pdf">
                        <img  alt="delete" src={PdfButtonBlack} />
                      </a>
                    </div>
                  </div>
                  {isEdit ?
                    <form id="senioritie-form" className="row mt-2 mb-2 senioritie-form" onSubmit={handleSubmit(onSubmit)} >
                      <div className="senioritieChange col-4">
                        <select name="seniorityId" id="seniorityId" value={seniority} onChange={e => handleClick(e)}
                          className='form-control' ref={register}
                        >
                          <option value="default"  hidden>Select an option...</option>
                          {allSeniorities.map((seniority, index) =>
                            <option key={index} value={seniority.value} >{seniority.title}</option>
                          )}
                        </select>
                      </div>
                      <div className="button-senioritie row col-6">
                        <button className="btn-save" type="submit" onClick={(e) => onSubmitSeniority(userDataSelected)}>
                          {isLoading ? (
                            <div className="spinner-border text-light" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          ) : (
                            'Save'
                          )}
                        </button>
                      </div>
                    </form>
                  :
                    <div className="col-12 mt-2 mb-3 container-senioritie">
                      {userDataSelected.seniorityId? (
                        <div className="row col-md-12 col-xs-12 m-0 senioritie">
                          <span className="senioritie-text col-lg-5 col-md-4 p-0 col-xs-7">
                            <Typography variant="h5" color="textSecondary" align="left">
                              {seniorityTitleSelected ? seniorityTitleSelected : userDataSelected.seniorities?.title}
                            </Typography>
                          </span>
                          <span className="senioritie-edit col-md-4 col-xs-1"
                            onClick={(e) => handleAddSeniority(e)}>
                            <img alt="edit" src={editPictureButton} />
                          </span>
                        </div>
                      ) : (
                        <span className="senioritie-add mt-3"
                          onClick={(e) => handleAddSeniority(e)}>
                          Add seniority
                        </span>
                      )}     
                    </div>
                  }
                  <label className="position col-12">{userDataSelected.country} - {userDataSelected.city}</label>
                  <label className="email col-12">{userDataSelected.email} {userDataSelected.phone?.length ? '- ' : null } {userDataSelected.phone?.length ? (userDataSelected.phone) : null }</label>
                  <label className="description col-12"> {userDataSelected.introduction} </label>
                </div>
              </div>
              {userDataSelected.skills?.length  ? (
                <div className="col-12 p-0">
                  <div className="featureContainer">
                    <label className="titles">SKILLS</label>
                    <div className="items-row row mt-1">
                      {userDataSelected.skills.map((skill, index) => (
                        <span key={index} className='col-auto mt-2 mx-2 btn badge rounded-pill skill-badge'>
                          {skill.title}
                        </span>)
                      )}
                    </div>
                  </div>
                </div>
              ) : null }
              {userDataSelected.experiences?.length || userDataSelected?.status[0]?.title === 'Mate'  ? (
                <div className="col-12 p-0">
                  <div className="featureContainer">
                    <label className="titles">EXPERIENCES&nbsp; 
                      <label className="statusMate">
                        {((userDataSelected?.status[0]?.title === 'Mate') && (userDataSelected?.status[0]['startDate'] !== null)) && '@42mate since: '+userDataSelected?.status[0]['startDate']}
                      </label>
                    </label>
                    <div className="items-row row">
                      { userDataSelected.experiences.map((experience, index) => (
                        <span key={index} className="mb-3">
                          <Typography className={classes.pos} color="textSecondary">
                          {experience.title}
                          </Typography>
                          <label className="col-12 p-0">
                            {experience.company} {experience.company ? <>|</> : null} {experience.startYear ? experience.startYear : ' ... '} - {experience.finishYear ? experience.finishYear : ' ... '}
                          </label>
                          <br/>
                          <label className="experienceDescription">
                            {experience.description}
                          </label>
                        </span>)
                      )}
                    </div>
                  </div>
                </div>
              ) : null }
              {userDataSelected.educations?.length ? (
                <div className="col-12 p-0">
                  <div className="featureContainer">
                    <label className="titles">EDUCATIONS</label>
                    <div className="items-row row">
                      { userDataSelected.educations.map((education, index) => (
                        <span key={index} className="mb-3">
                          <Typography className={classes.pos} color="textSecondary">
                            {education.title}
                          </Typography>
                          <label className="infoEducations">
                            {education.institution} - {education.country} - 
                          </label>
                          <label>
                            {education.level} | {education.startYear ? education.startYear : ' ... '} - {education.finishYear ? education.finishYear : ' ... '}
                          </label>
                        </span>)
                      )}
                    </div>
                  </div>
                </div>
              ) : null }
              {userDataSelected.languages?.length  ? (
                <div className="col-12 p-0">
                  <div className="featureContainer">
                    <label className="titles">LANGUAGES</label>
                    <div className="items-row row">
                      { userDataSelected.languages.map((language, index) => (
                        <span key={index} className="mb-3">
                          <Typography className={classes.pos} color="textSecondary">
                            {language.title}
                          </Typography>
                          <label >
                            {language.level.charAt(0) + language.level.slice(1).toLowerCase()}
                          </label>
                        </span>)
                        )}
                    </div>
                  </div>
                </div>
              ) : null }
              {(userDataSelected.links?.length || userDataSelected.curriculumUrl?.length)  ? (
                <div className="col-12 p-0">
                  <div className="featureContainer">
                    <label className="titles">LINKS</label>
                    <div className="items-row row">
                      { userDataSelected.links.map((link, index) => (
                        <span key={index} className="mb-3">
                          <Typography className={classes.pos} color="textSecondary">
                            {link.title}
                          </Typography>
                          <label >
                            <a href={link.url.includes('http') ? link.url : '//'+link.url} target="_blank">{link.url}</a>
                          </label>
                        </span>)
                      )}
                      {personalCurriculum64 ? 
                        <div className="items-row row">
                          <span className="m-0">
                            <hr />
                            <Typography className={classes.pos} color="textSecondary">
                              Personal Curriculum
                            </Typography>
                            <div>
                              <label className="col-12">
                                <a onClick={(): void => openBase64NewTab(personalCurriculum64)} target="_blank" className="row p-0">
                                  <div className="col-3 textOpen">
                                    Open Curriculum
                                  </div>
                                  <div className="col-1">
                                    <img className="iconOpen" src={OpenButton} alt="" />
                                  </div>
                                </a>
                              </label>
                            </div>
                          </span>
                        </div>
                      : null }
                    </div>
                  </div>
                </div>
              ) : null }
              {(userDataSelected.reference?.length) ? (
                <div className="col-12 p-0">
                  <div className="featureContainer">
                    <label className="titles">REFERENCES</label>
                    {userDataSelected.countReferenced > 0 ?
                      <div className="items-row row mt-2">
                        <Typography className={classes.pos} color="textSecondary">
                          Positive references:
                        </Typography>
                        {userDataSelected.reference.map((reference, index) => (
                          <span key={index} className="references">
                            <div className="col-12 row p-0 textOpen">
                              {reference.type == true ? 
                                <Link to={`${reference.referenceId}`} target="_blank"> 
                                  <span className="name">
                                    {reference.reference.firstName} {reference.reference.lastName}
                                  </span> 
                                  {reference.description ? 
                                    <span className="descriptionReference">|</span> 
                                  : null} 
                                  <span className="descriptionReference">
                                    {reference.description ? reference.description : null}
                                  </span>
                                </Link>
                              : null}
                            </div>
                          </span>)
                        )}
                      </div>
                    : null}
                    {userDataSelected.countUnreferenced > 0 ? 
                      <div className="items-row row mt-4">
                        <Typography className={classes.pos} color="textSecondary">
                          Negative references:
                        </Typography>
                        { userDataSelected.reference.map((reference, index) => (
                          <span key={index} className="references">
                            <div className="col-6 row p-0 textOpen">
                              {reference.type == false ? 
                                <Link to={`${reference.referenceId}`} target="_blank"> 
                                  <span className="name">
                                    {reference.reference.firstName}{reference.reference.lastName}
                                  </span> 
                                  {reference.description ? 
                                    <span className="descriptionReference">|</span> 
                                  : null} 
                                  <span className="descriptionReference">
                                    {reference.description ? reference.description : null}
                                  </span>
                                </Link>
                              : null}
                            </div>
                          </span>)
                        )}
                      </div>
                    : null}
                  </div>
                </div>
              ) : null }
            </div> :
            <div className="mt-5 container-spinner">
              <Spinner name="chasing-dots" className="spinnerUser"/>
            </div> }
          </div>
        </>
        }
      </div>
    </>
  );
};