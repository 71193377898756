import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Cards } from '.';
import { ILanguage, IAxiosResponse } from 'utils/types';
import { Input } from 'components/atoms';

interface Props {
	id: number;
	languages: Array<ILanguage>;
	updateCallback: any;
	language?: ILanguage;
	validationSchema: any;
}

export const Data: React.FC<Props> = ({ id, languages, updateCallback, validationSchema }: Props) => {
	
	const [search, setSearch] = useState('')
	const filteredLanguages = languages.filter(
		(language) => {
			return language.title.toUpperCase().includes(search.toUpperCase()) ||
				language.level.toUpperCase().includes(search.toUpperCase())
		}
	);


	if (languages.length) {
		return (
			<div className="card-language-form">
				<h5 className="text-no-language">MY LANGUAGES</h5>
				<div className="filter col-md-12 col-sm-12 col-xs-12">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <Input value={search} type="text" onChange={e => setSearch(e.target.value)}
              name="filter" placeholder="Search" register={null} />
          </div>
        </div>
				<Cards languages={filteredLanguages} updateCallback={updateCallback} validationSchema={validationSchema} />
			</div>
		);
	} else {
			return (
				<div className="card-language-form">
					<h5 className="text-no-language">MY LANGUAGES</h5>
					<h5 className="mt-4">You have not added any language yet..</h5>
				</div>
			)
	}
};