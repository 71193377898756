import { useEffect, useState } from 'react';
import { IUser, IPersonalData } from 'utils/types';
import { ActionGroupDispatcher, AuthActions, UserActions } from 'actions';
import { UserData } from 'components/organisms';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { IRootState } from 'reducers/rootReducer';
import { validationSchema } from './validations';

interface Props {}

export const PersonalData: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();
  const BASE_API = process.env.REACT_APP_BASE_API_URL;
  const { id, isAuthenticated } = useSelector((state: IRootState) => state.auth);
  const user: IUser = useSelector((state: IRootState) => state.user);
  const [personalCurriculum64, setPersonalCurriculum64] = useState();
  const submitCallback = (userData: IPersonalData) => {
    const actionGroupDispatcher = ActionGroupDispatcher([AuthActions.refreshToken(id), UserActions.updatePersonalData(userData)]);
    actionGroupDispatcher(dispatch);
  };
  
  const getPersonalCurriculum = () => {
		fetch(`${BASE_API}/users/curriculum/personal/${id}`)
    .then(response => {
      return response.json();
    })
    .then(result => {
      setPersonalCurriculum64(result.data)
    }).catch(error => {
      console.log(error)
    })
	};

  useEffect(() => {
    getPersonalCurriculum();
  })

  if (!isAuthenticated) return <Redirect to="/login" />;
  return <UserData id={id} submitCallback={submitCallback} user={user} userPicture={user.pictureUrl} validationSchema={validationSchema} personalCurriculum64={personalCurriculum64} />;
};