import React, { useEffect, useRef } from 'react';
import { Toast } from 'bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers/rootReducer';
import { UtilsReducers } from 'reducers/utilsReducer';
import './styles.scss';

export const ToastContainer = () => {
  const toastRef = useRef();
  const dispatch = useDispatch();
  const { showToast, toastStatus, toastMessage } = useSelector((state: IRootState) => state.utils);

  useEffect(() => {
    if (showToast) {
      let toast = Toast.getInstance(toastRef.current);
      if (!toast) toast = new Toast(toastRef.current);
      toast.show();
      dispatch({
        type: UtilsReducers.TOAST_DISPLAYED,
      });
    }
  }, [showToast]);

  return (
    <div
      className={'toast align-items-center text-white border-0 position-fixed top-0 end-0 m-3 ' + toastStatus}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      ref={toastRef}
      data-bs-delay="7000">
      <div className="d-flex p-2">
        <div className="toast-body">{toastMessage}</div>
        <button
          type="button"
          className="btn-close btn-close-white me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
    </div>
  );
};