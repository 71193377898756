import { useState } from "react"
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CancelIcon from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  register?: any
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 5, 3),
  },
}));

export const CurriculumField: React.FC<Props> = ({ register }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  
  const handleUploadCurriculum = (event) => {
    const file = event.target.files[0];
    if (file) {
      const MB = 1048576;
      if (file.size > 5 * MB) {
        setOpen(true)
        event.target.value = null;
        return null;
      } else {
        getBase64(file)
        .then (result => {
          file["base64"] = result;
          const curriculum = {
            data: file.base64.substr(26), 
            extension: file.type.substr(12, 15), 
          }
        })
        .catch( err => {
          console.log(err);
        })
      }
    }
  }

  const getBase64 = (file) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const baseURL = reader.result.toString();
        resolve(baseURL);
      }        
    })
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <div className="mt-3">
      <div className="curriculumFIle">
        <div className="col-md-12 p-0">
          <input
            name="curriculum"
            type="file"
            accept="application/pdf"
            id='curriculum'
            ref={register}
            onChange={handleUploadCurriculum}
            className="form-control py-2 form-control-curriculum"
            required
          />
        </div>
      </div>
    </div>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <CancelIcon className="close-icon" onClick={handleClose} />
          <br />
          <p id="transition-modal-description">The file size must be less than 5MB.</p>
        </div>
      </Fade>
    </Modal>
  </>
  )
}