import { Experience } from 'screens';
import { IExperience, IAction } from 'utils/types';

type ExperienceReducer = (state: IExperience, action: IAction) => IExperience;
export enum ExperienceReducers {
  ADD_SKILL = 'ADD_SKILL',
  CLEAR_EXPERIENCE = 'CLEAR_EXPERIENCE',
  CREATE_EXPERIENCE = 'CREATE_EXPERIENCE',
  DELETE_SKILL = 'DELETE_SKILL',
  UPDATE_EXPERIENCE = 'UPDATE_EXPERIENCE',
  READ_USER_EXPERIENCE = 'READ_USER_EXPERIENCE',
  READ_EXPERIENCE = 'READ_EXPERIENCE',
  DELETE_EXPERIENCE = 'DELETE_EXPERIENCE'
}

const initialState: IExperience = {
  id: 0,
  userId: 0,
  title: '',
  company: '',
  description: '',
  startYear: '',
  finishYear: '',
  skills: [],
  data: [],
  isEdit: false,
};

export const experience: ExperienceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ExperienceReducers.ADD_SKILL:
      if (!state.skills.some(({ title }) => title === action.payload.title)) {
        return {
          ...state,
          skills: [...state.skills, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case ExperienceReducers.CLEAR_EXPERIENCE:
      return initialState;
    case ExperienceReducers.DELETE_SKILL:
      return {
        ...state,
        skills: [...state.skills.slice(0, action.payload), ...state.skills.slice(action.payload + 1)],
      };
    case ExperienceReducers.UPDATE_EXPERIENCE:
      return {
        ...state,
        ...action.payload,
      };
    case ExperienceReducers.READ_USER_EXPERIENCE:
      return {
        ...state,
        ...action.payload,
      };
    case ExperienceReducers.DELETE_EXPERIENCE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state;
  }
};