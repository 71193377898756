import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import deletePictureButton from 'assets/images/trash-alt.svg';
import editPictureButton from 'assets/images/edit.svg';
import { makeStyles } from '@material-ui/core/styles';
import { IRootState } from 'reducers/rootReducer';
import { listSkills } from 'utils/API';
import { ExperienceActions } from 'actions';
import { Input, Autocomplete } from 'components/atoms';
import {
	ISkill,
	IAxiosResponse,
	INewSkill,
	IExperience,
  } from 'utils/types';
import { useForm } from 'react-hook-form';
import { useValidationResolver } from 'utils/useValidationResolver';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import Moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MAX_CHARACTERS } from 'utils/variables';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
	root: {
	  minWidth: 275,
	},
	bullet: {
	  display: 'inline-block',
	  margin: '0 2px',
	  transform: 'scale(0.8)',
	},
	title: {
	  fontSize: 14,
	},
	pos: {
	  marginBottom: 12,
	},
  });

interface Props {
	experiences: Array<IExperience>;
	updateCallback: (userExperienceData: IExperience) => void;
	validationSchema: any;
	experienceDOM?: IExperience;    
}

export const Cards: React.FC<Props> = ({experiences, updateCallback, validationSchema, experienceDOM}: Props) => {
	const { isLoading } = useSelector((state: IRootState) => state.utils);
	const dispatch = useDispatch();
	const classes = useStyles();
	const [reloadExperience, setReloadExperience] = useState(false);
	const { id, isAuthenticated } = useSelector((state: IRootState) => state.auth);
	const [edit, setEdit] = useState(-1);
	const resolver = useValidationResolver(validationSchema);
	const { register, handleSubmit,setValue, reset, errors } = useForm({
		mode: 'onTouched',
		resolver,
		defaultValues: {experiences},
	});
	useEffect(() => {
		dispatch(ExperienceActions.readUserExperience({id}));
		setReloadExperience(false);
	  },[reloadExperience]);
	const handleDeleteExperience = (experienceId: number) => {
		if( window.confirm("Are you sure you want to delete this experience?")) {
			dispatch(ExperienceActions.deleteExperienceHandler(experienceId));
			setReloadExperience(true);
		}
	};
	const handleEditExperience = (experienceId: number, experience) => {
		experiences.map(oneExperience => {
			if (oneExperience.id == experienceId) {
				if (oneExperience.finishYear == 'Present') {
					setChecked(true)
				} else {
					setChecked(false)
				}
			}
		})
		if(edit == -1) {
			experienceDOM.id = experienceId;
			experienceDOM.company = experience.company;
			experienceDOM.title = experience.title;
			experienceDOM.description = experience.description;
			experienceDOM.startYear = experience.startYear;
			experienceDOM.finishYear = experience.finishYear;
			experienceDOM.skills = experience.skills;
			experienceDOM.isEdit = true;
			dispatch(ExperienceActions.readUserExperience({id}))
			setEdit(experienceId);
		} else {
			setEdit(-1);
			dispatch(ExperienceActions.readUserExperience({id}))
			experienceDOM.isEdit = false;
			experienceDOM.skills = [];
		}
	};
	const querySkils = async (search: string, setSkillOptions) => {
		const response = (await listSkills(search)) as IAxiosResponse<ISkill[]>;
		if (response.data) setSkillOptions(response.data);
	};
	const handleAddSkill = (selectedSkill: ISkill | INewSkill) => {
		dispatch(ExperienceActions.addSkill(selectedSkill));
	};
	const handleDeleteSkill = (index: number) => {
		dispatch(ExperienceActions.deleteSkill(index));
	};
	const onSubmit = async (data: IExperience, e ): Promise<void> => {
		const experienceData = {
			id: experienceDOM.id,
			...data,
			skills: experienceDOM.skills,
		};
		if ((startYear != '' && (startYear > experienceDOM.finishYear)) || (finishYear != '' && (experienceDOM.startYear > finishYear))) {
			alert('Your finishYear cannot be less than the startYear')
		} else {
			e.target.reset();
			setEdit(-1);
			dispatch(ExperienceActions.readUserExperience({id}))
			experienceDOM.isEdit = false;
			updateCallback(experienceData);
			experienceDOM.skills = [];
		}
	}; 
	useEffect(() => {
		if (id && experienceDOM ) {
		  setValue('title', experienceDOM.title);
		  setValue('company', experienceDOM.company);
		  setValue('description', experienceDOM.description);
		  setValue('startYear', experienceDOM.startYear);
		  setValue('finishYear', experienceDOM.finishYear);
		  setValue('skills', experienceDOM.skills);
		}
	  }, [id, experienceDOM, edit]); 
	const yesterday = Moment().subtract( 1, 'day' );
	const valid = function( current ){
		return current <= yesterday;
	};
	const handleAddStart = (date) => { setStartYear(date.year()) }
	const [startYear, setStartYear] = useState('');
	const handleAddFinish = (date) => { setFinishYear(date.year()) }
	const [finishYear, setFinishYear] = useState('');
	const inputPropsStart = {
		placeholder: 'Start Year',
		name: 'startYear',
		autoComplete: 'off',
		readOnly: true
	}
	const inputPropsFinish = {
		placeholder: 'Finish Year',
		name: 'finishYear',
		autoComplete: 'off',
		readOnly: true
	}
	const [checked, setChecked] = useState(false);
	const handleCheckbox = () => {
		if (checked == true) {
			setChecked(false)
		} else {
			setChecked(true)
		}
	}
	return (
		<div>
			{experiences.map((experience, index) => (
				<div key={index} className="card-experience">
					{experience.id == edit ? 
						<form id="experience-form" onSubmit={handleSubmit(onSubmit)} className="" onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}}>
							<Card className={classes.root}>
								<CardContent>
                  <span className="profile-picture-delete" 
                  onClick={() => handleDeleteExperience(experience.id)}>
                    <input type="button"/>
                    <img alt="delete" src={deletePictureButton} />
                  </span>
                  <span className="profile-picture-edit"
                  onClick={() => handleEditExperience(experience.id, experience)}>
                    <input type="button"/>
                    <img alt="edit" src={editPictureButton} />
                  </span>
                  <div className="title">
                    <Input name="title" register={register} error={errors?.title} placeholder="Position *"/> 
                  </div>
                  <div className="company">
                    <Input name="company" placeholder="Company" register={register} error={errors?.company}  /> 
                  </div>
                  <div className="row mt-3">
                    <div className="startYear col-12 col-lg-6">
                      <Datetime className="notEdit" dateFormat="YYYY" isValidDate={valid} initialValue={experience.startYear} timeFormat={false} 
                      inputProps={inputPropsStart}  onChange={e => handleAddStart(e)}/>
                      <input type="hidden" value={startYear} ref={register} name="startYear"/>
                    </div>
                    <div className="finishYear col-12 col-lg-6 ">
                      {checked ? 
                      <Input name="finishYear" register={register} value="Present" readOnly/> : 
                      <>
                      <Datetime dateFormat="YYYY" isValidDate={valid} initialValue={experience.finishYear} timeFormat={false} 
                      inputProps={inputPropsFinish} onChange={e => handleAddFinish(e)}/>
                      <input type="hidden" value={finishYear} ref={register} name="finishYear"/></>
                      }
                    </div>
                  </div>
									<div className="row mt-3">
										<div className="checkbox col-12">
										<FormControlLabel
											control={
												<Checkbox
												checked={checked}
												onChange={handleCheckbox}
												name="checkedB"
												color="default"
												/>
											}
											label="Ongoing"
											className="form-label form-checkbox"
											/>
										</div>
									</div>
                  <hr/>
                    <textarea
                    name="description"
                    ref={register}
                    className="description form-control"
                    id="description-input"
                    placeholder="Description"
                    maxLength={MAX_CHARACTERS} />
                  <hr/> 
                  <div>
										<Autocomplete getOptions={querySkils} onSelect={handleAddSkill} />
                    <div className="skills-row row mt-2">
                      {experienceDOM.skills?.length
                      ? experienceDOM.skills.map((skill, index) => (
                        <span
                        key={skill.id ? 'skill' + skill.id : 'newSkill' + index}
                        className='col-auto mt-2 mx-2 btn badge rounded-pill skill-badge'
												onClick={() => handleDeleteSkill(index)}>         
                          {skill.title}
													<span>
														<Tooltip title="delete skill">
															<IconButton aria-label="delete skill">
																<ClearIcon />
															</IconButton>
														</Tooltip>
													</span>
                        </span>
                      ))
                      : null}
                    </div>
                  </div>                            
								</CardContent>
								<div className="save-submit row mt-4">
									<button className="btn button-orange" type="submit">
										{isLoading ? (
										<div className="spinner-border text-light" role="status">
											<span className="visually-hidden">Loading...</span>
										</div>
										) : (
										'Update'
										)}
									</button>
								</div>
								<div className="cancel-submit row mt-4">
									<button className="btn button-orange"
										onClick={() => handleEditExperience(experience.id, experience)} type="button">
										Cancel
									</button>
								</div>
							</Card>
						</form>
						:
						<Card className={classes.root}>
							<CardContent>
								<span className="profile-picture-delete" 
								onClick={() => handleDeleteExperience(experience.id)}>
									<input type="button"/>
									<img alt="delete" src={deletePictureButton} />
								</span>
								<span className="profile-picture-edit"
								onClick={() => handleEditExperience(experience.id, experience)}>
									<input type="button"/>
									<img alt="edit" src={editPictureButton} />
								</span>
								<label >
									{experience.title}
								</label>
								<Typography className={classes.pos} color="textSecondary">
									{experience.company} {experience.company ? <>|</> : null}  {experience.startYear ? experience.startYear : ' ... '} - {experience.finishYear ? experience.finishYear : ' ... '}
								</Typography>
								{experience.description ? <hr/> : <div></div> }
								<Typography variant="body2" component="p">
									{experience.description}
								</Typography>
								{experience.skills?.length ? <hr/> : <div></div>}

								<div className="skills-row row mt-2">
									{experience.skills?.length
									? experience.skills.map((skill, index) => (
										<span
										key={skill.id ? 'skill' + skill.id : 'newSkill' + index}
										className='col-auto mt-2 mx-2 btn badge rounded-pill skill-badge'>
											{skill.title}
										</span>
									))
									: null}
								</div>
							</CardContent>
						</Card>                   
					}
				</div>
			))}
		</div>
	);
}