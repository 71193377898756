import { UserDetail } from 'screens';
import { IReference, IAction } from 'utils/types';

type ReferenceReducer = (state: IReference, action: IAction) => IReference;
export enum ReferenceReducers {
  CLEAR_REFERENCE = 'CLEAR_REFERENCE',
  CREATE_REFERENCE = 'CREATE_REFERENCE',
  DELETE_REFERENCE = 'DELETE_REFERENCE',
  READ_USER_REFERENCE = 'READ_USER_REFERENCE',
  READ_REFERENCE = 'READ_REFERENCE',
}

const initialState: IReference = {
  id: 0,
  userId: 0,
  referenceId: 0,
  type: true,
  data: [],
};

export const reference: ReferenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReferenceReducers.CLEAR_REFERENCE:
      return initialState;
    case ReferenceReducers.READ_USER_REFERENCE:
      return {
        ...state,
        ...action.payload,
      };
    case ReferenceReducers.DELETE_REFERENCE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state;
  }
};