type GetItem = () => string;
type SetItem = (item: string) => void;
enum Keys {
  ID = 'id',
  ISAUTHENTICATED = 'isAuthenticated',
  USER = 'user',
  TOKEN = 'token',
}
interface LocalStorage {
  clearUser: () => void;
  getUserId: () => number;
  getToken: GetItem;
  isAuthenticated: () => boolean;
  saveToken: SetItem;
  saveUserId: SetItem;
  saveIsAuthenticated: SetItem;
}

export const localstorage: LocalStorage = {
  clearUser: () => {
    localstorage.saveToken('');
    localstorage.saveUserId('');
    localstorage.saveIsAuthenticated('false');
  },
  getUserId: () => parseInt(localStorage.getItem(Keys.ID)),
  getToken: () => localStorage.getItem(Keys.TOKEN),
  isAuthenticated: () => localStorage.getItem('isAuthenticated') === 'true',
  saveToken: (token) => localStorage.setItem(Keys.TOKEN, token),
  saveUserId: (id) => localStorage.setItem(Keys.ID, id),
  saveIsAuthenticated: (isAuthenticated) => localStorage.setItem(Keys.ISAUTHENTICATED, isAuthenticated),
};