import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { LinkActions, AuthActions } from 'actions';
import { useValidationResolver } from 'utils/useValidationResolver';
import { Input } from 'components/atoms';
import './styles.scss';
import {
  ILink,
  IUser
} from 'utils/types';
import { IRootState } from 'reducers/rootReducer';
import "react-datetime/css/react-datetime.css";
import { useHistory } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { user } from 'reducers/userReducer';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { truncate } from 'fs/promises';

interface Props {
  userId?: number;
  user: any;
  submitCallback: (userLinkData: ILink) => void;
  link?: ILink;
  validationSchema: any;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 5, 3),
  },
}));

export const UserLinkData: React.FC<Props> = ({ userId, user, submitCallback, link, validationSchema }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
  const [finish, setFinish] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const { isLoading } = useSelector((state: IRootState) => state.utils);
  const resolver = useValidationResolver(validationSchema);
  const { register, handleSubmit, errors } = useForm({
    mode: 'onTouched',
    resolver,
    defaultValues: {link},
  });
  useEffect(() => {
    if (userId) dispatch(AuthActions.refreshToken(userId));
    setFinish(false)
  }, []);

  useEffect(() => {
    if (user?.educations?.length && user?.experiences?.length && user?.languages?.length && user?.links?.length && user?.skills?.length) {
      setFinish(true)
    } else {
      setFinish(false)
    }
  }, [user]);

  const onSubmit = async (data: ILink, e ): Promise<void> => {
    data.userId = userId;
    const userLinkData = {
      ...data,
    };
    e.target.reset();
    submitCallback(userLinkData);
  };  

  const handleContinue = () => {
		const title = ((document.getElementById('title')) as HTMLInputElement).value
		const url = ((document.getElementById('url')) as HTMLInputElement).value
    setOpen(true)
    if ((title != '') || (url != '')) {
      setEditing(true)
    } else {
      setEditing(false)
      if (user.role == 'ADMIN' || user.role == 'EMPLOYEE') {
        history.push('/curriculum');
      } else {
        history.push('/personal-data');
      }
    }
  }

	const handleClose = () => {
    setOpen(false);
  };

	const handleStayModal = () => {
    setOpen(false)
  };

	const handleContinueModal = () => {
    if (user.role == 'ADMIN' || user.role == 'EMPLOYEE') {
      history.push('/curriculum');
    } else {
      history.push('/personal-data');
    }
  };

  return ( 
    <form id="link-form" onSubmit={handleSubmit(onSubmit)}  className="link-form pb-5"> 
      <div className="form-body">
        <div className=" text-form">
          <div className="row mt-4">
            <div className="title col-12 ">
              <Input name="title" id="title" label="Title *" register={register} error={errors?.title} 
              disabled={link.isEdit} />
            </div>
          </div>
          <div className="row mt-4">
            <div className="url col-12  ">
              <Input name="url" id="url" label="Url *" register={register} error={errors?.url} 
              disabled={link.isEdit} />
            </div>
          </div>          
        </div>
        <Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className={classes.modal}
					open={open}
					onClose={handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
          <Fade in={open}>
						<div className={classes.paper}>
             	{editing ? 
                <p id="transition-modal-description">There are unsaved changes. If you continue, all unsaved data will be lost.</p>
              : 
                <p id="transition-modal-description">¡You have completed the registration process!</p>
              }
							{(editing) ? 
                <div className="save-submit row">
                  <button type="button" className="continue m-1 btn button-orange" onClick={handleStayModal}>
                    Stay
                  </button>
                  <button type="button" className="continue m-1 btn button-orange" onClick={handleContinueModal}>
                    Continue
                  </button>
                </div>
              : null }
						</div>
					</Fade>
				</Modal>
        {userId ? (
          <div>
            <div className="save-submit row mt-4">
              <button className="save btn button-orange" type="submit">
                {isLoading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  'Add'
                )}
              </button>
              <button className="continue btn button-orange " type="submit" onClick={(e) => handleContinue()}>
                <NavigateNextIcon fontSize="large"/>
              </button>
            </div>
          </div>
        ) : (
          <div className="row mt-4">
            <button className="btn button-orange mx-auto" type="submit">
              {isLoading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                'SIGN UP'
              )}
            </button>
          </div>
        )}
      </div>
    </form>
  );
};