import { UserActions } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers/rootReducer';
import { ISignUpData, IUser } from 'utils/types';
import { useHistory } from 'react-router-dom';
import { validationSchema } from './validations';
import { SignUpData } from 'components/organisms/SignUpData/SignUpData';

export const SignUp: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user: IUser = useSelector((state: IRootState) => state.user);
  const submitCallback = (userData: ISignUpData) => {
    dispatch(UserActions.createUser(userData, history));
  };
  
  return <SignUpData submitCallback={submitCallback} user={user} validationSchema={validationSchema} />;
};
