import axios from 'axios';
import { BASE_API } from './variables';
import { ISignUpData, ILoginForm, IUserToken, IAxiosResponse, ISkill, ILanguage, 
  IUser, IExperience, IEducation, ILink, IStatus, IPassword, INewPassword, IReference } from './types';
import { handleResponseErrors } from './handleResponseErrors';
import { string } from 'yup/lib/locale';


type ILogin = (user: ILoginForm) => Promise<Error | IAxiosResponse<IUserToken>>;
type ILoginGoogle = (googleData) => Promise<Error | IAxiosResponse<IUserToken>>;
type IListSkills = (search: string) => Promise<Error | IAxiosResponse<ISkill[]>>;
type IListLanguages = (search: string) => Promise<Error | IAxiosResponse<ILanguage[]>>;
type IRefreshToken = (userID: number, config: string) => Promise<Error | IAxiosResponse<IUserToken>>;
type ISignUp = (signupDate: ISignUpData) => Promise<Error | IAxiosResponse<IUser>>;
type IExperienceUp = (saveExperience: IExperience) => Promise<Error | IAxiosResponse<IExperience>>;
type IEducationUp = (saveEducation: IEducation) => Promise<Error | IAxiosResponse<IEducation>>;
type ILinkUp = (saveLink: ILink) => Promise<Error | IAxiosResponse<ILink>>;
type IStatusUp = (saveStatus: IStatus) => Promise<Error | IAxiosResponse<IStatus>>;
type IReferenceUp = (saveReference: IReference) => Promise<Error | IAxiosResponse<IReference>>;

const api = axios.create({
  baseURL: BASE_API,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});
api.interceptors.response.use(function (response) {
  return response.data;
});
api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) config.headers['authorization'] = token;
  return config;
}, function(error) {
  return Promise.reject(error);
});

const getUsers = async ({limitNumber, pageNumber, name, skill, language, city, status}) => {
  const url = `users?name=${name}&skill=${skill}&language=${language}&city=${city}&status=${status}&limit=${limitNumber}&page=${pageNumber}`;
  const response = await api.get(url).catch(handleResponseErrors);
  return response;
};

const getUser = async (id) => {
  const url = `users/${id}`;
  const response = await api.get(url).catch(handleResponseErrors);
  return response;
};

const listSkills: IListSkills = async (search) => {
  const url = search ? `skills?title=${search}` : 'skills';
  const response = (await api.get(url).catch(handleResponseErrors)) as IAxiosResponse<ISkill[]>;
  return response;
};

const listLanguages: IListLanguages = async (search) => {
  const url = search ? `languages?title=${search}` : 'languages';
  const response = (await api.get(url).catch(handleResponseErrors)) as IAxiosResponse<ILanguage[]>;
  return response;
};

const getUserLanguages = async ({id}) => {
  const url = `users/${id}/languages`;
  const response = await api.get(url).catch(handleResponseErrors) as IAxiosResponse<ILanguage[]>;;
  return response;
};

const login: ILogin = async (user = { password: '', email: '' }) => {
  const url = 'auth/login';
  const response = (await api.post(url, user).catch(handleResponseErrors)) as IAxiosResponse<IUserToken>;
  return response;
};

const loginGoogle: ILoginGoogle = async (googleData) => {
  const url = 'auth/google';
  const user = {email: googleData.email, given_name: googleData.given_name, family_name: googleData.family_name, iss: 'accounts.google.com'}
  const response = (await api.post(url, user).catch(handleResponseErrors)) as IAxiosResponse<IUserToken>;
  return response;
};

const refreshToken: IRefreshToken = async (userID) => {
  const url = 'auth/refreshtoken';
  const body = {
    userID,
  };

  const response = (await api.post(url, body).catch(handleResponseErrors)) as IAxiosResponse<IUserToken>;
  return response;
};

const signUp: ISignUp = async (signUpData) => {
  const url = `users`;
  const response = (await api.post(url, signUpData ).catch(handleResponseErrors)) as IAxiosResponse<IUser>;
  return response;
};

const updatePersonalData = async (personalData) => {
  const url = `users/${personalData.id}`;
  const response = await api.put(url, personalData.data ).catch(handleResponseErrors);
  return response;
};

const AdminUpdatePersonalData = async (queryData) => {
  const url = `users/${queryData.user.id}`;
  const response = await api.put(url, queryData ).catch(handleResponseErrors);
  return response;
};

const saveExperience: IExperienceUp = async (experienceData) => {
  const url = `experiences`;
  const response = (await api.post(url, experienceData ).catch(handleResponseErrors)) as IAxiosResponse<IExperience>;
  return response;
};

const updateExperience = async (experience) => {
  const url = `experiences/${experience.id}`;
  const response = await api.put(url, experience.data ).catch(handleResponseErrors);
  return response;
};

const getUserExperience = async ({id}) => {
  const url = `users/${id}/experiences`;
  const response = await api.get(url).catch(handleResponseErrors);
  return response;
};

const getExperience = async (id) => {
  const url = `experiences/${id}`;
  const response = await api.get(url).catch(handleResponseErrors);
  return response;
};

const deleteExperience = async (id) => {
  const url = `experiences/${id}`;
  const response = await api.delete(url).catch(handleResponseErrors);
  return response;
};

const saveEducation: IEducationUp = async (educationData) => {
  const url = `educations`;
  const response = (await api.post(url, educationData ).catch(handleResponseErrors)) as IAxiosResponse<IEducation>;
  return response;
};

const updateEducation = async (education) => {
  const url = `educations/${education.id}`;
  const response = await api.put(url, education.data ).catch(handleResponseErrors);
  return response;
};

const getUserEducation = async ({id}) => {
  const url = `users/${id}/educations`;
  const response = await api.get(url).catch(handleResponseErrors);
  return response;
};

const getEducation = async (id) => {
  const url = `educations/${id}`;
  const response = await api.get(url).catch(handleResponseErrors);
  return response;
};

const deleteEducation = async (id) => {
  const url = `educations/${id}`;
  const response = await api.delete(url).catch(handleResponseErrors);
  return response;
};

const saveLink: ILinkUp = async (linkData) => {
  const url = `links`;
  const response = (await api.post(url, linkData).catch(handleResponseErrors)) as IAxiosResponse<ILink>;
  return response;
};

const updateLink = async (link) => {
  const url = `links/${link.id}`;
  const response = await api.put(url, link.data).catch(handleResponseErrors);
  return response;
};

const getUserLink = async ({id}) => {
  const url = `users/${id}/links`;
  const response = await api.get(url).catch(handleResponseErrors);
  return response;
};

const getLink = async (id) => {
  const url = `links/${id}`;
  const response = await api.get(url).catch(handleResponseErrors);
  return response;
};

const deleteLink = async (id) => {
  const url = `links/${id}`;
  const response = await api.delete(url).catch(handleResponseErrors);
  return response;
};

const getCurriculum = async (id) => {
  const url = `users/curriculum/${id}`;
  const response = await api.get(url).catch(handleResponseErrors);
  return response;
};

const getUserStatus = async (id) => {
  const url = `users/${id}/status`;
  const response = await api.get(url).catch(handleResponseErrors);
  return response;
};

const getStatus = async (id) => {
  const url = `status/${id}`;
  const response = await api.get(url).catch(handleResponseErrors);
  return response;
};

const deleteStatus = async (id) => {
  const url = `status/${id}`;
  const response = await api.delete(url).catch(handleResponseErrors);
  return response;
};

const saveStatus: IStatusUp = async (statusData) => {
  const url = `status`;
  const response = (await api.post(url, statusData).catch(handleResponseErrors)) as IAxiosResponse<IStatus>;
  return response;
};

const updateStatus = async (status) => {
  const url = `status/${status.id}`;
  const response = await api.put(url, status.data).catch(handleResponseErrors);
  return response;
};

const forgotPassword = async (email) => {
  const url = `auth/forgot-password`;
  const response = (await api.put(url, email.data).catch(handleResponseErrors)) as IAxiosResponse<IPassword>;
  if (response) {
    return email;
  }
};

const newPassword = async (password) => {
  const url = `auth/new-password`;
  const response = (await api.put(url, password.data).catch(handleResponseErrors)) as IAxiosResponse<INewPassword>;
  password.data.repeatPassword = "";
  password.data.email = response.data.email;
  return password;
};

const getUserReference = async (id) => {
  const url = `users/${id}/references`;
  const response = await api.get(url).catch(handleResponseErrors);
  return response;
};

const getReference = async (id) => {
  const url = `references/${id}`;
  const response = await api.get(url).catch(handleResponseErrors);
  return response;
};

const saveReference: IReferenceUp = async (referenceData) => {
  const url = `references`;
  if (!referenceData.type) {
    referenceData.type = false;
  }
  const response = (await api.post(url, referenceData).catch(handleResponseErrors)) as IAxiosResponse<IReference>;
  return response;
};

export {
  listSkills, listLanguages, login, loginGoogle, refreshToken, signUp, updatePersonalData, AdminUpdatePersonalData, getUserLanguages,
  updateExperience, saveExperience, getUserExperience, deleteExperience, getExperience,
  updateEducation, saveEducation, getUserEducation, deleteEducation, getEducation,
  updateLink, saveLink, getUserLink, getLink, deleteLink, getCurriculum, getUsers ,getUser, 
  getUserStatus, getStatus, deleteStatus, updateStatus, saveStatus, forgotPassword, newPassword,
  saveReference, getReference, getUserReference
};