import React, { useEffect } from 'react';
import logo42people from '../../assets/images/logo42people.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from 'actions';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { validationSchema } from './validations';
import { useValidationResolver } from 'utils/useValidationResolver';
import './style.scss';
import { ILoginForm } from 'utils/types';
import { IRootState } from 'reducers/rootReducer';
import { Input } from 'components/atoms';
import * as queryString from 'query-string';
import axios from 'axios';

const Login = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: IRootState) => state.utils);
  const resolver = useValidationResolver(validationSchema);
  const { register, handleSubmit, errors } = useForm({ mode: 'onTouched', resolver });
  
  const onSubmit = async (data: ILoginForm) => {
    dispatch(AuthActions.login(data));
  };

  const stringifiedParams = queryString.stringify({
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_FRONT + '/login',
    scope: [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
    ].join(' '), // space seperated string
    response_type: 'code',
    access_type: 'offline',
    prompt: 'consent',
  });
  const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?${stringifiedParams}`;
  const urlParams = queryString.parse(window.location.search);

  if(urlParams.code) {
  }
  useEffect(() => {
    if(urlParams.code) {
      async function getAccessTokenFromCode() {
        const { data } = await axios({
          url: `https://oauth2.googleapis.com/token`,
          method: 'post',
          data: {
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
            redirect_uri: process.env.REACT_APP_FRONT + '/login',
            grant_type: 'authorization_code',
            code: urlParams.code,
          },
        }).then();
        return data.access_token;
      };
      getAccessTokenFromCode().then((token) => {
        async function getGoogleUserInfo(access_token) {
          const { data } = await axios({
            url: 'https://www.googleapis.com/oauth2/v2/userinfo',
            method: 'get',
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          });
          return data;
        };
        getGoogleUserInfo(token).then((data) => dispatch(AuthActions.loginGoogle(data)));
      });
    }
  },[urlParams.code])

  return (
    <div className="login">
      <div className="login-form">
        <img className="logo" src={logo42people} alt="42people" />
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="email mx-auto">
            <Input type="email" name="email" label="Email" register={register} error={errors.email} />
          </div>
          <div className="password mx-auto">
            <Input type="password" name="password" label="Password" register={register} error={errors.password} />
          </div>
          <div className="submit mx-auto">
            <button className="btn button-orange mx-auto" type="submit">
              {isLoading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                'LOG IN'
              )}
            </button>
            <br/>
            <br/>
            <a className="google-link" href={googleLoginUrl}>
              <div className="google-btn">
                <div className="google-icon-wrapper">
                  <img className="google-icon" src="https://developers.google.com/identity/images/branding_guideline_sample_lt_sq_sl.svg"/>
                </div>
                <p className="btn-text m-0">LOG IN WITH GOOGLE</p>
              </div>
            </a>
          </div>
          <div className="signup mt-3">
            <label>Don't have an account?</label>
            <Link to="/signup" className="signup-button btn">
              Sign up
            </Link>
            <br/>
            <Link to="/forgot-password" className="signup-button btn">
              Forgot password?
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Login;