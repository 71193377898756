import React, { InputHTMLAttributes } from 'react';
import './styles.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: {
    type: string;
    message: string;
  };
  label?: string;
  name: string;
  register: any;
}

export const Input: React.FC<Props> = ({ error, label, name, register, ...restProps }: Props) => {
  return (
    <div>
      {label ? <label className="form-label">{label}</label> : null}
      <input className={error ? 'form-control is-invalid' : 'form-control'} name={name} ref={register} {...restProps} />
      {error ? <label className="error">{error.message}</label> : null}
    </div>
  );
};