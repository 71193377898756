import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './styles.scss';
import { IUser, IAxiosResponse } from 'utils/types';
import { IRootState } from 'reducers/rootReducer';
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { UserProgress } from 'components/molecules/UserProgress';
import { getUser } from 'utils/API';
import { AuthActions } from 'actions';

const paths = {
  '/personal-data': 0,
  '/education': 1,
  '/experience': 2,
  '/languages': 3,
  '/links': 4,
  '/curriculum': 5,
  '/list-users': 6,
};

interface Props {}

export const TopNavbar: React.FC<Props> = ({}: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const routes = ['/personal-data', '/education', '/experience', '/languages', '/links', '/curriculum', '/list-users']
  const history = useHistory();
  const selectedTab = useRef<any>();
  const [userSelected, setUserSelected] = useState({});
  const user: IUser = useSelector((state: IRootState) => state.user);
  const { id, isAuthenticated } = useSelector((state: IRootState) => state.auth);

  const handleSelectTab = (event) => {
    getUserss(id);
  };

  useEffect(() => {
    const tabs = document.getElementById('tabs-container').childNodes;
    const selectedTabIndex = paths[history.location.pathname];
    selectedTab.current = tabs[selectedTabIndex];
    selectedTab.current?.classList.add('selected');
    dispatch(AuthActions.refreshToken(id));
  }, []);

  useEffect(() => {
    routes.map(route => {
      const idRoute = route.substr(1);
      if (route == location.pathname) {
        document.getElementById(idRoute)?.classList.add('selected')
      } else {
        document.getElementById(idRoute)?.classList.remove('selected')
      }
    })
  }, [location.pathname]);

  async function getUserss(id: number) {
    const response = (await getUser(id)) as IAxiosResponse<IUser[]>;
    if (response?.data) setUserSelected(response?.data);
  }

  useEffect(() => {
    getUserss(id);
  },[id]);  

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-center tabs-container card row navbar-light" id="tabs-container">
        <div className="container-fluid">
        <a className="navbar-brand" href="#"></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-center align-items-center" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/personal-data" id="personal-data" className="tab-link col btn nav-link active" onClick={handleSelectTab}>
                  Personal data
                </Link>
              </li>
              <li className="nav-item ">
                <Link to="/education" id="education" className="tab-link col btn nav-link active" onClick={handleSelectTab}>
                  Education
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/experience" id="experience" className="tab-link col btn nav-link active" onClick={handleSelectTab}>
                  Experience
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/languages" id="languages" className="tab-link col btn nav-link active" onClick={handleSelectTab}>
                  Languages
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/links" id="links" className="tab-link col btn nav-link active" onClick={handleSelectTab}>
                  Links
                </Link>
              </li>
              { (user.role == 'ADMIN' || user.role == 'EMPLOYEE') ? 
                <li className="nav-item">
                  <Link to="/curriculum" id="curriculum" className="tab-link col btn nav-link active" onClick={handleSelectTab}>
                    Curriculum
                  </Link>
                </li>
               : null }
              { (user.role == 'ADMIN' || user.role == 'EMPLOYEE') ? 
                <li className="nav-item">
                  <Link to="/list-users" id="list-users" className="tab-link col btn nav-link active" onClick={handleSelectTab}>
                    People
                  </Link>
                </li>
               : null }
            </ul>
          </div>
        </div>
      </nav>
      {location.pathname != "/list-users" && !location.pathname.includes("/user-detail") ?
      <UserProgress user={userSelected}/>
      : null }
    </div>
  );
};