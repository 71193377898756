import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions, UserActions } from 'actions';
import './styles.scss';
import { IRootState } from 'reducers/rootReducer';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useValidationResolver } from 'utils/useValidationResolver';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import ThumbDown from '@material-ui/icons/ThumbDown';
import { IReference, ISignUpData} from 'utils/types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { MAX_CHARACTERS } from 'utils/variables';

interface Props {
  id: number;
  idUserSelected: number;
  userDataSelected: any;
  allReferences: any;
  submitCallback: (userReferenceData: IReference) => void;
  validationSchema: any;
}

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const AddReferenceUser: React.FC<Props> = ({ id, idUserSelected, userDataSelected, allReferences, submitCallback, validationSchema }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const resolver = useValidationResolver(validationSchema);
  const { isLoading } = useSelector((state: IRootState) => state.utils);
  const [referenceUp, setReferenceUp] = useState(true);
  const [referenceDown, setReferenceDown] = useState(false);
  const [countCharacters,setCountCharacters] = useState(0);
  /* el tipo de referencia es true cuando es ThumbUp & false cuando es ThumbDown */
  const [referenceType, setReferenceType] = useState(true);
  const [description, setDescription] = useState('');
  const [descriptionDown, setDescriptionDown] = useState('');
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    mode: 'onTouched',
    resolver,
    defaultValues: {allReferences},
  });
  
	useEffect(() => {
    if (id) dispatch(AuthActions.refreshToken(id));
    dispatch(UserActions.readUser(id));
  }, []);

  const onSubmit = async (data: IReference, e): Promise<void> => {
    data.userId = Number(idUserSelected);
    data.referenceId = id;
    data.description = data.description;
    data.type = referenceType;
    data.description = description;
    const userReferenceData = {
      ...data,
    } as ISignUpData;
    e.target.reset();
    submitCallback(userReferenceData)
  }; 

  const changeReferenceUp = () => {
    ((document.getElementById('descriptionRef')) as HTMLInputElement).value = "";
    setReferenceType(true);
    setReferenceUp(true);
    setReferenceDown(false);
    setCountCharacters(0);
  }

  const changeReferenceDown = () => {
    ((document.getElementById('descriptionRef')) as HTMLInputElement).value = "";
    setReferenceType(false);
    setReferenceDown(true);
    setReferenceUp(false);
    setCountCharacters(0);
  }

  const handleDescriptionUp = (e) => {
    setDescription(e.target.value);
    setCountCharacters(e.target.value.length);
  }

  const handleDescriptionDown = (e) => {
    setDescriptionDown(e.target.value)
    setDescription(e.target.value);
    setCountCharacters(e.target.value.length);
  }

  const saveReference = () => {
    setOpen(true);
    const dataReference = {
      userId: Number(idUserSelected), 
      referenceId: id,
      type: referenceType,
      description: description,
    } as ISignUpData;
    submitCallback(dataReference)
    setDescription('');
    ((document.getElementById('descriptionRef')) as HTMLInputElement).value = "";
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h4 id="simple-modal-title">Only one positive and/or negative reference can be added per user</h4>
      <p id="simple-modal-description">
        If you add a new reference, the old one will be replaced
      </p>
    </div>
  );

  const handleCloseModal = () => {
    setOpen(false);
  };
  
  return (
    <div className="container-references row col-12">
      <div className="col-12 p-0 mt-5">
        <label className="title col-12">REFERENCES</label>
        <hr />
        <div className="mt-3">
          <form className="reference-user-form" onSubmit={handleSubmit(onSubmit)} >
            <div className="row p-0 m-0 col-12 form-body container-subtitle">
              <div className="col-6 p-0 m-0 iconLeft">
                <FormControlLabel
                  control={<Checkbox icon={<ThumbUpAltOutlinedIcon style={{color: "black"}}/>} checkedIcon={<ThumbUp style={{color: "black"}}/>} name="checkedUp" />}
                  id='checkDescriptionReference'
                  label=""
                  className="profile-picture-thumb"
                  checked={referenceUp}
                  onClick={() => changeReferenceUp()}
                />
              </div>
              <div className="col-6 p-0 m-0 iconRight">
                <FormControlLabel
                  control={<Checkbox icon={<ThumbDownAltOutlinedIcon style={{color: "black"}}/>} checkedIcon={<ThumbDown style={{color: "black"}}/>} name="checkedDown" />}
                  id='checkDescriptionReference'
                  label=""
                  className="profile-picture-thumb"
                  checked={referenceDown}
                  onClick={() => changeReferenceDown()} 
                />
              </div>
            </div>    
            {referenceUp == true ? 
              <div className="text-form">
                <div className="description col-12 mx-auto mt-2">
                  <label htmlFor="description-thumbUp" className="form-label">
                    Description
                  </label>
                  <textarea
                    name="descriptionRef"
                    ref={register}
                    className='form-control'
                    id="descriptionRef"
                    placeholder="Tell us why you are giving this reference to the user"
                    onChange={e => handleDescriptionUp(e)}
                    maxLength={MAX_CHARACTERS}
                  />
                  <label className="label-introduction">{countCharacters}/{MAX_CHARACTERS}</label>
                </div>
              </div>
            : null}      
            {referenceDown == true ? 
              <div className="text-form">
                <div className="description col-12 mx-auto mt-2">
                  <label htmlFor="description-thumbDown" className="form-label">
                    Description*
                  </label>
                  <textarea
                    name="descriptionRef"
                    ref={register}
                    onChange={e => handleDescriptionDown(e)}
                    className={errors?.descriptionRef ? 'form-control is-invalid' : 'form-control'}
                    id="descriptionRef"
                    placeholder="Tell us why you are giving this reference to the user"
                    maxLength={MAX_CHARACTERS}
                  />
                  <label className="label-introduction">{countCharacters}/{MAX_CHARACTERS}</label>
                </div>
              </div>
            : null}  
            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {body}
            </Modal>
            {id ? (
              <div className="save-submit row">
                <button className="btn button-orange" type="submit" onClick={saveReference} disabled={(referenceDown == true && descriptionDown == '')}>
                  {isLoading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    'Add reference'
                  )}
                </button>
              </div>
            ) : (
              <div className="row mt-4">
                <button className="btn button-orange mx-auto" type="submit">
                  {isLoading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    'SIGN UP'
                  )}
                </button>
              </div>
              )}
          </form>
        </div>
      </div>
    </div>
  );
};
