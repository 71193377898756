import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UserActions, AuthActions } from 'actions';
import DefaultPicture from 'assets/images/default_picture.jpg';
import { Link } from 'react-router-dom';
import {
  IAxiosResponse, 
  IUser
} from 'utils/types';
import './styles.scss';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { getUser } from 'utils/API';

const useStyles = makeStyles({
	pos: {
		marginBottom: 12,
	},
});

interface Props {
  id: number;
  idUserSelected: number;
  userDataSelected?: any;
}

export const UserDetailEmployee: React.FC<Props> = ({ id, idUserSelected, userDataSelected }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  var Spinner = require('react-spinkit');

  async function getUserss(idUserSelected: number) {
    const response = (await getUser(idUserSelected)) as IAxiosResponse<IUser[]>;
    if (response?.data) {
      (userDataSelected = response?.data);
    }
  }

  useEffect(() => {
    getUserss(idUserSelected);
  },[userDataSelected.seniorityId]);  
    
  useEffect(() => {
    const idUser = idUserSelected;
    if (id) dispatch(AuthActions.refreshToken(id));
  }, []);

  useEffect(() => {
    dispatch(UserActions.readUser(idUserSelected))
  }, [userDataSelected.seniorityId]);

  return (
    <div className="container-userDetail row col-12">
      <div className="col-md-1 col-xs-12 p-0">
        <Link to="/list-users">
          <FontAwesomeIcon className="icon" icon={faChevronLeft} />
        </Link>
      </div>
      <div className="col-md-11 col-xs-12 p-0">
        {userDataSelected.firstName ?
        <div className="col-12 row m-0 p-0">
          <div className="col-md-12 row mt-3 mb-4">
            <div className="col-md-4 col-xs-12 p-0 containerPicture ml-2 mr-2">
              <img alt="delete" src={userDataSelected?.pictureUrl || DefaultPicture} className="pictureUser"/>
            </div>
            <div className="col-md-8 col-xs-12 p-0 containerInfo">
              <label className="name2 col-12" >{userDataSelected.firstName} {userDataSelected.lastName}</label>
              <div className="col-12 mt-3 mb-3">
              {userDataSelected.seniorityId? (
                <div className="row col-12 m-0">
                  <span className="senioritie-text col-12 p-0">
                    <Typography variant="h5" color="textSecondary" align="left">
                      {userDataSelected.seniorities?.title}
                    </Typography>
                  </span>
                </div> 
                ) : null
              }     
              </div>
              <label className="position col-12">{userDataSelected.country} - {userDataSelected.city}</label>
              <label className="email col-12">{userDataSelected.email} {userDataSelected.phone?.length ? '- ' : null } {userDataSelected.phone?.length ? (userDataSelected.phone) : null }</label>
              <p className="description col-12"> {userDataSelected.introduction} </p>
            </div>
          </div>
          {userDataSelected.skills?.length  ? (
            <div className="col-12 p-0">
              <div className="featureContainer">
                <label className="titles">SKILLS</label>
                <div className="items-row row mt-2">
                  {userDataSelected.skills.map((skill, index) => (
                    <span key={index} className='col-auto mt-2 mx-2 btn badge rounded-pill skill-badge'>
                      {skill.title}
                    </span>)
                  )}
                </div>
              </div>
            </div>
          ) : null }
          {userDataSelected.experiences?.length  ? (
            <div className="col-12 p-0">
              <div className="featureContainer">
                <label className="titles">EXPERIENCES&nbsp; <label className="statusMate">{userDataSelected?.status[0]?.title === 'Mate'? '@42mate since: '+userDataSelected?.status[0].createdAt.slice(0,10) : null}</label></label> 
                <div className="items-row row">
                  { userDataSelected.experiences.map((experience, index) => (
                    <span key={index} className="mb-3">
                      <Typography className={classes.pos} color="textSecondary">
                      {experience.title}
                      </Typography>
                      <label className="col-12 p-0">
                        {experience.company} {experience.company ? <>|</> : null} {experience.startYear ? experience.startYear : ' ... '} - {experience.finishYear ? experience.finishYear : ' ... '}
                      </label>
                      <br/>
                      <label className="experienceDescription">
                        {experience.description}
                      </label>
                    </span>)
                  )}
                </div>
              </div>
            </div>
            ) : null }
          {userDataSelected.educations?.length ? (
            <div className="col-12 p-0">
              <div className="featureContainer">
                <label className="titles">EDUCATIONS</label>
                <div className="items-row row">
                  { userDataSelected.educations.map((education, index) => (
                    <span key={index} className="mb-3">
                      <Typography className={classes.pos} color="textSecondary">
                        {education.title}
                      </Typography>
                      <label className="infoEducations">
                        {education.institution} - {education.country} - 
                      </label>
                      <label>
                         {education.level} | {education.startYear ? education.startYear : ' ... '} - {education.finishYear ? education.finishYear : ' ... '}
                      </label>
                    </span>)
                  )}
                </div>
              </div>
            </div>
          ) : null }
          {userDataSelected.languages?.length  ? (
            <div className="col-12 p-0">
              <div className="featureContainer">
                <label className="titles">LANGUAGES</label>
                <div className="items-row row">
                  { userDataSelected.languages.map((language, index) => (
                    <span key={index} className="mb-3">
                      <Typography className={classes.pos} color="textSecondary">
                        {language.title}
                      </Typography>
                      <label >
                        {language.level.charAt(0) + language.level.slice(1).toLowerCase()}
                      </label>
                    </span>)
                    )}
                </div>
              </div>
            </div>
          ) : null }
          {userDataSelected.links?.length  ? (
            <div className="col-12 p-0">
              <div className="featureContainer">
                <label className="titles">LINKS</label>
                <div className="items-row row mt-2">
                  { userDataSelected.links.map((link, index) => (
                    <span key={index} >
                      <Typography className={classes.pos} color="textSecondary">
                        {link.title}
                      </Typography>
                      <label >
                        <a href={link.url.includes('http') ? link.url : '//'+link.url} target="_blank">{link.url}</a>
                      </label>
                    </span>)
                  )}
                </div>
              </div>
            </div>
          ) : null }
        </div>
        :
        <div className="mt-5 container-spinner">
          <Spinner name="chasing-dots" className="spinnerUser"/>
        </div> }
      </div>
    </div>
  );
};
