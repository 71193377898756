import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Cards } from './';
import { IExperience, IAxiosResponse } from 'utils/types';
import { Input } from 'components/atoms';

interface Props {
	id: number;
	experiences: Array<IExperience>;
	totalExperiences: number;
	updateCallback: (userExperienceData: IExperience) => void;
	experience?: IExperience;
	validationSchema: any;
}

export const Data: React.FC<Props> = ({ id, experiences, totalExperiences, updateCallback, experience, validationSchema }: Props) => {

  const [search, setSearch] = useState('');
  const filteredExperiences = experiences.filter(
    (experience) => {
      return experience.title.toUpperCase().includes(search.toUpperCase()) ||
        experience.company.toUpperCase().includes(search.toUpperCase()) ||
        experience.description?.toUpperCase().includes(search.toUpperCase()) ||
        experience.skills?.find((skill) => skill.title.toUpperCase().includes(search.toUpperCase()))
    }
  );

	if (totalExperiences != 0) {
		return (
			<div className="card-experience-form">
				<h5 className="text-no-experience">MY EXPERIENCE</h5>
				<div className="filter col-md-12 col-sm-12 col-xs-12">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <Input value={search} type="text" onChange={e => setSearch(e.target.value)}
              name="filter" placeholder="Search" register={null} />
          </div>
        </div>
				<Cards experiences={filteredExperiences} updateCallback={updateCallback} validationSchema={validationSchema} experienceDOM={experience} />
				
			</div>
		);
	} else {
		return (
			<div className="card-experience-form">
				<h5 className="text-no-experience">MY EXPERIENCE</h5>
				<h5 className="mt-4">You have not added any experience yet..</h5>
			</div>
		)
	}
};