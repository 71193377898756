import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from 'actions';
import { useValidationResolver } from 'utils/useValidationResolver';
import './styles.scss';
import {
	ILanguage,
	IAxiosResponse,
	ISignUpData,
	IPersonalData
} from 'utils/types';
import { IRootState } from 'reducers/rootReducer';
import { listLanguages } from 'utils/API';
import { languages, levels } from '../objects';
import { useHistory } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

interface Props {
	userId?: number;
	submitCallback: (userData: ISignUpData | IPersonalData) => void;
	language?: any;
	validationSchema: any;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 5, 3),
  },
}));

export const UserLanguageData: React.FC<Props> = ({ userId, submitCallback, language, validationSchema }: Props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [titleSelected, setTitleSelected] = useState('');
	const [levelSelected, setLevelSelected] = useState('');
	const { isLoading } = useSelector((state: IRootState) => state.utils);
	const resolver = useValidationResolver(validationSchema);
	const { register, handleSubmit, setValue, watch, errors } = useForm({
		mode: 'onTouched',
		resolver,
		defaultValues: {
			...language,
		},
	});

	useEffect(() => {
		if (userId) dispatch(AuthActions.refreshToken(userId));
	}, []);

	const onSubmit = async (data: any): Promise<void> => {
    if (titleSelected && levelSelected) {
			const languagesResponse = (await (listLanguages(data.title))) as IAxiosResponse<ILanguage[]>;
			const languageResponse = languagesResponse.data.find(element => element.level == data.level);
			const userData = {
				id: languageResponse.id,
				...data
			};
			submitCallback(userData);
		}
		setTitleSelected('')
		setLevelSelected('')
	};

	function handleChangeTitle(event) {
		setTitleSelected(event.target.value);
	}
	function handleChangeLevel(event) {
		setLevelSelected(event.target.value);
	}

	const handleContinue = () => {
		if ((titleSelected != '') || (levelSelected != '')) {
			setOpen(true);
		} else {
			history.push('/links');	
		}
  }

	const handleClose = () => {
    setOpen(false);
  };

	const handleStayModal = () => {
    setOpen(false)
  };

	const handleContinueModal = () => {
    history.push('/links');	
  };

	return (
		<form id="language-form" onSubmit={handleSubmit(onSubmit)} className="language-form pb-5" onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}}>
			<div className="form-body">
				<div className="text-form">
					<div className="row mt-4">
						<div className="title col-12 ">
							<label className="form-label">Language *</label>
							<select name="title" id="title" value={titleSelected} onChange={e => handleChangeTitle(e)}
								className={errors?.title ? 'form-control is-invalid' : 'form-control'} ref={register}
							>
								<option value="default" hidden>Select an option...</option>
								{languages.map((language, index) =>
									<option key={index} value={language.value}>{language.name}</option>
								)}
							</select>
							{errors.title ? <label className="error">{errors.title}</label> : null}
						</div>
					</div>
					<div className="row mt-4">
						<div className="level col-12 ">
							<button type="button" data-toggle="tooltip" data-placement="right"  className="button-level"
								title="References: 
Beginner (Principiante)  
Elementary (Elemental)
Pre Intermediate (Pre-Intermedio)  
Intermediate (Intermedio)  
Upper Intermediate (Intermedio-superior) 
Advanced (Avanzado)
Native (Nativo)">
								<label className="form-label">Level *</label>
							</button>
							<select name="level" id="level" value={levelSelected} onChange={e => handleChangeLevel(e)}
								className={errors?.levelTitle ? 'form-control is-invalid' : 'form-control'} ref={register}
							>
								<option value="default" hidden>Select an option...</option>
								{levels.map((level, index) =>
									<option key={index} value={level.value}>{level.name}</option>
								)}
							</select>
							{errors.level ? <label className="error">{errors.level}</label> : null}
						</div>
					</div>
				</div>
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className={classes.modal}
					open={open}
					onClose={handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={open}>
						<div className={classes.paper}>
							<p id="transition-modal-description">There are unsaved changes. If you continue, all unsaved data will be lost.</p>
							<div className="save-submit row">
								<button type="button" className="continue m-1 btn button-orange" onClick={handleStayModal}>
									Stay
								</button>
								<button type="button" className="continue m-1 btn button-orange" onClick={handleContinueModal}>
									Continue
								</button>
							</div>
						</div>
					</Fade>
				</Modal>
				{userId ? (
          <div>
            <div className="save-submit row mt-4">
              <button className="save btn button-orange" type="submit">
                {isLoading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  'Add'
                )}
              </button>
              <button className="continue btn button-orange " type="button" onClick={() => handleContinue()}>
                <NavigateNextIcon fontSize="large"/>
              </button>
            </div>
					</div>
				) : (
					<div className="row mt-4">
						<button className="btn button-orange mx-auto" type="submit">
							{isLoading ? (
								<div className="spinner-border text-light" role="status">
									<span className="visually-hidden">Loading...</span>
								</div>
							) : (
								'SIGN UP'
							)}
						</button>
					</div>
				)}
			</div>
		</form>
	);
};