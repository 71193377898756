import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from 'actions';
import { useValidationResolver } from 'utils/useValidationResolver';
import { Input } from 'components/atoms';
import './styles.scss';
import {
	IEducation
} from 'utils/types';
import { IRootState } from 'reducers/rootReducer';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import Moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { levelsEducation } from '../objects';
import { useHistory } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

interface Props {
	userId?: number;
	submitCallback: (userEducationData: IEducation) => void;
	education?: IEducation;
	validationSchema: any;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 5, 3),
  },
}));

export const UserEducationData: React.FC<Props> = ({ userId, submitCallback, education, validationSchema }: Props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [levelSelected, setLevelSelected] = useState('');
	const { isLoading } = useSelector((state: IRootState) => state.utils);
	const resolver = useValidationResolver(validationSchema);
	const [submit, setSubmit] = useState(true);
	const { register, handleSubmit, errors } = useForm({
		mode: 'onTouched',
		resolver,
		defaultValues: { education },
	});

	useEffect(() => {
		if (userId) dispatch(AuthActions.refreshToken(userId));
	}, []);

	useEffect(() => {
		setSubmit(true)
	}, [!submit]);

	const onSubmit = async (data: IEducation, e): Promise<void> => {
		data.userId = userId;
		const userEducationData = {
			...data
		};
		if (startYear <= finishYear || data.finishYear == 'Present') {
      e.target.reset();
			submitCallback(userEducationData);
		} else {
			alert('Your finishYear cannot be less than the startYear')
		}
		setLevelSelected('')
		setChecked(false)
		setStartYear('')
		setFinishYear('')
		setSubmit(false)
	};

	const yesterday = Moment().subtract(1, 'day');
	const valid = function (current) {
		return current <= yesterday;
	};
	const inputPropsStart = {
		placeholder: 'Pick a year',
		name: 'startYear',
		autoComplete: 'off',
		disabled: education.isEdit,
		readOnly: true
	}
	const inputPropsFinish = {
		placeholder: 'Pick a year',
		name: 'finishYear',
		autoComplete: 'off',
		disabled: education.isEdit,
		readOnly: true
	}	
	
	const handleAddStart = (date) => { setStartYear(date.year()) }
	const [startYear, setStartYear] = useState('');
	const handleAddFinish = (date) => { setFinishYear(date.year()) }
	const [finishYear, setFinishYear] = useState('');
	const [checked, setChecked] = useState(false);

	const handleChecbox = () => {
		if (checked == true) {
			setChecked(false)
		} else {
			setChecked(true)
		}
	}

	const handleChangeLevel = (event) => {
		setLevelSelected(event.target.value);
	}

  const handleContinue = () => {
		const title = ((document.getElementById('title')) as HTMLInputElement).value
		const institution = ((document.getElementById('institution')) as HTMLInputElement).value
		const country = ((document.getElementById('country')) as HTMLInputElement).value
		if ((title != '') || (institution != '') || (country != '') || (levelSelected != '') || (startYear != '') || (finishYear != '')) {
			setOpen(true);
		} else {
			history.push('/experience');	
		}
  }

	const handleClose = () => {
    setOpen(false);
  };

	const handleStayModal = () => {
    setOpen(false)
  };

	const handleContinueModal = () => {
    history.push('/experience');	
  };

	return (
		<form id="education-form" onSubmit={handleSubmit(onSubmit)} className="education-form pb-5" onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}}>
			<div className="form-body">
				<div className="text-form">
					<div className="row mt-4">
						<div className="title col-12 ">
							<Input name="title" id="title" label="Career / Title *" register={register} error={errors?.title}
							disabled={education.isEdit} />
						</div>
					</div>
					<div className="row mt-4">
						<div className="institution col-12 ">
							<Input name="institution" id="institution" label="Institution / Platform*" register={register} error={errors?.institution}
							disabled={education.isEdit} />
						</div>
					</div>
					<div className="row mt-4">
						<div className="level col-12 ">
							<button type="button" data-toggle="tooltip" data-placement="right"  className="button-level"
								title="References: 
Secondary (Secundario)  
Tertiary (Terciario)
College (Universidad)  
Master (Master) 
Doctoral (Doctorado) 
Postgraduate (Postgrado)
Courses (Cursos)
Certifications (Certificaciones)
Specializations (Especializaciones)">
								<label className="form-label">Level *</label>
							</button>
							<select name="level" id="level" value={levelSelected} onChange={e => handleChangeLevel(e)}
								className={errors?.level ? 'form-control is-invalid' : 'form-control'} ref={register} disabled={education.isEdit}
							>
								<option value="default" hidden>Select an option...</option>
								{levelsEducation.map((level, index) =>
									<option key={index} value={level.value}>{level.name}</option>
								)}
							</select>
							{errors.level ? <label className="error">{errors.level}</label> : null}
						</div>
					</div>
					<div className="row mt-3">
						<div className="startYear col-12 col-lg-6 mx-auto">
							<label className="form-label">Start Year *</label>
							{submit ? 
                <>
                  <Datetime dateFormat="YYYY" isValidDate={valid} timeFormat={false} className={education.isEdit ? "" : "notEdit"}
                    inputProps={inputPropsStart} onChange={e => handleAddStart(e)} />
                  <Input type="hidden" value={startYear} name="startYear" id="startYear" register={register}
                    disabled={education.isEdit} className={education.isEdit ? "" : "notEdit"}/>
                </>
               : null}
						</div>
						<div className="finishYear col-12 col-lg-6 mx-auto">
							<label className="form-label">Finish Year *</label>
							{checked ?
                <Input name="finishYear" register={register} value="Present" readOnly /> 
              : null }
              {(submit && !checked) ? 
                <>
                  <Datetime dateFormat="YYYY" className={education.isEdit ? "" : "notEdit"} isValidDate={valid} timeFormat={false}
                    inputProps={inputPropsFinish} onChange={e => handleAddFinish(e)} />
                  <Input type="hidden" value={finishYear} name="finishYear" id="finishYear" register={register}
                  disabled={education.isEdit} />
                </>
              : null} 
						</div>
					</div>
					<div className="row mt-4">
						<div className="checkbox col-12">
							<FormControlLabel
								control={
									<Checkbox
										checked={checked}
										onChange={handleChecbox}
										name="checkedB"
										color="default"
									/>
								}
								label="Ongoing"
								className="form-label form-checkbox"
								disabled={education.isEdit}
							/>
						</div>
					</div>
					<div className="row mt-4">
						<div className="country col-12 ">
							<Input name="country" id="country" label="Country *" register={register} error={errors?.country}
							disabled={education.isEdit} />
						</div>
					</div>
				</div>
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className={classes.modal}
					open={open}
					onClose={handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={open}>
						<div className={classes.paper}>
							<p id="transition-modal-description">There are unsaved changes. If you continue, all unsaved data will be lost.</p>
							<div className="save-submit row">
								<button type="button" className="continue m-1 btn button-orange" onClick={handleStayModal}>
									Stay
								</button>
								<button type="button" className="continue m-1 btn button-orange" onClick={handleContinueModal}>
									Continue
								</button>
							</div>
						</div>
					</Fade>
				</Modal>
				{userId ? (
          <div>
            <div className="save-submit row mt-4">
              <button className="save btn button-orange" type="submit">
                {isLoading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  'Add'
                )}
              </button>
              <button className="continue btn button-orange" type="button" onClick={() => handleContinue()}>
                <NavigateNextIcon fontSize="large"/>
              </button>
            </div>
          </div>
				) : (
					<div className="row mt-4">
						<button className="btn button-orange mx-auto" type="submit">
							{isLoading ? (
								<div className="spinner-border text-light" role="status">
									<span className="visually-hidden">Loading...</span>
								</div>
							) : (
								'SIGN UP'
							)}
						</button>
					</div>
				)}
			</div>
		</form>
	);
};