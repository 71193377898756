import { useState, useEffect } from 'react';
import { Pagination } from 'components/molecules';
import { Input } from 'components/atoms';
import { ListUsersData } from './';
import './styles.scss';
import { IUsers, Query} from 'utils/types';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UsersActions } from 'actions';
import { IRootState } from 'reducers/rootReducer';
import deleteFilterButton from 'assets/images/delete-icon.svg';
import searchFilterButton from 'assets/images/search-icon-black.svg';
import { languages } from '../objects';

interface Props {
  id: number;
  role: string;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export const Data: React.FC<Props> = ({ id, role }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  var url = `list-users?`;
  let query = useQuery();
  var Spinner = require('react-spinkit');
  const pageNumber = query.get("page") || '1';
  const limitNumber = query.get("limit") || '16';
  const [name, setName] = useState(query.get("name") || '');
  const [skill, setSkill] = useState(query.get("skill") || '');
  const [language, setLanguage] = useState(query.get("language") || '');
  const [city, setCity] = useState(query.get("city") || '');
  const [status, setStatus] = useState(query.get("status") || '');
  const objectParams = {
    limitNumber,
    pageNumber, 
    name, 
    skill, 
    language, 
    city, 
    status
  }
  const listUsers: Query<IUsers> = (useSelector((state: IRootState) => state.users));
  const debounceName = useDebounce(name, 1000);
  const debounceSkill = useDebounce(skill, 1000);
  const debounceLanguages = useDebounce(language, 200);
  const debounceCity = useDebounce(city, 1000);
  const debounceStatus = useDebounce(status, 200);

  useEffect(() => {
    dispatch(UsersActions.readUsers(objectParams))
  },[]);

  useEffect(() => {
    dispatch(UsersActions.readUsers(objectParams))
  },[pageNumber]); 

  const deleteFilters = () => {
    objectParams.name = '';
    objectParams.skill = '';
    objectParams.language = '';
    objectParams.city = '';
    objectParams.status = '';
    setName('');
    setSkill('');
    setLanguage('');
    setCity('');
    setStatus('');
    dispatch(UsersActions.readUsers(objectParams));
    history.push(`list-users?limit=${limitNumber}&page=1`);
  };

  useEffect(() => {
    let myUrl = url
    if (debounceName !== '') {myUrl = myUrl.concat(`name=${debounceName}&`)}
    if (debounceSkill !== '') {myUrl = myUrl.concat(`skill=${debounceSkill}&`)}
    if (debounceLanguages !== '') {myUrl = myUrl.concat(`language=${debounceLanguages}&`)}
    if (debounceCity !== '') {myUrl = myUrl.concat(`city=${debounceCity}&`)}
    if (debounceStatus !== '') {myUrl = myUrl.concat(`status=${debounceStatus}&`)}
    history.push(`${myUrl}limit=${limitNumber}&page=1`);
    dispatch(UsersActions.readUsers(objectParams))
  }, [debounceName, debounceSkill, debounceStatus, debounceCity, debounceLanguages])
  
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] 
    );
    return debouncedValue;
  }

  return (
    <div className="card-user-form mx-auto row m-0 p-0">
      <div className="col-md-12 col-sm-12 row m-0 p-0 justify-content-center align-items-center mb-3">
        <div className="col-md-1 col-sm-12 mx-auto">
          <label className="titleFilter">FILTER BY:</label>
        </div>
        <div className="col-md-2 col-sm-12 col-xs-12 filters">
          <Input value={name} type="text" onChange={e => setName(e.target.value)}
            name="filterName" placeholder="Name" register={null} />
        </div>
        <div className="col-md-2 col-sm-12 col-xs-12 filters">
          <Input value={skill} type="text" onChange={e => setSkill(e.target.value)}
            name="skill" placeholder="Skill" register={null} />
        </div>
        <div className="col-md-2 col-sm-12 col-xs-12 filters">
          <select name="language" id="language" value={language} onChange={e => setLanguage(e.target.value)}
            className='form-control form-select'>
            <option value="">Language</option>
            {languages.map((language, index) =>
							<option key={index} value={language.name}>{language.name}</option>
						)}
          </select>
        </div>
        <div className="col-md-2 col-sm-12 col-xs-12 filters">
          <Input value={city} type="text" onChange={e => setCity(e.target.value)}
            name="city" placeholder="City" register={null} />
        </div>
        <div className="col-md-2 col-sm-12 col-xs-12 filters">
          <select name="status" id="status" value={status} onChange={e => setStatus(e.target.value)}
            className='form-control form-select' >
            <option value="">Status</option>
            <option value="New User" className="col-form-label">New User</option>
            <option value="Candidate" className="col-form-label">Candidate</option>
            <option value="To Interview" className="col-form-label">To interview</option>
            <option value="Interviewed, not ready" className="col-form-label">Interviewed, not ready</option>
            <option value="Interviewed, possible candidate" className="col-form-label">Interviewed, possible candidate</option>
            <option value="Accepted" className="col-form-label">Accepted</option>
            <option value="Not a fit" className="col-form-label">Not a fit</option>
            <option value="Proposal not accepted" className="col-form-label">Proposal not accepted</option>
            <option value="Mate" className="col-form-label">Mate</option>
            <option value="Ex Mate" className="col-form-label">Ex Mate</option>
          </select>
        </div>
        <div className="col-md-1 col-sm-12 iconsFilter">
          <label className="picture-search-filter" >
            <img alt="update" src={searchFilterButton}/>
          </label>
          <label className="picture-delete-filter" onClick={deleteFilters}>
            <img alt="update" src={deleteFilterButton}/>
          </label>
        </div>
      </div>
      <hr />
      {listUsers.isLoading && <Spinner name="chasing-dots" className="spinner"/>}
      {listUsers.isSuccess && (!listUsers.data.data.length ?
        <h5>No results were found with these characteristics...</h5>
      :
        <> 
          <ListUsersData id={id} users={listUsers.data.data} role={role} params={objectParams} />
          <Pagination
            limitNumber={limitNumber}
            name={name}
            skill={skill}
            language={language}
            city={city}
            status={status}
          />
        </>
      )}
    </div>
  );
};