import { UserDetail } from 'screens';
import { IStatus, IAction } from 'utils/types';

type StatusReducer = (state: IStatus, action: IAction) => IStatus;
export enum StatusReducers {
  CLEAR_STATUS = 'CLEAR_STATUS',
  CREATE_STATUS = 'CREATE_STATUS',
  DELETE_STATUS = 'DELETE_STATUS',
  UPDATE_STATUS = 'UPDATE_STATUS',
  READ_USER_STATUS = 'READ_USER_STATUS',
  READ_STATUS = 'READ_STATUS',
}

const initialState: IStatus = {
  id: 0,
  userId: 0,
  title: '',
  startDate: '',
  description: '',
  editorId: 0,
  isEdit: false,
  data: [],
};

export const status: StatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case StatusReducers.CLEAR_STATUS:
      return initialState;
    case StatusReducers.UPDATE_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    case StatusReducers.READ_USER_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    case StatusReducers.DELETE_STATUS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state;
  }
};