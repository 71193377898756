import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import './styles.scss';

interface IOption {
  id?: number | string;
  title: string;
}

interface Props {
  getOptions: (search: string, setOptions: React.Dispatch<React.SetStateAction<IOption[]>>) => void;
  onSelect: (selectedOption: IOption) => void;
  disabled?: boolean;
}

export const Autocomplete: React.FC<Props> = ({ getOptions, onSelect, disabled }: Props) => {
  const [search, setSearch] = useState<string>('');
  const [options, setOptions] = useState<IOption[]>([]);

  return (
    <Autosuggest
      // If options doesn't includes search, a new option is added with search as title
      suggestions={
        options?.length && options.some(({ title }) => title.toLowerCase() === search.toLowerCase())
          ? options
          : [...options, { title: search.charAt(0).toUpperCase() + search.slice(1) }]
      }
      onSuggestionsFetchRequested={({ value }) => getOptions(value, setOptions)}
      onSuggestionsClearRequested={() => setOptions([])}
      onSuggestionSelected={(event: Event, { suggestion }) => {
        setSearch('');
        setOptions([]);
        onSelect(suggestion);
      }}
      getSuggestionValue={(suggestion) => suggestion.title}
      inputProps={{
        value: search,
        onChange: (e, { newValue }) => setSearch(newValue),
        disabled: disabled,
      }}
      renderSuggestion={(suggestion) => suggestion.title}
    />
  );
};