import React, { useState } from 'react';
import './styles.scss';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Alert from '@material-ui/lab/Alert';
import ReactTooltip from "react-tooltip";

interface Props {
  user: any
}

export const UserProgress: React.FC<Props> = ({user}: Props) => {
  var progress = 0;
  const [enter, setEnter] = useState(false);
  var message = [];

  if (user.email) {
    if (user.email) {
      progress = progress + 16.6;
    } 
    if (user.skills != '') {
      progress = progress + 16.6;
    } else {
      message.push('Complete your skills.')
    }
    if (user.experiences != '') {
      progress = progress + 16.6;
    } else {
      message.push('Update your work experience.')
    }
    if (user.educations != '') {
      progress = progress + 16.6;
    } else {
      message.push('Update your education information.')
    }
    if (user.languages != '') {
      progress = progress + 16.6;
    } else {
      message.push('Add your known languages.')
    }
    if (user.links != '') {
      progress = progress + 16.6;
    } else {
      message.push('Add links to your projects and profiles.')
    }
  }

  const mouseEnter = () => {
    setEnter(true);
  }
  const mouseLeave = () => {
    setEnter(false);
  }
  
  return (
    <div className={enter && progress < 99 ? 'user-progress-alert' : enter && progress > 99 ? 'user-progress-success' : 'user-progress'}
    onMouseEnter={() => mouseEnter()} onMouseLeave={() => mouseLeave()}>
      <div data-tip data-for="warning">
        <ProgressBar striped  now={Math.round(progress)} label={Math.round(progress)+'%'} data-for="warning"/>
      </div>
      {progress < 99 ?
        <ReactTooltip className="tooltip" id="warning" place="bottom" effect="solid" type="warning"
        border={false} backgroundColor="transparency">
          <Alert className='alert' severity="warning">
            <strong>Profile at {Math.round(progress)}%</strong> <br/>
            Update your profile to get more opportunities!
            <br/>
              {message.map((line, index) => {
                return <li key={index}>{line}</li>
              })}
          </Alert>
        </ReactTooltip>
      :
        <ReactTooltip className="tooltip" id="warning" place="bottom" effect="solid" type="success"
        border={false} backgroundColor="transparency">
          <Alert className='success' severity="success">
            <strong>Profile in {Math.round(progress)}%</strong> <br/>
            Congratulations! Just completed your profile in 42People
          </Alert>
        </ReactTooltip>
      }
    </div>
  )
}