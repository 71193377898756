import React, {useEffect, useState} from 'react';
import { IStatus, ISignUpData, IAxiosResponse, IUser, IReference } from 'utils/types';
import { Redirect, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers/rootReducer';
import { AuthActions, UserActions, StatusActions, ReferenceActions } from 'actions';
import { UserDetailAdmin, AddStatusUser, Cards } from 'components/organisms/UserDetailAdmin';
import { UserDetailEmployee, AddReferenceUser, CardsRef } from 'components/organisms/UserDetailEmployee';
import { validationSchema } from './validations';
import { useHistory } from 'react-router-dom';
import { getUser } from 'utils/API';

interface Props {}

export const UserDetail: React.FC<Props> = ( {}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const BASE_API = process.env.REACT_APP_BASE_API_URL;
  const [userSelected, setUserSelected] = useState({});
  const [reloadStatus, setReloadStatus] = useState(false);
  const [reloadReference, setReloadReference] = useState(false);
  const [curriculum64, setCurriculum64] = useState();
  const [personalCurriculum64, setPersonalCurriculum64] = useState();
  const { id, isAuthenticated } = useSelector((state: IRootState) => state.auth);
  const user: IUser = useSelector((state: IRootState) => state.user);
  const { idUser } = useParams();
  const status: IStatus = useSelector((state: IRootState) => state.status);
  const statusData = Object.values(status.data);
  const references: IReference = useSelector((state: IRootState) => state.reference);
  const referencesData = Object.values(references.data);

  async function getUserr(idUser: number) {
    const response = (await getUser(idUser)) as IAxiosResponse<IUser[]>;
    if (response.data) setUserSelected(response.data)
  }

  useEffect(() => {
    if (id) dispatch(AuthActions.refreshToken(id));
    dispatch(UserActions.readUser(id));
  }, []);

  useEffect(() => {
    getUserr(idUser);
  },[idUser]);  
  
  useEffect(() => {
    dispatch(StatusActions.readUserStatus(idUser));
    status.isEdit = false;
    setReloadStatus(false);
  },[reloadStatus]); 
  
  useEffect(() => {
    dispatch(ReferenceActions.readUserReference(idUser));
    setReloadReference(false);
  },[reloadReference]); 

  const submitCallback = (userStatusData: ISignUpData) => {
    dispatch(StatusActions.createStatus(userStatusData, history));
    dispatch(StatusActions.readUserStatus(idUser));
    setReloadStatus(true);
  };

  const updateCallback = (statusUpdateData: ISignUpData) => {
    dispatch(StatusActions.updateStatus(statusUpdateData));
    dispatch(StatusActions.readUserStatus(idUser))
    setReloadStatus(true);
  };

  const submitCallbackRef = (userReferenceData: ISignUpData) => {
    dispatch(ReferenceActions.createReference(userReferenceData, history));
    dispatch(ReferenceActions.readUserReference(idUser));
    setReloadReference(true);
  };

  const getCurriculum = () => {
		fetch(`${BASE_API}/users/curriculum/${idUser}`)
    .then(response => {
      return response.json();
    })
    .then(result => {
      setCurriculum64(result.data)
    }).catch(error => {
      console.log(error)
    })
	};

  const getPersonalCurriculum = () => {
		fetch(`${BASE_API}/users/curriculum/personal/${idUser}`)
    .then(response => {
      return response.json();
    })
    .then(result => {
      setPersonalCurriculum64(result.data)
    }).catch(error => {
      console.log(error)
    })
	};

  useEffect(() => {
    getCurriculum();
    getPersonalCurriculum();
  })

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <div>
      {(userSelected != {} && user.role == 'ADMIN') ? (
        <div className="row col-12 p-0 m-0 pt-3 mb-4">
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 p-0 mx-auto">
            <UserDetailAdmin id={id} user={user} idUserSelected={idUser} userDataSelected={userSelected} curriculum64={curriculum64} personalCurriculum64={personalCurriculum64} validationSchema={validationSchema} />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-0 mx-auto">
            <AddStatusUser id={id} idUserSelected={idUser} userDataSelected={userSelected} allStatus={statusData} submitCallback={submitCallback} validationSchema={validationSchema} />
            <Cards id={id} idUserSelected={idUser} allStatus={statusData} userDataSelected={userSelected} updateCallback={updateCallback}/>  
          </div> 
        </div>  
        ) : ( 
        <div className="row col-12 p-0 m-0 pt-3 mb-4">
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 p-0 mx-auto">
            <UserDetailEmployee id={id} idUserSelected={idUser} userDataSelected={userSelected}/>
          </div>
          {id != idUser ?
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-0 mx-auto">
            <AddReferenceUser id={id} idUserSelected={idUser} userDataSelected={userSelected} allReferences={referencesData} submitCallback={submitCallbackRef} validationSchema={validationSchema}/>
            <CardsRef id={id} idUserSelected={idUser} allReferences={referencesData}/>  
          </div> 
          : null }
        </div>           
      )} 
    </div>
  );
};