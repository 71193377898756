import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers/rootReducer';
import { Data } from 'components/organisms/ListUsersData';

interface Props {
  role: string,
}

export const ListUsers: React.FC<Props> = ({role}: Props) => {
  const { id, isAuthenticated } = useSelector((state: IRootState) => state.auth);

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <div className="row mt-3 m-0 p-0">
      <Data id={id} role={role}/>
    </div>
  );
};