import * as yup from 'yup';

export const validationSchema = yup.object({
  firstName: yup.string().required('Required field'),
  lastName: yup.string().required('Required field'),
  country: yup.string().required('Required field'),
  city: yup.string().required('Required field'),
  email: yup.string().required('Required field').email('This has to be a valid email'),
  password: yup.string().required('Required field').min(8, 'The password must have at least ${min} characters').notOneOf([yup.ref('email')], 'The password cannot be the same as the email'),
  repeatPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords do not match'),
});