import { IFile } from './types';

export const getFileExtension = (file: File) => {
  return file?.type?.split('/').pop();
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    if (!file) return reject('Error no file found');
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const string = reader.result as string;
      const base64String = string.replace('data:', '').replace(/^.+,/, '');
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });

export const getBase64File = async (file: File): Promise<IFile | null> => {
  if (!file) return null;
  try {
    const data = (await toBase64(file)) as string;
    return {
      data,
      extension: getFileExtension(file),
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};