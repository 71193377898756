import { IAction, IUtilsState, ToastStatus } from 'utils/types';

type UtilsReducer = (state: IUtilsState, action: IAction) => IUtilsState;
export enum UtilsReducers {
  SET_LOADING = 'SET_LOADING',
  SHOW_TOAST = 'SHOW_TOAST',
  STOP_LOADING = 'STOP_LOADING',
  TOAST_DISPLAYED = 'TOAST_DISPLAYED',
}

const initialState: IUtilsState = {
  isLoading: false,
  showToast: false,
  toastStatus: ToastStatus.INFO,
  toastMessage: '',
};

export const utils: UtilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UtilsReducers.SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case UtilsReducers.SHOW_TOAST:
      return {
        ...state,
        showToast: true,
        toastMessage: action.payload.message,
        toastStatus: action.payload.status,
      };
    case UtilsReducers.STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case UtilsReducers.TOAST_DISPLAYED:
      return {
        ...state,
        showToast: false,
      };
    default:
      return state;
  }
};